import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { CloudUpload } from "@mui/icons-material";
import { IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAuth } from "../../contexts/AuthConext";
import { Image } from "antd";

const AddUnifiedSM = ({ open, onHide ,fetchManufacturers}) => {
  const {saasId,
    isdCodes,fetchIsdCodes,
    fetchExpertiseList, expertise, supplierType, fetchsupplierTypeList, countries, fetchCountries,
    states,fetchStates
  } = useAuth(); 

useEffect(() => {
  fetchIsdCodes()
}, [])

const handlecountryOpen = () => {
  fetchCountries();
};
const handleDropdownOpen = () => {
  fetchExpertiseList();
};
const handlesupplierTypeOpen = () => {
  fetchsupplierTypeList();
};
const [errorPrimary, setErrorPrimary] = useState(''); // Error state for primary number
const [errorSecondary, setErrorSecondary] = useState(''); // Error state for secondary number

const [selectedIsdCode, setSelectedIsdCode] = useState('+91');
const [selectedFile, setSelectedFile] = useState(null);
  const initialState = {
    name: "",
    certifications: [], // Ensure it's initialized as an empty array
    phone: "",
    phone2: "",
    expertise:"",
    type:"",
    email: "",
    email2: "",
    manufactureCost:"",
    supplierPrice:"",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: ""
    },
    certificationFiles: {}
  };
  

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (formData?.address?.country) {
      fetchStates(formData?.address?.country);
    }
  }, [formData?.address?.country]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      validatePhoneNumber(value, selectedIsdCode, 'primary');
    } else if (name === 'phone2') {
      validatePhoneNumber(value, selectedIsdCode, 'secondary');
    }
    // Handle address fields
    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address, // Ensure that existing address fields are not overwritten
          [addressField]: value
        }
      }));
    } 
    // Handle other fields
    else if (name === "certifications") {
      const certifications = Array.isArray(value) ? value : [value];
      setFormData((prevState) => ({
        ...prevState,
        certifications,
        certificationFiles: certifications.reduce((acc, cert) => {
          acc[cert] = prevState.certificationFiles[cert] || ''; // Initialize files if not present
          return acc;
        }, {})
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  

  const handleFileChange = async (event, cert) => {
    const file = event.target.files[0];
  
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await DataService.addOCR(formData);
  
        // Show success or error message
        Swal.fire({
          title: response.data.status ? 'Success' : 'Error',
          text: response.data.message,
          icon: response.data.status ? 'success' : 'error',
          confirmButtonText: 'OK',
        });
  
        // Extract the date from the OCR response
        const extractedDate = response.data.data; // Assuming the date is in response.data.data
  
        // Create a preview URL for the uploaded file
        const previewUrl = URL.createObjectURL(file);
  
        // Update formData state with the file, extracted date, and preview URL
        setFormData((prevData) => ({
          ...prevData,
          certificationFiles: {
            ...prevData.certificationFiles,
            [cert]: {
              file,
              previewUrl,
              date: extractedDate, // Store the extracted date
            },
          },
        }));
      } catch (error) {
        console.error('Error uploading file', error);
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while uploading the file.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };
  const OnFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedFile(file); // Store the actual file, not the preview URL
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "name", "certifications", "phone", "email", "email2",
      "address.flatNo", "address.cityArea", "address.country",
      "address.state", "address.pincode"
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return field;
      }
    }
    return null;
  };



  // Phone number validation logic
  const validatePhoneNumber = (phone, isdCode, field) => {
    const selectedCountry = isdCodes.find((isd) => isd.code === isdCode);
    let errorMessage = '';
  
    if (selectedCountry) {
      const minLength = selectedCountry.minphoneLength; // Minimum phone length
      const maxLength = selectedCountry.maxphoneLength; // Maximum phone length
  
      // Check phone length
      if (phone?.length < minLength || phone?.length > maxLength) {
        if (minLength === maxLength) {
          errorMessage = `Phone number must be ${maxLength} digits long.`;
        } else {
          errorMessage = `Phone number must be between ${minLength} and ${maxLength} digits long.`;
        }
      }
    }
  
    // Set error message only if there is one
    if (field === 'primary') {
      setErrorPrimary(errorMessage || ''); // Set error message for primary phone
    } else if (field === 'secondary') {
      setErrorSecondary(errorMessage || ''); // Set error message for secondary phone
    }
  
    return errorMessage; // Return the error message if any
  };



  const handleSubmit = async () => {

    
    const primaryErrorMessage = validatePhoneNumber(formData.phone, selectedIsdCode, 'primary');
    const secondaryErrorMessage = validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary');

        // Check if there are any validation errors
        // Check if there are any validation errors
    if (primaryErrorMessage) {
      // Display validation error message for primary phone
      Swal.fire({
        title: 'Validation Error!',
        text: primaryErrorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; // Stop execution if validation fails
    }

    if (secondaryErrorMessage) {
      // Display validation error message for secondary phone
      Swal.fire({
        title: 'Validation Error!',
        text: secondaryErrorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; // Stop execution if validation fails
    }




    // const missingField = validateForm();
  
    // if (missingField) {
    //   Swal.fire({
    //     title: "Missing Information",
    //     text: `Please fill in the ${missingField.replace("address.", "")} field.`,
    //     icon: "warning",
    //   });
    //   return;
    // }
  
    const dataToSend = {
        saasId: saasId,
        name: formData.name,
        certifications: formData.certifications, // Already storing certifications as an array
        expertise:formData.expertise,
        type:formData.type,
        manufactureCost:formData.manufactureCost,
        supplierPrice:formData.supplierPrice,

        contactInfo: {
          phone: formData.phone,
          phone2: formData.phone2,
          email: formData.email,
          email1: formData.email2, // Ensure alternate email is stored as email1 in the payload
        },
        address: { 
      flatNo: formData.address.flatNo,
      pincode: formData.address.pincode,
      cityArea: formData.address.cityArea,
      state: formData.address.state,
          country: formData.address.country,
          }
        };
    
    try {
      const response = await DataService.createUnifiedSM(dataToSend);
  
      const UnifiedSMID = response.data.data.id; // Assume the API returns this
      if (response.data.status) {
        const uploadResponse = await fileUploadResponse(UnifiedSMID, formData.certificationFiles);
  
        if (uploadResponse) {
          Swal.fire({
            title: "Supplier Created Successfully",
            icon: "success",
            timer: 2000
          });
          UploadSupplierimage(response.data.data.id);
          fetchManufacturers()
          onHide(); // Close the modal
          setFormData(initialState);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire({
          title: "Error Creating Unified Supplier Manufacturer",
          text: error.response.data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error Creating Unified Supplier Manufacturer",
          icon: "error",
          text: error.message || "An unknown error occurred",
        });
      }
    }
  };

  const fileUploadResponse = async (UnifiedSMID, certificationFiles) => {
    const formData = new FormData();
  
    // Append certificate files and their corresponding extracted dates
    Object.keys(certificationFiles).forEach((cert, index) => {
      const { file, date } = certificationFiles[cert]; // Extract file and date dynamically
  
      if (file) {
        formData.append(`certificate${index + 1}`, file); // Append the file
        formData.append(`date${index + 1}`, date); // Append the extracted date from OCR API
      }
    });
  
    try {
      const response = await DataService.uploadUnifiedSM(formData, UnifiedSMID);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      Swal.fire({
        title: "Error Uploading Files",
        icon: "error",
        text: error.message,
      });
      return null;
    }
  };
  
  
  //Supplier Logo Upload
  const UploadSupplierimage = async (Id)=>{
    try {
      if(selectedFile){
        //append the file to the form data
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await DataService.uploadSupplierLogo(formData, Id);
        
        if (response.status === 200) {
          Swal.fire({
            title: "Supplier Logo Uploaded Successfully",
            icon: "success",
            timer: 2000
          });
        } else {
          throw new Error(response.data.message || "Supplier logo upload failed");
        }
      }
      
    } catch (error) {
      Swal.fire({
        title: "Error Uploading Supplier Logo",
        icon: "error",
        text: error.message,
      });
      console.log(error)
    }
  }

  


  // isd code validation



// Handle ISD Code change
const handleIsdCodeChange = (event) => {
  const newIsdCode = event.target.value;
  setSelectedIsdCode(newIsdCode);
  validatePhoneNumber(formData.phone, newIsdCode, 'primary'); // Validate primary phone when ISD code changes
};

  return (
    <div>
      <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold">Create Supplier</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardBody>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                       required
                        label="Email (Primary)"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                       required

                        label="Email (Secondary)"
                        value={formData.email2}
                        onChange={handleInputChange}
                        name="email2"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Certifications</InputLabel>
                        <Select
                          label="Certifications"
                          value={formData.certifications}
                          onChange={handleInputChange}
                          name="certifications"
                          multiple
                        >
                          <MenuItem value="certifications1">Certifications 1</MenuItem>
                          <MenuItem value="certifications2">Certifications 2</MenuItem>
                          <MenuItem value="certifications3">Certifications 3</MenuItem>
                          <MenuItem value="certifications4">Certifications 4</MenuItem>
                          <MenuItem value="certifications5">Certifications 5</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Dynamic File Upload Inputs */}
                    {Array.isArray(formData.certifications) &&
  formData.certifications.map((cert) => (
    <Grid item xs={12} sm={4} key={cert}>
      <TextField
        label={`Upload ${cert}`}
        variant="outlined"
        fullWidth
        value={formData.certificationFiles[cert]?.file?.name || ''}
        InputProps={{
          readOnly: true,
          endAdornment: formData.certificationFiles[cert]?.previewUrl && (
            <InputAdornment position="start">
              {/* Thumbnail Preview Inside TextField */}
              <img
                src={formData.certificationFiles[cert].previewUrl}
                alt={`Preview of ${cert}`}
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                  marginRight: '8px',
                }}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="end">
              <IconButton
                component="label"
                style={{ background: 'white', borderRadius: '4px' }}
              >
                <CloudUpload />
                <input
                  type="file"
                   accept="image/*"
                  hidden
                  onChange={(e) => handleFileChange(e, cert)}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        className="mb-2"
      />
    </Grid>
  ))}

                    <Grid item xs={12} sm={4}>
                      <TextField
                      required

                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                         type="number"
                        fullWidth
                        onBlur={() => validatePhoneNumber(formData.phone, selectedIsdCode, 'primary')} // Validate on blur
                        error={!!errorPrimary} // Apply error style if error exists for primary
                        helperText={errorPrimary} // Show error message for primary
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                value={selectedIsdCode}
                                onChange={handleIsdCodeChange}
                                displayEmpty
                                variant="standard"
                                style={{ marginRight: '8px', fontSize: '14px' }}
                              >
                                {isdCodes.map((isd) => (
                                  <MenuItem key={isd.code} value={isd.code}>
                                    {isd.country}
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required

                        label="Mobile No. (Secondary)"
                        value={formData.phone2}
                        onChange={handleInputChange}
                        name="phone2"
                         type="number"
                          onBlur={() => validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary')} // Validate on blur
                         error={!!errorSecondary} // Apply error style if error exists for secondary
                         helperText={errorSecondary} // Show error message for secondary
                        fullWidth
                      />
                    </Grid>

                
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required

                        label="Address"
                        value={formData.address.flatNo}
                        onChange={handleInputChange}
                        name="address.flatNo"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required

                        label="City Area"
                        value={formData.address.cityArea}
                        onChange={handleInputChange}
                        name="address.cityArea"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <TextField
                      required

                        label="Country"
                        value={formData.address.country}
                        onChange={handleInputChange}
                        name="address.country"
                        fullWidth
                      /> */}
                           {/* Country Select Dropdown */}
                <FormControl fullWidth>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    required
                    labelId="country-select-label"
                    id="country-select"
                    label="Country"
                    value={formData.address.country}
                    onChange={handleInputChange}
                    name="address.country"
                    className=" border border-gray-300 rounded-sm w-full"
                    onOpen={handlecountryOpen}
                  >
                    {countries &&
                      countries.map((el) => (
                        <MenuItem key={el.country_id} value={el.country_name}>
                          {el.country_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
             
                               <FormControl fullWidth>
  <InputLabel>State</InputLabel>
  <Select
   label="State"
   value={formData.address.state}
   onChange={handleInputChange}
   name="address.state"
  >
    {states.length > 0 ? (
      states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>No states available</MenuItem>
    )}
  </Select>
</FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                      <TextField
                        label="District"
                        value={formData.address.district}
                        onChange={handleInputChange}
                        name="address.district"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required

                        label="ZipCode"
                        value={formData.address.pincode}
                        onChange={handleInputChange}
                        name="address.pincode"
                         type="number"
                        fullWidth
                      />
                    </Grid>  
                    <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>expertise</InputLabel>
                        <Select
                          required
                          label="expertise"
                          value={formData.expertise}
                          onChange={handleInputChange}
                          name="expertise"
                          onOpen={handleDropdownOpen}
                          fullWidth
                        >
                          {expertise.map((expertise) => (
                            <MenuItem key={expertise.id} value={expertise.expertiseName}>
                              {expertise.expertiseName}
                            </MenuItem>
                          ))}


                        </Select>
                      </FormControl>
                    </Grid>   
                    <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          required
                          label="Type"
                          value={formData.type}
                          onChange={handleInputChange}
                          onOpen={handlesupplierTypeOpen}
                          name="type"
                        >
                          {supplierType.map((supplier) => (
                            <MenuItem key={supplier.id} value={supplier.typeName}>
                              {supplier.typeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>   
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required
                       label="Cost"
                        value={formData.manufactureCost}
                        onChange={handleInputChange}
                        name="manufactureCost"
                        type="number"
                        fullWidth
                      />
                    </Grid>  
                    <Grid item xs={12} sm={4}>
                      <TextField
                      required
                       label="Price"
                        value={formData.supplierPrice}
                        onChange={handleInputChange}
                        name="supplierPrice"
                        type="number"
                        fullWidth
                      />
                    </Grid>  
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                           label="Logo"
                        variant="outlined"
                        type="file"
                        name="productImage"
                        onChange={OnFileChange}
                        inputProps={{
                          accept: "image/*", // Allow only image files
                        }}
                        InputLabelProps={{
                          shrink: true, // Keeps the label open
                        }}
                      />
                    </Grid>       
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </Container>
          <div className="flex justify-center space-x-8 col-span-full mt-2">
        <Button
                    style={{
                      borderRadius: '20px',
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
          <Button onClick={handleSubmit}   style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#2D059C',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#2D059C' },
                      background: "#4F46E5",
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="contained"
                    color="primary">
            Submit
          </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddUnifiedSM;
