import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { Brush, CloudUpload } from '@mui/icons-material';
import Notification from './../assets/Notification.png';
const NotificationList = () => {
  const notifications = [
    { id: 1, title: 'New Product Upload successful', message: 'New product "Table" uploaded successfully.' },
    { id: 2, title: 'New Product Upload successful', message: 'New product "Table" uploaded successfully.' },
    { id: 3, title: 'New Product Upload successful', message: 'New product "Table" uploaded successfully.' },
    { id: 4, title: 'New Product Upload successful', message: 'New product "Table" uploaded successfully.' },
    { id: 5, title: 'New Product Upload successful', message: 'New product "Table" uploaded successfully.' },
  ];
  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  return (
    <div style={containerWrapperStyle}>
    <div container spacing={2} className="scrollable-container " style={scrollableContainerStyle}>
    <div className="p-4 bg-gray-100">
      <Typography variant="h6" className="mb-4">
        Notification
      </Typography>
      <Grid container spacing={2}>
        {notifications.map((notification) => (
          <Grid item xs={12} key={notification.id}>
            <Card className="flex items-center space-x-4 p-4 rounded-lg shadow-sm bg-white">
            {/* <img src={Notification} alt="Notification Table"  className="w-12 h-12" /> */}
              <Avatar className="bg-black">
                <Brush  />
              </Avatar>
              <CardContent className="p-0">
                <Typography variant="body1" className="font-semibold">
                  {notification.title}
                </Typography>
                <Typography variant="body2" className="text-gray-500">
                  {notification.message}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
    </div>
    </div>
  );
};

export default NotificationList;
