import React from 'react';
import { Box, Typography, Paper, Avatar } from '@mui/material';
import { classNames } from 'classnames';
import { Image } from 'antd';

function ManufacturerInfo({ manufacturerInfo }) {
  // Array of certificate URLs
  const certificates = [
    manufacturerInfo?.certificate1,
    manufacturerInfo?.certificate2,
    manufacturerInfo?.certificate3,
    manufacturerInfo?.certificate4,
    manufacturerInfo?.certificate5,
  ];

  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="text.primary" mb={2}>
          Manufacturer Details
        </Typography>

        {/* Manufacturer Name */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="text.primary">
            Manufacturer Name:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {manufacturerInfo?.name || 'N/A'}
          </Typography>
        </Box>

        {/* Manufacturer Address */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="text.primary">
            Address:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {manufacturerInfo?.address ? `
             ${manufacturerInfo.address.district}, ${manufacturerInfo.address.state}, ${manufacturerInfo.address.country} - ${manufacturerInfo.address.pincode}` : 'N/A'}
          </Typography>
        </Box>

        {/* Manufacturer Contact Info */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="text.primary">
            Contact Info:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Phone: {manufacturerInfo?.contactInfo?.phone || 'N/A'}
            <br />
            Email: {manufacturerInfo?.contactInfo?.email || 'N/A'}
          </Typography>
        </Box>

   
      </Paper>

           {/* Certifications */}
      
           <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
              <Box display="flex" justifyContent="center" mt={4} flexDirection="row" mb={2}>
          {/* <Typography variant="body2" color="text.primary" mb={1}>
            Certifications:
          </Typography> */}
          {certificates.map((cert, index) =>
            cert ? (
              <Box key={index} mb={1}>
                <Typography variant="body2" color="text.secondary">
                  Certificate {index + 1}:
                </Typography>
                <Image 
                  src={cert} 
                  alt={`Certificate ${index + 1}`} 
                  sx={{ width: 100, height: 100, mb: 1, borderRadius: 1 }}
                />
              </Box>
            ) : null
          )}
          </Box>
      </Paper>

     
    </Box>
  );
}

export default ManufacturerInfo;
