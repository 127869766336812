import React from 'react';
import { Avatar, Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { Home, Settings, ExitToApp, Inventory2, Factory, PersonAdd, Store } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../contexts/AuthConext';
import StorageIcon from '@mui/icons-material/Storage';
import profile from './../assets/Profilepg.png';
import { BASEURL } from '../services/http-common';
import { FaPassport, FaTruck } from 'react-icons/fa';

const Sidebar = ({ className, handleReset }) => {
  const { logout, authData } = useAuth();
  const { name, id } = authData || {};
  
  const navigate = useNavigate();
  const location = useLocation();

  const handleResethome = () => {
    handleReset();
    navigate("/");
  };

  return (
    <Box className={`flex flex-col p-4 [background:#383a3a] text-white ${className}`} style={{ height: "100%" }}>
      <div>
        <div className="flex items-center mb-8">
          <Avatar
            className="cursor-pointer"
            onClick={() => navigate("/Profile")}
            src={`${BASEURL.ENDPOINT_URL}auth/get-profile-image/${id}`}
            alt="User Photo"
            sx={{ width: 56, height: 56, marginRight: 2 }}
          />
          <div>
            <Typography variant="h6" className='montserrat-text-normal'>
              {name || 'User Name'}
            </Typography>
          </div>
        </div>
        <nav>
          <List>
            <ListItem 
              button 
              onClick={handleResethome}
              style={{
                backgroundColor: location.pathname === "/" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <Home className={location.pathname === "/" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Dashboard</li>
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate("/Product")}
              style={{
                backgroundColor: location.pathname === "/Product" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/Product" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <FaPassport size={24}  className={location.pathname === "/Product" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Product</li>
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate("/UnifiedSMList")}
              style={{
                backgroundColor: location.pathname === "/UnifiedSMList" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/UnifiedSMList" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <FaTruck size={24} className={location.pathname === "/UnifiedSMList" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Supplier</li>
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate("/AddRetailer")}
              style={{
                backgroundColor: location.pathname === "/AddRetailer" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/AddRetailer" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <Store size={24}   className={location.pathname === "/AddRetailer" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Retailer</li>
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate("/AllUser")}
              style={{
                backgroundColor: location.pathname === "/AllUser" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/AllUser" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <PersonIcon className={location.pathname === "/AllUser" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>User</li>
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate("/Datamanagement")}
              style={{
                backgroundColor: location.pathname === "/Datamanagement" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/Datamanagement" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <StorageIcon className={location.pathname === "/Datamanagement" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Master Data</li>
            </ListItem>
          </List>
        </nav>
      </div>
      <div className="mt-auto" style={{ marginBottom: "200px" }}>
        <nav>
          <List>
            {/* <ListItem 
              button 
              style={{
                backgroundColor: location.pathname === "/Settings" ? "#FFEDEC" : "inherit",
                color: location.pathname === "/Settings" ? "black" : "white"
              }}
            >
              <ListItemIcon>
                <Settings className={location.pathname === "/Settings" ? "text-purple-500" : "text-white"} />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Settings</li>
            </ListItem> */}
            <ListItem 
              button 
              className="cursor-pointer" 
              onClick={() => { logout(); navigate("/login"); }}
            >
              <ListItemIcon>
                <ExitToApp className="text-white" />
              </ListItemIcon>
              <li className='montserrat-text-normal list-none'>Logout</li>
            </ListItem>
          </List>
        </nav>
      </div>
    </Box>
  );
};

export default Sidebar;
