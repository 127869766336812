import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import PassportSelection from './components/PassportSelection';
import EndProductStepThree from './components/EndProductStepThree';
import EndProductStepFour from './components/EndProductStepFour';
import CustomStepper from './components/CustomStepper';
import EndProductStepFive from './components/EndProductStepFive';
import Product from './components/Product';
import ProductTabs from './components/Productpages/ProductTabs';
import Register from './LoginRegister/Register';
import Login from './LoginRegister/Login';
import { AuthProvider, useAuth } from './contexts/AuthConext';
import { CartProvider } from './contexts/CartContext';
import ProductListPage from './components/Productpages/ProductListPage';
import SuplierList from './components/Master/SuplierList';
import MenufactureList from './components/Master/MenufactureList';
import RetailerList from './components/Master/RetailerList';
import ProtectedRoute from './ProtectedRoute';
import Profile from './Profile/Profile';
import NotificationList from './Profile/NotificationList';
import Datamanagement from './components/Master/Datamanagement';
import AllProductInfo from './ProductInfo/AllProductInfo';
import Restricted from './Restricted';
import AllCustomer from './Customers/AllCustomer';
import PermissionManagement from './Customers/PermissionManagement';
import ApiPermissionPage from './Customers/ApiPermissionPage';
import UnifiedSMList from './Unified/UnifiedMaster/UnifiedSMList';

function App() {
  const [isExtend, setIsExtend] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const {isLoding} = useAuth();
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);
  
  const handledirct = (step) => setActiveStep(step);


  return (
    isLoding ? <div>Loading...</div> : (
      <CartProvider>
      
          <Routes>
            {/* Public Routes */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/productDetails/:productId" element={<AllProductInfo />} />

             {/* Protected Routes */}
            </Routes>
            
              <Layout handleReset={handleReset} isExtend={isExtend} setPageLayout={setIsExtend}>
                <Routes>
                <Route path="/ERROR" element={<Restricted />} />
                  
                  {/* Dashboard Route */}
                  <Route path="/" element={<ProtectedRoute page="Dashboard" index="0" />}>
                    <Route path="/" element={<PassportSelection handleNext={handleNext} setActiveStep={setActiveStep} />} />
                  </Route>

                  {/* Custom Stepper */}
                  <Route path="/CustomStepper" element={<ProtectedRoute page="Custom Stepper" index="1" />}>
                    <Route path="/CustomStepper" element={<CustomStepper handledirct={handledirct} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} handleReset={handleReset} />} />
                  </Route>

                  {/* Product Route */}
                  <Route path="/Product" element={<ProtectedRoute page="Product" index="2" />}>
                    <Route path="/Product" element={<ProductListPage />} />
                  </Route>

                  {/* Master Data Routes */}
                  <Route path="/UnifiedSMList" element={<ProtectedRoute page="Add Supplier" index="3" />}>
                    <Route path="/UnifiedSMList" element={<UnifiedSMList />} />
                  </Route>
                  <Route path="/AddManufacture" element={<ProtectedRoute page="Add Manufacturer" index="4" />}>
                    <Route path="/AddManufacture" element={<MenufactureList />} />
                  </Route>
                  <Route path="/AddRetailer" element={<ProtectedRoute page="Add Retailer" index="5" />}>
                    <Route path="/AddRetailer" element={<RetailerList />} />
                  </Route>

                  {/* Data Management */}
                  <Route path="/Datamanagement" element={<ProtectedRoute page="Data Management" index="6" />}>
                    <Route path="/Datamanagement" element={<Datamanagement />} />
                  </Route>

                  {/* Profile */}
                  <Route path="/Profile" element={<ProtectedRoute page="Profile" index="7" />}>
                    <Route path="/Profile" element={<Profile />} />
                  </Route>

                  {/* Notifications */}
                  <Route path="/NotificationList" element={<ProtectedRoute page="Notification List" index="8" />}>
                    <Route path="/NotificationList" element={<NotificationList />} />
                  </Route>

                  {/* All User */}
                  <Route path="/AllUser" element={<ProtectedRoute page="All User" index="9" />}>
                    <Route path="/AllUser" element={<AllCustomer />} />
                  </Route>

                  <Route path="/PermissionManagement/:userId" element={<PermissionManagement />} />


                  <Route path="/ProductTabs" element={<ProductTabs />} />
                  <Route path="/apipermission" element={<ApiPermissionPage />} />

                  {/* <Route path="/UnifiedSMList" element={<UnifiedSMList />} /> */}

                  {/* Catch-all route */}
                  {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
                </Routes>
              </Layout>
      
   
     
      </CartProvider>
    )
  );
}

export default App;
