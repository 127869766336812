import React, { useEffect, useRef, useState } from 'react'
import {FaPassport, FaBed, FaUsers, FaWallet, FaTruck, FaHeartbeat, FaBrain, FaCut, FaChevronDown, FaTshirt, FaGem, FaClock, FaRoad, FaCloud, FaTachometerAlt, FaMapMarkedAlt, FaCalculator } from 'react-icons/fa'
import { Line } from 'react-chartjs-2';
import { PersonAdd, Store } from '@mui/icons-material';
import 'chart.js/auto'; // Automatically register the required chart elements
import { Grid, Paper, Typography, Select, MenuItem, FormControl, InputLabel ,

  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CardContent
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { Bar } from 'react-chartjs-2';  
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';  
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthConext';
import AddMaleUser from '../assets/AddMaleUser.png';
import AddManu from '../assets/AddManu.png';
import Swal from 'sweetalert2';
import DataService from '../services/requestApi';
import { Image } from 'antd';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);  

  const Card = ({ icon, value, label, color, bgColor, navigateTo,trnspoEmision }) => {
    const navigate = useNavigate();
  
    const handleCardClick = () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  
    return (
  <div     onClick={handleCardClick} className={`rounded-lg p-6 shadow-sm cursor-pointer`} style={{ background: bgColor }}>
         <div className="flex justify-center">
      <h4 className='montserrat-text'>{trnspoEmision} </h4>
      </div>
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${color}`}>
        {icon}
      </div>
    
        <div className="text-2xl montserrat-text">{value}</div>
        <div className="montserrat-text">{label}</div>
   
    </div>
  </div>
 );
};

const data = {  
  labels: [' Smartphone ', 'Sabuz Bag', 'Amoxicycilne', 'T-Shit',"Jeans", ],  
  datasets: [  
    {  
      label: 'Sustainability Product %',  
      data: [90, 95, 20,40,60,85, 100],  
      backgroundColor: 'rgba(128, 0, 128, 0.6)', // Paars  
    },  
  ],  
};  

const options = {  
  responsive: true,  
  plugins: {  
    legend: {  
      position: 'bottom',  
    },  
    title: {  
      display: true,  
      text: 'New Accounts by Month',  
    },  
  },  
  scales: {  
    y: {  
      beginAtZero: true,  
    },  
  },  
}; 
const BarChart = () => (
  <div className="bg-white rounded-lg p-6 shadow-sm">
   
    <div>  
      <Bar className='mt-12' data={data} options={options} />  
      <div style={{ textAlign: 'center', marginTop: '10px' }}>  
        <span>Sustainability</span> | <span>Product Name</span>  
      </div>  
    </div>  
  </div>
)





const CompletionCard = ({setActiveStep}) => {
  const {saasId
   
  } = useAuth(); 
  const [total, settotal] = useState([
  ]);
  const GetTotalCount = async () => {
    try {
      const response = await DataService.getdasboardcount(saasId);
     settotal(response?.data?.data || []);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };
  
  
  
  useEffect(() => {
    GetTotalCount()
  
  }, [])
  const [isClicked, setIsClicked] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0); // Track the rotation angle
  const outerChartRef = useRef(null);
  const innerChartRef = useRef(null);

 // Outer Doughnut data
 const [outerData, setOuterData] = useState({
  datasets: [
    {
      data: [25,15, 15, 15,15, 15], // Purple (40%) and White (10%)
      backgroundColor: ['#6b46c1','#FFFFFF', '#6b46c1', '#6b46c1','#FFFFFF', '#6b46c1'], // Ensure white and purple alternate
      borderWidth: 0,
      cutout: '80%',
    },
  ],
});

// Inner Doughnut data
const [innerData, setInnerData] = useState({
  datasets: [
    {
      data: [15,30, 10, 15,20, 20], // Purple (40%) and White (10%)
      backgroundColor: ['#FFFFFF','#6b46c1', '#6b46c1', '#FFFFFF','#6b46c1', '#6b46c1'], // Ensure white and purple alternate
      borderWidth: 0,
      cutout: '90%',
    },
  ],
});

  const options = (rotation) => ({
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
    rotation: rotation, // Start the rotation at a specific angle
    circumference: 360, // Full circle
    animation: {
      animateRotate: true, // Enable rotation animation
      duration: 1500, // Increased the duration to 3000ms (3 seconds)
    },
  });
  const productId = localStorage.getItem('productId');
    const materialId = localStorage.getItem('materialId');
    const impactId = localStorage.getItem('impactId');
    const unifiedSMId = localStorage.getItem('UnifiedSM_id');
    const retailerId = localStorage.getItem('Reatailr_id');
  const navigte =useNavigate()
  const handleClick = () => {
    setIsClicked(true);
  
    // Update the rotation angle for opposite direction movement
    const newRotation = rotationAngle + 180; // Rotate by 180 degrees
    setRotationAngle(newRotation);



  // Update Doughnut data when clicked
  setOuterData({
    datasets: [
      {
        data: [35, 15, 35, 15], // New data after click
        backgroundColor: ['#6b46c1', '#FFFFFF', '#6b46c1', '#FFFFFF'],
        borderWidth: 0,
        cutout: '80%',
      },
    ],
  });

  setInnerData({
    datasets: [
      {
        data: [35, 15, 35, 15], // New data after click
        backgroundColor: ['#6b46c1', '#FFFFFF', '#6b46c1', '#FFFFFF'],
        borderWidth: 0,
        cutout: '90%',
      },
    ],
  });



    // Trigger rotation animation for both charts by updating their options
    if (outerChartRef.current) {
      outerChartRef.current.options.rotation = newRotation;
      outerChartRef.current.update();
    }
    if (innerChartRef.current) {
      innerChartRef.current.options.rotation = -newRotation; // Inner chart rotates in opposite direction
      innerChartRef.current.update();
    }

    // Reset isClicked after animation completes
    setTimeout(() => {
      setIsClicked(false);
      if (retailerId) {
        setActiveStep(5); // Navigate to step 6
      } else if (unifiedSMId) {
        setActiveStep(4); // Navigate to step 5
      } else if (impactId) {
        setActiveStep(3); // Navigate to step 4
      } else if (materialId) {
        setActiveStep(2); // Navigate to step 3
      } else if (productId) {
        setActiveStep(1); // Navigate to step 2
      } else {
        setActiveStep(0); // Default to step 1
      }
      navigte("/CustomStepper")
    }, 1200); // Match this timeout with the animation duration
  };
  // Calculate the number of completed steps
  const completedSteps = [
    !!productId,
    !!materialId,
    !!impactId,
    !!unifiedSMId,
    !!retailerId,
  ].filter(Boolean).length;

  const totalSteps = 6; // Total number of steps
  const remainingSteps = totalSteps - completedSteps; // Remaining steps
  return (
    <div className="bg-slate-200 rounded-lg p-6 shadow-sm flex flex-col justify-between">
 <h2 className="text-lg montserrat-text mb-1">
  {completedSteps === 0  ?
    'Create New Product Passport '
 : `${remainingSteps} more step${remainingSteps > 1 ? 's' : ''} to finish`}  
</h2>
      <div className="relative h-48 mb-3" onClick={handleClick}>
        {/* Outer Doughnut */}
        <Doughnut ref={outerChartRef} data={outerData} options={options(rotationAngle)} />

        {/* Inner Doughnut */}
        <div className="absolute inset-0">
          <Doughnut ref={innerChartRef} data={innerData} options={options(-rotationAngle)} />
        </div>

        {/* Center text - 50% */}
        {completedSteps === 0 ? <div className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer">
  <div style={{ backgroundColor: '#6b46c1' }} className="montserrat-text  relative mt-2.5 flex flex-col items-center justify-center w-32 h-32 rounded-full  animate-pulse zoom">
    <span className="text-xl montserrat-text text-white z-10">Create</span>
    <span className="text-xl montserrat-text text-white z-10">PassPort</span>
  </div>
</div>

 :
 <div className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer">
  <div style={{ backgroundColor: '#6b46c1' }} className="relative mt-2.5 flex flex-col items-center justify-center w-32 h-32 rounded-full  animate-pulse zoom">
    <span className="text-xl montserrat-text text-white z-10">Continue</span>
    <span className="text-xl montserrat-text text-white z-10">PassPort</span>
  </div>
</div>
       }
      </div>
      {/* <ul className="text-left text-gray-700 mt-4">
        <li className="flex items-center mb-2">
          <input type="checkbox" checked={!!productId} className="form-checkbox h-5 w-5 text-blue-600" />
          <span className="ml-2">Basic information</span>
        </li>
        <li className="flex items-center mb-2">
          <input type="checkbox" checked={!!materialId} className="form-checkbox h-5 w-5 text-blue-600" />
          <span className="ml-2">Materials</span>
        </li>
        <li className="flex items-center mb-2">
          <input type="checkbox" checked={!!impactId} className="form-checkbox h-5 w-5 text-blue-600" />
          <span className="ml-2">Impact</span>
        </li>
        <li className="flex items-center mb-2">
          <input type="checkbox" checked={!!unifiedSMId} className="form-checkbox h-5 w-5 text-blue-600" />
          <span className="ml-2">Supplier Manufacturer</span>
        </li>
        <li className="flex items-center mb-2">
          <input type="checkbox" checked={!!retailerId} className="form-checkbox h-5 w-5 text-blue-600" />
          <span className="ml-2">Retailer</span>
        </li>
      </ul> */}
       {/* Cards for Total Emission and Kilometers using Material-UI */}  
          

 {/* Cards for Total Emission and Kilometers using Material-UI */}
 <div className="grid grid-cols-2 gap-4 " >
 <Card 
    icon={<FaCalculator  size={24} className="text-purple-500" />}
    value={total?.totalEmission}
    label="Kg"
    trnspoEmision="Emissions "
    color="bg-purple-100"
    bgColor="#FFEDEC" // Color for Pending Items
    navigateTo="/Product" 
  />

<Card 
    icon={<FaTachometerAlt   size={24} className="text-blue-500" />}
    value={total?.totalTransport}
    label="Km"
    trnspoEmision="Transport "
    color="bg-blue-100"
    bgColor="#F8EAF8" // Color for Pending Suppliers
    navigateTo="/UnifiedSMList" 

  />
  </div>
    </div>
  );
};










const PieChart = ({ title, data }) => (
  <div className="bg-white rounded-lg p-6 shadow-sm">
    <h2 className="text-lg font-semibold mb-4">{title}</h2>
    <div className="relative h-48">
      <svg viewBox="0 0 100 100" className="w-full h-full">
        <circle cx="50" cy="50" r="40" fill="transparent" stroke="#c084fc" strokeWidth="20" />
        <circle cx="50" cy="50" r="40" fill="transparent" stroke="#c084fc" strokeWidth="20" />

        <circle cx="50" cy="50" r="40" fill="transparent" stroke="#fb923c" strokeWidth="20" strokeDasharray="251.2" strokeDashoffset="62.8" />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-4xl montserrat-text">28%</div>
      </div>
    </div>
    <div className="flex justify-center space-x-4 mt-4">
      {data.map((item, index) => (
        <div key={index} className="flex items-center">
          <div className={`w-3 h-3 rounded-full ${item.color} mr-2`}></div>
          <span className="text-sm text-gray-600">{item.label}</span>
        </div>
      ))}
    </div>
  </div>
)

const PatientsByDivision = () => {
  const { saasId } = useAuth(); // Auth context
  const [searchTerm, setSearchTerm] = useState('');
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedUnifiedSM, setSelectedUnifiedSM] = useState('');
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  // Fetch manufacturers from API
  const fetchManufacturers = async (search = '') => {
    try {
      let response;
      if (search) {
        response = await DataService.getSearchUnifiedSM(saasId, search);
      } else {
        response = await DataService.getUnifiedSM(saasId);
      }
      setManufacturers(response.data.data); // Set the fetched manufacturers
    } catch (error) {
      console.error('Failed to fetch manufacturers', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch manufacturers',
        icon: 'error',
      });
    }
  };

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchManufacturers(event.target.value);
  };

  // Handle selection of supplier
  const handleUnifiedSMChange = (event) => {
    const selectedName = event.target.value;
    setSelectedUnifiedSM(selectedName);

    // Find the certificate for the selected supplier
    const selectedSupplier = manufacturers.find(manufacturer => manufacturer.name === selectedName);
    
    if (selectedSupplier) {
      setSelectedCertificate({
        certificateImage: selectedSupplier.certificate1,
        expiryDate: selectedSupplier.date1,
        isExpired: new Date(selectedSupplier.date1) < new Date(), // Check if the certificate is expired
        isNearExpiry: new Date(selectedSupplier.date1) <= new Date(new Date().setMonth(new Date().getMonth() + 3)), // Check if near expiry (3 months)
      });
    } else {
      setSelectedCertificate(null);
    }
  };

  // Fetch manufacturers initially
  useEffect(() => {
    fetchManufacturers();
  }, []);

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <Grid container spacing={4}>
        {/* Header */}
        <Grid item xs={12} md={8} className="mt-2">
          <h3 className="text-lg font-semibold">Certificate Details</h3>
        </Grid>

        {/* Select Dropdown */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Supplier Name</InputLabel>
            <Select
              value={selectedUnifiedSM}
              onChange={handleUnifiedSMChange}
              label="Supplier Name"
            >
              {manufacturers.length > 0 ? (
                manufacturers.map((sm, index) => (
                  <MenuItem key={index} value={sm.name}>
                    {sm.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No suppliers available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        {/* Certificate Table */}
        {selectedCertificate && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="certificate table">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier Name</TableCell>
                    <TableCell>Certificate</TableCell>
                    <TableCell align="center">Image</TableCell>
                    <TableCell align="center">Validation Status</TableCell>
                    <TableCell align="center">Expiry Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{selectedUnifiedSM}</TableCell>
                    <TableCell>{`${selectedUnifiedSM} Certificate`}</TableCell>
                    <TableCell align="center">
                      {selectedCertificate.certificateImage ? (
                        <Image width={80} height={80} src={selectedCertificate.certificateImage} alt={`${selectedUnifiedSM} Certificate`} />
                      ) : (
                        'No Image'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {selectedCertificate.isExpired ? (
                        <span className="text-red-500">Expired</span>
                      ) : selectedCertificate.isNearExpiry ? (
                        <span className="text-yellow-500">Near Expiry</span>
                      ) : (
                        <span className="text-green-500">Valid</span>
                      )}
                    </TableCell>
                    <TableCell align="center">{selectedCertificate.expiryDate || 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </div>
  );
};


const PatientsThisMonth = () => {

  const { saasId
  } = useAuth(); 

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); 
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  // Replace monthlyData with API calls
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  // Only allow selecting years from 2020 to 2024
  const yearOptions = [2023, 2024,2025,2026];
  const fetchSupplyChainData = async (monthIndex) => {
    setLoading(true);
    try {
      const response = await DataService.getsupplychaincountbymounts(saasId, monthNames[monthIndex], selectedYear);
      if (response.status) {
        setTotalData(response?.data?.data?.totalData || 0); 
        return Array.isArray(response?.data?.data?.data) ? response.data.data.data : []; 
      } else {
        return []; // Handle empty or error cases
      }
    } catch (error) {
      console.error("Error fetching supply chain data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadChartData = async () => {
      const dataForCurrentMonth = await fetchSupplyChainData(selectedMonth);
      
      const merge = [0, ...(dataForCurrentMonth || [])];
      // Update chart data dynamically based on selected month and API response
      setChartData({
        labels: ['0', '5', '10', '15', '20', '25', '30'], // Days of the month
        datasets: [
          {
            label: `Supply Chain Count in ${monthNames[selectedMonth]}`,
            data:  merge, // Use data from the API
            fill: false,
            borderColor: '#fff', // Line color
            backgroundColor: '#fff',
            tension: 0.4, // Curved line for smoothness
          },
        ],
      });
    };

    loadChartData();
  }, [selectedMonth]); // Reload data when selectedMonth changes

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#D1D5DB', // Light purple color for x-axis labels
        },
      },
      y: {
        ticks: {
          color: '#D1D5DB', // Light purple color for y-axis labels
          callback: function(value) {
            return Number.isInteger(value) ? value : null; // Only show whole numbers
          },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Paper style={{ background: "#805ad5" }} className="bg-purple-600 rounded-lg p-6 shadow-sm text-white">
      <Grid container spacing={2}>
        <Grid item md={6}>
        <Typography variant="h4" className="mb-1">{totalData}</Typography>
        </Grid>
        <Grid item md={6} className="flex justify-end">
          {/* Month Selector */}
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            className=" border border-none text-white"
            variant="outlined"
            sx={{
              '& .MuiSelect-select': {
                padding: '8px 16px', // Add padding for better appearance
              },
            }}
          >
            {monthNames.map((month, index) => (
              <MenuItem key={index} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>

              {/* Year Selector */}
              <Select
            value={selectedYear}
            onChange={handleYearChange}
            className=" border border-none text-white ml-4"
            variant="outlined"
            sx={{
              '& .MuiSelect-select': {
                padding: '8px 16px', // Add padding for better appearance
              },
            }}
          >
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={12}>
          <Typography className="text-purple-200 mb-4">Product Passports created this month</Typography>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            chartData.datasets.length > 0 && (
              <Line data={chartData} options={options} />
            )
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};







function PassportSelection({setActiveStep}) {
  const {saasId
   
} = useAuth(); 
const [total, settotal] = useState([
]);
const GetTotalCount = async () => {
  try {
    const response = await DataService.getdasboardcount(saasId);
   settotal(response?.data?.data || []);
  } catch (error) {
    console.error('Failed to fetch Brand', error);
  }
};



useEffect(() => {
  GetTotalCount()

}, [])

  return (
    <div className="bg-gray-100 min-h-screen p-8" style={scrollableContainerStyle}>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6" >
  <Card 
    icon={<FaPassport size={24} className="text-purple-600" />}
    value={total?.passportCount}
    label="Product"
    color="bg-purple-100"
    bgColor="#FFEDEC" // Color for Pending Items
    navigateTo="/Product" 
  />
  <Card 
    icon={<FaTruck size={24} className="text-blue-600" />}
    value={total?.supplierCount}
    label="Supplier"
    color="bg-blue-100"
    bgColor="#F8EAF8" // Color for Pending Suppliers
    navigateTo="/UnifiedSMList" 

  />
    <Card 
    icon={<Store size={24} className="text-pink-600" />}
    value={total?.retailerCount}
    label="Retailer"
    color="bg-pink-100"
    bgColor="#F4F6F5" // Color for Product Compliance
    navigateTo="/AddRetailer" 

  />
  <Card 
    icon={<PersonIcon size={24} className="text-orange-600" />}
    value={total?.userCount}
    label="User"
    color="bg-orange-100"
    bgColor="#FEF4DB" // Color for Pending Users
    navigateTo="/AllUser" 

  />

</div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <CompletionCard setActiveStep={setActiveStep}/>

      {/* <PieChart 
          title="Product Sustainability" 
          data={[
            { label: 'Sustainable', color: 'bg-purple-500' },
            { label: 'Not Sustainable', color: 'bg-orange-500' },
          ]}
        /> */}
        <PatientsThisMonth />
      </div>
      <div className="grid   mt-6 mb-20">
      {/* <BarChart /> */}
       
        {/* <PatientsByDivision categoryList={categoryList} /> */}
      </div>
      <div className="mt-6">
        {/* <CompletionCard /> */}
        {/* <TimeAdmitted /> */}
      </div>
    </div>
  )
}


export default PassportSelection
