import React from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  useMediaQuery,
  Chip,
  DialogContent,
  DialogTitle,
  Dialog,
  CardMedia,
  Grid
} from '@mui/material';
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Business as BusinessIcon,
  EmojiEvents as AwardIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const theme = createTheme();

const ViewSupplier = ({row}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedCertificate, setSelectedCertificate] = React.useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const certificates = [
    { id: 1, name: "Certificate 1", image: row?.certificate1, date: row?.date1 },
    { id: 2, name: "Certificate 2", image: row?.certificate2, date: row?.date2 },
  ].filter(cert => cert.image);

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90%' : 600,
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <IconButton aria-label="view" onClick={handleOpen}>
        <RemoveRedEyeIcon style={{ color: 'green' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={theme}>
          <Box sx={style}>
            <Card className='p-2 shadow-none' elevation={3}>
              
              <Typography id="retailer-modal-title" variant="h4" component="h2" gutterBottom>
            {row?.name}
          </Typography>
              <Typography variant="subtitle1" gutterBottom>
            {row.type} ID: {row?.id} | SAAS ID: {row?.saasId}
          </Typography>
              <Card sx={{ display: 'flex', mb: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 156 }}
              image={row?.logo}
              alt={`${row?.name} logo`}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h6" gutterBottom>
                  Address
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {row?.address?.flatNo}<br />
                  {row?.address?.cityArea}<br />
                  {row?.address?.state}, {row?.address?.country}<br />
                  PIN: {row?.address?.pincode}
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Phone:</strong> {row?.contactInfo?.phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Alternate Phone:</strong> {row?.contactInfo?.phone2}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Email:</strong> {row?.contactInfo?.email}
              </Typography>
            </Grid>
            {row?.contactInfo.email1 !== row?.contactInfo?.email && (
              <Grid item xs={6}>
                <Typography className='text-nowrap' variant="body2">
                  <strong>Alternate Email:</strong> {row?.contactInfo?.email1}
                </Typography>
              </Grid>
            )}
          </Grid>
              <CardContent className='py-3 px-0'>
                <Typography variant="h6" gutterBottom>
                  Certificates
                </Typography>
                {certificates.length > 0 ? (
                  <List>
                    {certificates.map((cert) => (
                      <ListItem key={cert.id} button onClick={() => handleCertificateClick(cert)}>
                        <ListItemIcon>
                          <AwardIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={cert.name} 
                          secondary={cert.date ? new Date(cert.date).toLocaleDateString() : "Date not provided"} 
                        />
                        <Chip label="View" color="primary" size="small" onClick={() => handleCertificateClick(cert)} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="text.secondary">No certificates available</Typography>
                )}
              </CardContent>
            </Card>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </Box>
          </Box>
        </ThemeProvider>
      </Modal>
      <Dialog
        open={!!selectedCertificate}
        onClose={() => setSelectedCertificate(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedCertificate?.name}
          <IconButton
            aria-label="close"
            onClick={() => setSelectedCertificate(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            src={selectedCertificate?.image}
            alt={selectedCertificate?.name}
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '70vh',
              objectFit: 'contain'
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewSupplier;