import React from 'react';  
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';  
import CarbonFootprintpng from '../../../assets/Natural Food.png';  
import WaterUsagepng from '../../../assets/Water.png';  
import Recyclabilitypng from '../../../assets/Recycle.png';  
import SustainableMaterialspng from '../../../assets/Environment Care.png';  
import EnergyEfficiencypng from '../../../assets/Flash On.png';  
import EthicalProductionpng from '../../../assets/Factory.png';  
import ChemicalSafetypng from '../../../assets/Biohazard.png';  
import TransportationImpactpng from '../../../assets/Container Truck.png';  
import Certificationspng from '../../../assets/Certification.png';  
import Durabilitypng from '../../../assets/Time Machine.png';
import Phone from '../../../assets/Phone.png';
import Email from '../../../assets/Post.png';
import Location from '../../../assets/Location.png';
import oneScan from '../../../assets/oneScan.png';




import { MdEmail, MdPhone } from 'react-icons/md';
import pdfcardbg from '../../../assets/pdfCardbg.png';  
import { BASEURL } from '../../../services/http-common';

// Define styles for the PDF  
const styles = StyleSheet.create({  
  page: {  
    flexDirection: 'column',  
    padding: 10,  
    backgroundColor: '#ffffff',  
  },  
  section: {  
    marginVertical: 10,  
    borderRadius: 8,  
    padding: 15,  
    backgroundColor: '#f8f8f8',  
  },  
  title: {  
    fontSize: 18,  
    fontWeight: 'bold',  
    marginBottom: 10,  
    textDecoration: 'underline',  
    color: '#333',  
  },  
  productCard: {  
    marginBottom: 10,  
    padding: 10,  
    borderRadius: 8,  
    backgroundColor: '#ffffff',  
    border: '1px solid #e1e1e1',  
  },  
  imageContainer: {  
    width: '40%',  
    marginRight: 10,  
  },  
  textContainer: {  
    width: '60%',  
    display: 'flex',  
    flexDirection: 'column',  
  },  
  progressCircle: {  
    borderRadius: "100%",  
    width: 50,  
    height: 50,  
    border: "2px solid #4F46E5",  
    alignItems: 'center',  
    justifyContent: 'center',  
    marginRight: 10,  
  },  
  button: {  
    fontSize: 12,  
    backgroundColor: '#4F46E5',  
    color: '#fff',  
    padding: 5,  
    textAlign: 'center',  
    borderRadius: 4,  
    marginVertical: 5,  
  }, 
  blankcolor:{   backgroundColor: '#fff',     color: '#fff',  }, 
  normalText: {  
    
    fontSize: 12,  
    color: '#555',  
    lineHeight: 1.5,  
  },  
  image: {  
    width: '100%',  
    height: 'auto',  
    borderRadius: 5,  
  },  
  sectionHeading: {  
    fontSize: 16,  
    fontWeight: 'bold',  
    marginVertical: 10,  
  },  
  contactInfo: {  
    marginBottom: 10,  
  },  
  transportDetails: {  
    marginBottom: 10,  
  },  
  subSection: {
    marginBottom: 12, // Space between each subsection
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  endAligned: {  
    flex: 1,  
    justifyContent: 'flex-end',  
    alignItems: 'flex-end',  
  },
  pagination: {  
    position: 'absolute',  
    bottom: 10,  
    left: '50%',  
    transform: [{ translateX: '-50%' }],  
    fontSize: 12,  
    textAlign: 'center',  
    color: '#333', // You can adjust the color as needed  
  },  
  pageNumber: {  
    fontSize: 12,  // Font size for the page number  
    color: '#555',  // Color for the page number text  
  },
  grid: {  
    flexDirection: 'row',  
    flexWrap: 'wrap',  
    justifyContent: 'space-between',  
  },  
  metricCard: {  
    width: '30%', // Adjust this width for responsiveness  
    borderRadius: 10,  
    padding: 10,  
    margin: 5,  
    backgroundColor: '#FFFFFF',  
    alignItems: 'center',  
  },  
  icon: {  
    width: 50,  
    height: 50,  
    marginBottom: 5,  
  },  
  label: {  
    fontSize: 14,  
    marginBottom: 4,  
    textAlign: 'center',  
    fontFamily: 'Helvetica',  
  },  
  value: {  
    fontSize: 18,  
    fontWeight: 'bold',  
    color: '#4caf50',  
    textAlign: 'center',  
    fontFamily: 'Helvetica',  
  },  
  card: {  
    margin: 'auto',  
    padding: 20,  
    width: '90%',  
    borderRadius: 8,  
  backgroundColor:"#EBFEF6"  
  },  
  gradientBackground: {  
    position: 'absolute',  
    top:0,  
    left:0,  
    right:0,  
    bottom:0,  
    width: '100%',  
    height: '100%',  
    resizeMode: 'cover',
  },   
  cardHeader: {  
    backgroundColor: '#048a52', // dark purple  
    color: '#FFFFFF',  
    padding: 10,  
    borderRadius: 4,  
    textAlign: 'center',  
    fontSize: 20,  
    marginBottom:10,
  },  
  section: {  
    margin: 10,  
    padding: 10,  
    backgroundColor: '#FFFFFF', // white background  
    borderRadius: 4,  
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',  
  },  
  title: {  
    fontWeight: 'bold',  
    fontSize: 16,  
    color: '#4B5563', // gray  
  },  
  subtitle: {  
    fontSize: 14,  
    color: '#6B5BCA', // purple  
  },  
  text: {  
    fontSize: 12,  
    color: '#4A5568', // dark gray  
  },  
  flexRow: {  
    display: 'flex',  
    flexDirection: 'row',  
    // alignItems: 'center',  
    justifyContent: 'space-between',  
  }, 
  retailericon: {  
    width:20,   
    flexRow: {  
    display: 'flex',  
    flexDirection: 'row',  
    justifyContent: 'flex-start',  },
  },
  flexIcon: {  
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  }, 
  hr: {
    borderBottomWidth: 1,
    borderBottomColor: '#000', // Set color
    marginVertical: 8,         // Adjust spacing above and below the line
  },
});  

const PdfDocument = ({  
  metrial,
  productData,  
  impactEntity,  
  supplyChainEntity,  
  retailerData,  
  unifiedSupplierManufacturerData,  
}) => {
  const metrics = [
    { icon: CarbonFootprintpng, label: "Carbon Footprint", value: `${impactEntity?.carbonFootprint?.totalCarbonFootprint} kg CO2` },
    { icon: WaterUsagepng, label: "Water Usage", value: `${impactEntity?.waterUsage?.totalWaterUsage} L` },
    { icon: Recyclabilitypng, label: "Recyclability", value: `${impactEntity?.recyclability?.totalRecyclability}%` },
    { icon: SustainableMaterialspng, label: "Sustainable Materials", value: `${impactEntity?.sustainableMaterials?.totalSustainable}%` },
    { icon: EnergyEfficiencypng, label: "Energy Efficiency", value: `${impactEntity?.energyEfficiency?.totalEnergyEfficiency}%` },
    { icon: EthicalProductionpng, label: "Ethical Production", value: `${impactEntity?.ethicalProduction?.totalEthicalProduction}%` },
    { icon: ChemicalSafetypng, label: "Chemical Safety", value: `${impactEntity?.chemicalSafety?.totalChemicalSafety}%` },
    { icon: TransportationImpactpng, label: "Transportation Impact", value: `${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2` },
    { icon: Certificationspng, label: "Certifications", value: `${impactEntity?.certifications?.totalCertificationScore}%` },
    { icon: Durabilitypng, label: "Durability", value: `${impactEntity?.durability?.totalDurabilityScore}%` },
  ];
   
  return (  
    <Document>  
   

      {/* Product Data Page */}  
    <Page size="A4" style={styles.page}>  












    <View style={styles.card}>  
 {/* Add background image */}  
 {/* <Image source={pdfcardbg} style={styles.gradientBackground} />   */}
 <View style={styles.section}>  
 <Image  style={styles.image}  source={oneScan} />
 <View style={styles.hr} />
 </View>
 {/* Card Content */}  
 <Text style={styles.cardHeader}>Product Details</Text>  


       
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>  
              <View style={styles.imageContainer}>  
                <Image src={productData?.image} alt="Product Image" style={styles.image} />  
              </View>  

              <View style={styles.textContainer}>  
                <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 5 }}>{productData?.name}</Text>  
                {/* <Text style={styles.button}>End product</Text>   */}
                <Text style={styles.normalText}>{productData?.description}</Text>
                <View style={styles.flexIcon}>   
                <Text style={styles.normalText}>{productData?.brand || 'N/A'}</Text> 
                <Image src={`${BASEURL.ENDPOINT_URL}brand/get-image-by-name/${productData?.brand}`} alt="Product Image" style={{width:40, height:40, marginLeft:10, borderRadius:30}} />  

                </View>  

              </View>  
            </View>  
            <View style={{ marginTop: 10 }}>  
              <Text style={styles.normalText}>Defined use period (years): {productData?.basicInfo?.usePeriod}</Text>  
              {metrial?.[0] &&  (
  <View>
    <Text style={styles.normalText}>
      Dominant material group(s) Type: {metrial[0]?.materialType || 'N/A'}
    </Text>
    <Text style={styles.normalText}>
      Dominant material group(s) Name: {metrial[0]?.materialName || 'N/A'}
    </Text>
  </View>
)}
              <Text style={styles.normalText}>Next use application: {productData?.basicInfo?.useApplication}</Text>  
              <Text style={styles.normalText}>End of use Treatment: {productData?.basicInfo?.usedTreatment}</Text>  
              <Text style={styles.normalText}>Weight: {productData?.basicInfo?.weight} {productData?.basicInfo?.weightUnit}</Text>  
              <Text style={styles.normalText}>Quantity: {productData?.basicInfo?.quantity} Items</Text>  
              <Text style={styles.normalText}>MSDS: {productData?.basicInfo?.msds}</Text>  
            </View>  
            <View style={styles.section}>  
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>  
              <View style={styles.progressCircle}>  
                <Text style={{ fontSize: 12 }}>{productData?.progressBar}%</Text>  
              </View>  
              <View style={styles.textContainer}>  
                <Text style={{ fontWeight: 'bold' }}>  
                  This passport is on the right track.  
                </Text>  
              </View>  
            </View>  
          </View>  
          </View>  

     
         {/* Pagination */}  
         <View style={styles.pagination}>  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (  
          `${pageNumber} / ${totalPages}`  
        )} />  
      </View>  
    </Page>  

        {/* Metrics Page */}  
        <Page size="A4" style={styles.page}>   
        <View style={styles.card}>  
 {/* Add background image */}  
 {/* <Image source={pdfcardbg} style={styles.gradientBackground} />   */}

 {/* Card Content */}  
 <Text style={styles.cardHeader}>Impact Details</Text>  

        <View style={styles.grid}>  
            {metrics.map((metric, index) => (  
              <View key={index} style={styles.metricCard}>  
                <Image src={metric.icon} style={styles.icon} />  
                <Text style={styles.label}>{metric.label}</Text>  
                <Text style={styles.value}>{metric.value}</Text>  
              </View>  
            ))}  
          </View>  
 
      </View>  
    {/* Pagination */}  
    <View style={styles.pagination}>  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (  
          `${pageNumber} / ${totalPages}`  
        )} />  
      </View>  
    </Page>  
     
 

          {/* Supply Chain Entity Page */}  
 {/* Supply Chain Entity Page */}  
<Page size="A4" style={styles.page}>  
  <View style={styles.card}>  
    {/* Add background image */}
    {/* <Image source={pdfcardbg} style={styles.gradientBackground} />   */}

    {/* Card Content */}
    <Text style={styles.cardHeader}>Supply Chain Information</Text>  

    {/* Contact Info */}
    <View style={styles.section}>  
      <View style={styles.flexRow}>  
        <Text style={styles.title}>Contact Information</Text>  
      </View>  
      <View>
        <View style={styles.flexIcon}>  
          <Image source={Phone} style={styles.retailericon} />  
          <Text style={styles.text}>Phone (Primary): {supplyChainEntity?.contactInfo?.phone || 'N/A'}</Text>  
        </View>  
        <View style={styles.flexIcon}>  
          <Image source={Phone} style={styles.retailericon} />  
          <Text style={styles.text}>Phone (Secondary): {supplyChainEntity?.contactInfo?.phone2 || 'N/A'}</Text>  
        </View>  
        <View style={styles.flexIcon}>  
          <Image source={Email} style={styles.retailericon} />  
          <Text style={styles.text}>Email (Primary): {supplyChainEntity?.contactInfo?.email || 'N/A'}</Text>  
        </View>  
        {supplyChainEntity?.contactInfo?.email1 && (
          <View style={styles.flexIcon}>  
            <Image source={Email} style={styles.retailericon} />  
            <Text style={styles.text}>Email (Secondary): {supplyChainEntity?.contactInfo?.email1}</Text>  
          </View>  
        )}
      </View>  
    </View>  

    {/* Transport Details */}
    <View style={styles.section}>  
      <View style={styles.flexRow}>  
        <Text style={styles.title}>Transport Details</Text>  
      </View>  
      <View>
        <Text style={styles.text}>Method: {supplyChainEntity?.transportDetails?.transportMethod || 'N/A'}</Text>  
        <Text style={styles.text}>Route: {supplyChainEntity?.transportDetails?.transportRout || 'N/A'}</Text>  
        <Text style={styles.text}>Route Start: {supplyChainEntity?.transportDetails?.transportRoutStart || 'N/A'}</Text>  
        <Text style={styles.text}>Route End: {supplyChainEntity?.transportDetails?.transportRoutEnd || 'N/A'}</Text>  
      </View>  
    </View>  

    {/* Warehouse Details */}
    <View style={styles.section}>  
      <View style={styles.flexRow}>  
        <Text style={styles.title}>Warehouse Details</Text>  
      </View>  
      <View>
        <Text style={styles.text}>Name: {supplyChainEntity?.warehouseDetails?.warehouseName || 'N/A'}</Text>  
        <Text style={styles.text}>License No: {supplyChainEntity?.warehouseDetails?.warehouseLicensNo || 'N/A'}</Text>  
        <Text style={styles.text}>Manager: {supplyChainEntity?.warehouseDetails?.warehouseManager || 'N/A'}</Text>  
        <Text style={styles.text}>GST No: {supplyChainEntity?.warehouseDetails?.warehouseGstNo || 'N/A'}</Text>  
      </View>  
    </View>  

  
  </View> 
    {/* Pagination */}
    <View style={styles.pagination}>  
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (  
        `${pageNumber} / ${totalPages}`  
      )} />  
    </View>   
</Page>
 



   
    {/* Retailer Data Page */}  
    <Page style={styles.page}>  
 <View style={styles.card}>  
 {/* Add background image */}  
 {/* <Image source={pdfcardbg} style={styles.gradientBackground} />   */}

 {/* Card Content */}  
 <Text style={styles.cardHeader}>Retailer Details</Text>  

 {/* Retailer ID */}  
 <View style={styles.section}>
 <View style={styles.flexRow}>  
 <View >    
 <Text style={styles.title}>Retailer ID</Text>  
 </View>  
 <View >  
 <Text style={styles.text}>{retailerData?.retailerId || 'N/A'}</Text>  
 </View>  
 </View>  
 </View>  

 {/* Retailer's Name */}  
 <View style={styles.section}>  
 <View style={styles.flexRow}>  
 <View > 

 <Text style={styles.title}>Retailer's Name</Text> 
 </View>  
 <View style={styles.flexRow} >   
 <Image src={`${BASEURL.ENDPOINT_URL}retailer/get-image-by-id/${retailerData.retailerId}`} alt="Product Image" style={{width:20, height:20, marginRight:10, borderRadius:30}} /> 
 <View style={{marginTop:2}} >  
 <Text style={styles.text}>{retailerData?.name || 'N/A'}</Text>  
 </View> 
 </View>  

 </View>  
 </View>  


 {/* Retailer Contact Numbers */}  
 <View style={styles.section}>  
 <View style={styles.flexRow}>  
 <View >  
 <Text style={styles.title}>Contact Numbers</Text>  
 </View>
<View>
 <View style={styles.flexIcon}>  
 <Image source={Phone} style={styles.retailericon} />  
 <Text style={styles.text}>(+91) {retailerData?.contactInfo?.phone || 'N/A'} (Primary)</Text>  
 </View>  
 <View style={styles.flexIcon}>  

 <Image source={Phone} style={styles.retailericon} />  
 <Text style={styles.text}>(+91) {retailerData?.contactInfo?.phone2 || 'N/A'} (Secondary)</Text>  
 </View>  
 </View>  
 </View>
 </View>  

 {/* Retailer Emails */}  
 <View style={styles.section}>  
 <View style={styles.flexRow}> 
 <View >   
 <Text style={styles.title}>Emails</Text>  
 </View>  
<View>
 <View style={styles.flexIcon}>  
<Image source={Email} style={styles.retailericon} /> <Text style={styles.text}>{retailerData?.contactInfo?.email || 'N/A'} (Primary)</Text>  
 </View>  
 {retailerData?.contactInfo?.email2 && (  
 <View style={styles.flexIcon}>  

 
 
 <Image source={Email} style={styles.retailericon} /><Text style={styles.text}>{retailerData?.contactInfo?.email2} (Secondary)</Text>  
 </View>  
 )}  
 </View>
 </View>  
 </View>  


 {/* Retailer Address */}  
 <View style={styles.section}>  
 <View style={styles.flexRow}> 
 <View > 
 <Text style={styles.title}>Address</Text>  
 </View>  
 <View >   
 <Text style={styles.text}>  
 <Image source={Location} style={styles.retailericon} />  

 {`${retailerData?.address?.flatNo || ''}, ${retailerData?.address?.cityArea || ''}, ${retailerData?.address?.district || ''}, ${retailerData?.address?.state || ''}, ${retailerData?.address?.country || ''}`}  
 </Text>  
 </View>  
 </View>  
 </View>  
 </View>  
  {/* Pagination */}
  <View style={styles.pagination}>  
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (  
        `${pageNumber} / ${totalPages}`  
      )} />  
    </View>  
 </Page>  



        
  {/* Unified Supplier Manufacturer Data Page */}  
<Page size="A4" style={styles.page}>  
<View style={styles.card}> 
  {/* Unified Supplier Manufacturer Data Section */}  
  {/* <Image source={pdfcardbg} style={styles.gradientBackground} />   */}
  <Text style={styles.cardHeader}>Supplier Information</Text>  
  {/* <Text style={styles.title}> Supplier Information</Text>   */}


  <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View>   
        <Text style={styles.normalText}>Supplier Name</Text>  
        </View > 
       
        <View > 


        <View style={styles.flexIcon}> 
            <Image src={`${BASEURL.ENDPOINT_URL}supplier-manufacture/get-image-by-id/${unifiedSupplierManufacturerData?.supplierManufactureId}`} style={{width:40, height:40, marginRight:10, borderRadius:30}} />  
             <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.name || "N/A"}</Text>  
        </View>    </View>  
      </View>   
      </View>  


  <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View>   
        <Text style={styles.normalText}>Location</Text>  
        </View > 
       
        <View > 


        <View style={styles.flexIcon}> 
            <Image source={Location} style={styles.retailericon} />  
             <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Text>  
        </View>    </View>  
      </View>   
      </View>  

      <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View>   
        <Text style={styles.normalText}>Manufacturing Date</Text> 
        <Text style={styles.normalText}>Supply Date</Text>  

        </View>   
        <View> 
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.manufactureDate || 'N/A'}</Text> 
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierDate || 'N/A'}</Text>  

        </View>  
      </View>   
      </View>  
{/* 

    <View style={styles.row}>  
      <View>  
        <Text style={styles.sectionHeading}>Batch/Serial Number</Text>  
      </View>  
      <View style={styles.endAligned}>  
        <Text style={styles.sectionHeading}>Supply Date</Text>  
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierDate || 'N/A'}</Text>  
      </View>  
    </View>   */}

    <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View> 
        <Text style={styles.normalText}>Payment Terms</Text>  
        <Text style={styles.normalText}>Delivery Terms</Text>  

        </View>   
        <View> 
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.paymentTerms || 'N/A'}</Text>  
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.deliveryTerms || 'N/A'}</Text>  

        </View>  
      </View>   
      </View>  

      <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View> 
        <Text style={styles.normalText}>Warranty </Text>  
        <Text style={styles.normalText}> Betch/Serial Number</Text>  

        </View>  
        <View> 
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.warranty || 'N/A'}</Text>  
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.number || 'N/A'}</Text>  

        </View>   
      
      </View>   
      </View>  

    <View style={styles.section}>  
 <View style={styles.flexRow}> 
   <View> 
  
        <Text style={styles.normalText}>Manufacturing Cost</Text> 
        <Text style={styles.normalText}>Supplier Price</Text>  

        </View>   
        <View> 
        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.manufactureCost || 'N/A'}</Text>  

        <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierPrice || 'N/A'}</Text>  
        </View>  
      </View>   
      </View>  

   

  </View>  
  
  {/* Pagination */}  
  <View style={styles.pagination}>  
    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (  
      `${pageNumber} / ${totalPages}`  
    )} />  
  </View>  
</Page>

    
    </Document>  
  );  
};  

export default PdfDocument;
