import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { Box } from '@mui/system';
import DataService from '../../services/requestApi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import EditProductModal from '../Modal/EditProductModal';
import AddCategoryModal from '../Modal/AddCategoryModal';
import { useAuth } from '../../contexts/AuthConext';

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();
  const {saasId
  } = useAuth(); 
  const fetchProducts = async (page, search = '', category = '') => {
    try {
      let response;
      if (search) {
        response = await DataService.searchproductByName(saasId,search);
      } else if (category && category !== 'all') {
        response = await DataService.searchProductsByCategory(saasId,category);
      } else {
        response = await DataService.getproductNameList(saasId,page);
      }
      if (response.data.status) {
        setProducts(response.data.data);
        setTotalPages(response.data.totalPage);
      } else {
        console.error('Failed to fetch products:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  useEffect(() => {
    if (selectedProduct) {
      setFormValues(selectedProduct);
    }
  }, [selectedProduct]);

  const handleViewClick = (productId) => {
    navigate('/ProductTabs', { state: { productId } });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product.productName);
    setModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('basicInfo.')) {
      const fieldName = name.replace('basicInfo.', '');
      setFormValues({
        ...formValues,
        basicInfo: {
          ...formValues.basicInfo,
          [fieldName]: value,
        },
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSaveClick = async () => {
    try {
      const response = await DataService.updateProduct(selectedProduct.productId, formValues);
      if (response.data.status) {
        Swal.fire({
          title: 'Success!',
          text: 'Product updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        fetchProducts(1);
        setModalOpen(false);
      } else {
        console.error('Failed to update product:', response.data.message);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.response?.data?.error || 'Failed to update product.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error('Failed to update product:', error);
    }
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchProducts(1, event.target.value, selectedCategory); // Search with category filter
  };

  const fetchCategoryList = async () => {
    try {
      const response = await DataService.getCategoryList(saasId);
      if (response.data.status) {
        setCategoryList(response.data.data);
      } else {
        console.error('Failed to fetch category list:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    fetchProducts(1, searchTerm, event.target.value); // Fetch products by category
  };

  const handleAddCategoryClick = () => {
    setCategoryModalOpen(true);
    fetchCategoryList()
  };

    // New delete function
    const handleDeleteClick = async (productId) => {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      });
  
      if (result.isConfirmed) {
        try {
          const response = await DataService.deleteSupplychainById(productId);
          if (response.data.status) {
            Swal.fire('Deleted!', 'The Supply Chain has been deleted.', 'success');
            fetchProducts(page); // Refresh the product list
          } else {
            Swal.fire('Error!', response.data.message, 'error');
          }
        } catch (error) {
          Swal.fire('Error!', 'Failed to delete product.', 'error');
        }
      }
    };

  const columns = [
    {
      name: 'Product ID',
      center: true,
      selector: (row) => row.productId ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Product Name',
      center: true,
      selector: (row) => row.productName?.name ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Product Category',
      center: true,
      selector: (row) => row.productName?.category ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Supplier Name',
      center: true,
      selector: (row) => row.suppliermanufaturerName ?? 'N/A',
      sortable: true,
    },

    // {
    //   name: 'Supplier Name',
    //   selector: (row) => row.supplierName?.join(', ') ?? 'N/A',
    //   sortable: true,
    // },
    {
      name: 'Retailer Name',
      center: true,
      selector: (row) => row.retailerName?.join(', ') ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: (row) => row.actions,
      center: true,
      cell: (row) => (
        <div>
          <IconButton aria-label="edit" onClick={() => handleViewClick(row.productName?.productId ?? '')}>
            <Edit style={{ color: 'green' }} />
          </IconButton>
          <IconButton aria-label="delete"         onClick={() => handleDeleteClick(row.supplyChainId)}>
            <Delete style={{ color: '#C81D20' }} />
          </IconButton>
          <IconButton aria-label="view" onClick={() => handleViewClick(row.productName?.productId ?? '')}>
            <Visibility style={{ color: 'green' }} />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create New Product</h1>
            <Button className='fw-bold' style={{background:"#a855f7"}} variant="contained" color="primary" onClick={() => navigate('/CustomStepper')}>
              Create 
            </Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <div className="flex justify-between">
            <h2 className="text-xl mb-4 fw-bold">Product Catalog</h2>
            <Button className="mb-4 montserrat-text-normal fw-bold" style={{background:"#a855f7"}} variant="contained" color="primary" onClick={handleAddCategoryClick}>
               Category
            </Button>
          </div>
          <div className="flex mb-2">
            <FormControl variant="outlined" style={{ width: '200px', marginBottom: '16px' }}>
              <InputLabel>All Category</InputLabel>
              <Select value={selectedCategory} onChange={handleCategoryChange} label="All Category">
                <MenuItem value="all">All Category</MenuItem>
                {categoryList.map((category) => (
        <MenuItem key={category.id} value={category.categoryName}>
          {category.categoryName}
        </MenuItem>
          ))}
              </Select>
            </FormControl>
            <TextField
              label="Search Product Name "
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ height: '300px', overflow: 'auto' }}>
            <DataTable
              columns={columns}
              data={products}
              pagination
              paginationServer
              // paginationTotalRows={totalPages}
              // onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    minHeight: '72px',
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
  
      <AddCategoryModal
        isOpen={categoryModalOpen}
        categoryList={categoryList}
        toggle={() => setCategoryModalOpen(!categoryModalOpen)}
        fetchCategoryList={fetchCategoryList}
      />
    </>
  );
};

export default ProductListPage;
