import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./contexts/AuthConext";
import Cookies from "js-cookie";

const ProtectedRoute = ({ index, page }) => {
  const { permission, Getpermission, role } = useAuth(); // Assuming useAuth provides role and permissions
  const isAuthenticated = Cookies.get("authToken");

  // Fetch permissions on mount if necessary
  useEffect(() => {
    if (!permission) {
      Getpermission(); // Ensure permissions are fetched if not available
    }
  }, [permission, Getpermission]);

  // Function to check the user's permission for a specific page
  const checkPermission = (index, page) => {
    return (
      role === "ADMIN" || 
      (role === "USER" && 
       permission[index]?.name === page && 
       permission[index]?.status === true)
    );
  };

  // Render based on authentication and permission checks
  return isAuthenticated ? (
    checkPermission(index, page) ? (
      <Outlet />
    ) : (
      <Navigate to="/Error" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
