import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import classNames from 'classnames';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PassportSelection from './PassportSelection';
import EndProductStepOne from './EndProductStepOne';
import EndProductStepThree from './EndProductStepThree';
import EndProductStepFour from './EndProductStepFour';
import EndProductStepFive from './EndProductStepFive';
import EndProductStepSix from './EndProductStepSix';
import EndProductStepSeven from './EndProductStepSeven';
import EndProductStepEith from './EndProductStepEith';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthConext';
import UnifiedSupplierManufacturer from '../Unified/UnifiedSupplierManufacturer';


const steps = [
  'Basic information',
  'Materials',
  'Impact',
  // 'Manufacturer',
  // 'Supplier Entity',
  'Supplier Manufacturer',

  'Retail Entity',        // Add this
  'Logistics and distribution',       // Add this
];

const CustomStepper = ({handledirct, activeStep, handleNext, handleBack, handleReset }) => {

  const { isAuthenticated,saasId } = useAuth();
  const navigate = useNavigate();
  const productId = localStorage.getItem('productId');


  // basic info state
  const [formValues, setFormValues] = useState({
    productName: "",
    description: "",
    weight: "",
    weightUnit: "",
    quantity: "",
    quantityUnit: "",
    length: "",
    width: "",
    height: "",
    usePeriod: "",
    msds: [],
    nextUseApplication:[],
    endOfUseTreatment: [],
    // code: "PROD-CODE-456",
    serialNumber: "",
    // hsCode: "HS-CODE-123",
    // eanNumber: "EAN-789",
    category: "",
    // sku: "",
    brand: "",
    manufacturingDate: "",
    expirationDate: ""
  });


// impact state 
const [addedMaterial, setAddedmaterial] = useState([]);
const [selectedTab, setSelectedTab] = useState([]);
const [SelectedMatarial, setSelectedMatrial] = useState([]);
    //  new impact screen state
    const [categories, setCategories] = useState([
      {
        name: "Carbon Footprint",
        isOpen: true,
        metrics: [
          { label: "Raw Material Emissions", value: "", unit: "CO2" },
          { label: "Production Emissions", value: "", unit: "CO2" },
          { label: "Transportation Emissions", value: "", unit: "CO2" },
          { label: "Use Emissions", value: "", unit: "CO2" },
          { label: "Disposal Emissions", value: "", unit: "CO2" },
        ],
      },
      {
        name: "Water Usage",
        isOpen: false,
        metrics: [
          { label: "Water Used in Production", value: "", unit: "Liters" },
          { label: "Water Used in Packaging", value: "", unit: "Liters" },
          { label: "Water Used in Transportation", value: "", unit: "Liters" },
        ],
      },
      {
        name: "Recyclability",
        isOpen: false,
        metrics: [
          { label: "Recyclable Material Weight", value: "", unit: "grams" },
          { label: "Total Material Weight", value: "", unit: "grams" },
        ],
      },
      {
        name: "Sustainable Materials",
        isOpen: false,
        metrics: [
          { label: "Weight of Sustainable Materials", value: "", unit: "grams" },
          { label: "Total Weight of Product", value: "", unit: "grams" },
        ],
      },
      {
        name: "Energy Efficiency",
        isOpen: false,
        metrics: [
          { label: "Output Energy", value: "", unit: "kWh" },
          { label: "Input Energy", value: "", unit: "kWh" },
        ],
      },
      {
        name: "Ethical Production",
        isOpen: false,
        metrics: [
          { label: "Number of Ethical Practices", value: "", unit: "Num" },
          { label: "Total Practices", value: "", unit: "Num" },
        ],
      },
      {
        name: "Chemical Safety",
        isOpen: false,
        metrics: [
          { label: "Number of Safe Chemicals", value: "", unit: "Num" },
          { label: "Total Chemicals Used", value: "", unit: "Num" },
        ],
      },
      {
        name: "Transportation Impact",
        isOpen: false,
        metrics: [
          { label: "Distance", value: "", unit: "km" },
          { label: "Emission Factor", value: "", unit: "kgCO2/km" },
        ],
      },
      {
        name: "Certifications",
        isOpen: false,
        metrics: [
          { label: "Number of Certifications", value: "", unit: "Num" },
          { label: "Total Possible Certifications", value: "", unit: "Num" },
        ],
      },
      {
        name: "Durability/Lifespan",
        isOpen: false,
        metrics: [
          { label: "Expected Lifespan", value: "", unit: "years" },
          { label: "Actual Lifespan", value: "", unit: "years" },
        ],
      },
    ])


    // unifield supplier state
    const [formData, setFormData] = useState({
      saasId:saasId,
      productId:"",
      supplierManufactureId: '',
      name: '',
      flatNo: '',
  
      phone: '',
      phone2: '',
      email: '',
      email1: '',
      certifications: [],
  
      manufactureDate: "",
      number: "",
      supplierDate: "",
      supplierLocation: "",
      paymentTerms: "",
      deliveryTerms: " ",
      warranty: " ",
      manufactureCost: "",
      supplierPrice: ""
    });

    // Retailer state
    const [retailerData, setRetailerData] = useState({
      saasId:saasId,
      retailerId: "",
      name: "",
      address: {
        flatNo: "",
        cityArea: "",
        country: "",
        state: "",
        district: "",
        pincode: "",
      },
      contactInfo: {
        phone: "",
        phone2: "",
        email: "",
        email1: "",
      },
    });
  

  const getStepContent = (step) => {
    switch (step) {
    
      case 0:
        return <EndProductStepOne formValues={formValues} setFormValues={setFormValues} handleNext={handleNext} />;
      case 1:
        return <EndProductStepThree SelectedMatarial={SelectedMatarial} setSelectedMatrial={setSelectedMatrial}   selectedTab={selectedTab} setSelectedTab={setSelectedTab} setAddedmaterial={setAddedmaterial} addedMaterial={addedMaterial} handleNext={handleNext} />;
      case 2:
        return <EndProductStepFour categories={categories} setCategories={setCategories} handleNext={handleNext} />;
      case 3:
        return <UnifiedSupplierManufacturer   formData={formData} setFormData={setFormData} handleNext={handleNext} />;
      case 4:
        return <EndProductStepEith retailerData={retailerData} setRetailerData={setRetailerData} handleNext={handleNext}/>;
      case 5:
        return <EndProductStepSeven handleNext={handleNext} />; // Add this
        
        // case 6:
        //   return <EndProductStepSeven handleNext={handleNext} />;
          // case 7:
          //   return <UnifiedSupplierManufacturer handleNext={handleNext} />;
      default:
        return 'Unknown step';
    }
  };

  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
      marginLeft: 10,
      marginRight: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#22C55E',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#22C55E',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));



  return (
    <div className="w-full ">
      <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />} className="w-full">
        {steps.map((label, index) => (
          <Step key={label} className="relative">
            <StepLabel
              StepIconComponent={(props) => (
                
                <div
                onClick={()=>{handledirct(index)}}
                  className={`cursor-pointer flex items-center justify-center w-12 h-12 rounded-full text-white ${
                    props.active
                      ? 'bg-purple-600'
                      : props.completed
                      ? 'bg-green-500'
                      : 'bg-gray-400'
                  }`}
                >
                  {index + 1}
                </div>
              )}
            >
              {label}
            </StepLabel>
            {index < steps.length - 1 && (
              <div
                className={classNames(
                  'absolute top-6 left-1/2 transform -translate-x-1/2 h-5',
                  {
                    'bg-purple-600 border-w-5': activeStep > index,
                    'bg-gray-300 h-6 border-t-4': activeStep <= index,
                  }
                )}
                style={{
                  height: '5px',
                }}
              />
            )}
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Box sx={{ my: 2 }}>All steps completed - you&apos;re finished</Box>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Box sx={{ my: 2 }}>{getStepContent(activeStep)}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {/* <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button> */}
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button> */}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomStepper;
