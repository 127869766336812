import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, IconButton, Box } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import DataService from "../../../services/requestApi";
import TransportRouteModal from "./TransportRouteModal";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthConext";
import ModalforMaster from "../../Master/Cutomemodal/ModalforMaster";

const Alltransport = ({ tab }) => {
  const [selectedTransport, setSelectedTransport] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { getAllTransport, transport } = useAuth();

  // Fetch transport routes based on active tab
  useEffect(() => {
    if (tab === 'Transport Route') {
      getAllTransport();
    }
  }, [tab]);

  // Handle delete transport route
  const handleDelete = async (id) => {
    try {
      const response = await DataService.DeleteTransport(id);
      if (response.status === 200) {
        Swal.fire({
          title: "Deleted!",
          text: "Transport Route deleted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        getAllTransport();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Data table columns
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Transport Route",
      selector: (row) => row.routeName,
      sortable: true,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setSelectedTransport(row); // Set the selected transport for edit
              setModalOpen(true); // Open modal for editing
            }}
          >
            <Edit style={{ color: "green" }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
            <Delete style={{ color: "#C81D20" }} />
          </IconButton>
          <ModalforMaster tab={tab} name={row.routeName} id={row.id} saasid={row.saasId}/>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="px-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Transport Route</h1>
            <Button
            className='fw-bold' style={{background:"#a855f7"}}
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedTransport(null); // Clear previous selection for adding
                setModalOpen(true); // Open modal for adding new route
              }}
            >
              Create
            </Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <div style={{ height: "200px", overflow: "auto" }}>
            <DataTable
              columns={columns}
              data={transport}
              pagination
              responsive
              striped
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </div>
        </div>
      </div>
      <TransportRouteModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        fetchtransportlist={getAllTransport}
        selectedRoute={selectedTransport} // Pass the selected route for edit, null for add
      />
    </>
  );
};

export default Alltransport;
