import React, { useState } from 'react';
import { TextField, MenuItem, Avatar, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';
import { useAuth } from '../contexts/AuthConext';
import { BASEURL } from '../services/http-common';

const ProfileContainer = styled(Container)({
  background: 'linear-gradient(to right, #e0f7fa, #ffe0b2)',
  borderRadius: '8px',
  padding: '30px',
});

const Profile = () => {
  const { authData } = useAuth();
  const { name, email, mobilephone, lastName, gender, country, id } = authData || {};

  const [profileImage, setProfileImage] = useState(`${BASEURL.ENDPOINT_URL}auth/get-profile-image/${id}`);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      Swal.fire({
        icon: 'error',
        title: 'No file selected',
        text: 'Please choose a file to upload.',
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await DataService.addprofile(id, formData);
      if (response.data.status === true) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
        
        // Immediately update the profile image after successful upload
        setProfileImage(`${BASEURL.ENDPOINT_URL}auth/get-profile-image/${id}?${new Date().getTime()}`);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save image. Please try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'There was an error uploading the file. Please try again.',
      });
      console.error('Error uploading file:', error);
    }
  };

  const handleAvatarClick = () => {
    document.getElementById('file-input').click();
  };

  return (
    <>
      <div className='mt-2'>
        <div className="flex justify-center items-center">
          <div className='shadow-md w-full max-w-4xl'>
            <ProfileContainer>
              {/* Any additional content in the ProfileContainer */}
            </ProfileContainer>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className='bg-white p-5 shadow-md w-full max-w-4xl'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Avatar
                  alt="Profile Picture"
                  src={profileImage}
                  sx={{ width: 100, height: 100, cursor: 'pointer' }}
                  onClick={handleAvatarClick}
                />
                <input
                  id="file-input"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" component="div">
                  {name || 'User Name'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {email || 'user@example.com'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField 
                  fullWidth 
                  label="First Name" 
                  placeholder="Your First Name" 
                  variant="outlined" 
                  value={name || ''} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Last Name" placeholder="Your Last Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Gender"
                  select
                  variant="outlined"
                  value={gender || ''}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  fullWidth 
                  label="Country" 
                  placeholder="Country" 
                  variant="outlined" 
                  value={country || ''} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  fullWidth 
                  label="Email" 
                  placeholder="Email" 
                  variant="outlined" 
                  value={email || ''} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  fullWidth 
                  label="Mobile No." 
                  placeholder="Mobile No." 
                  variant="outlined" 
                  value={mobilephone || ''} 
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
