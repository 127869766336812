import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';
const Modaluseperiod = ({ isOpen, toggle, getAll, selectedRaw}) => {
    const [rawname, setRawname] = useState('');
    const [loading, setLoading] = useState(false);

    const {saasId
    } = useAuth(); 


    useEffect(() => {
        if (selectedRaw) {
            setRawname(selectedRaw.periodName);
        }else{
            setRawname('');
        }
    }, [selectedRaw]);
   
    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (selectedRaw) {
                await DataService.updateProductUserPeriod(selectedRaw.id, {periodName: rawname});
            } else {
                await DataService.addProductUserPeriod({saasId:saasId, periodName: rawname });
            }
            toggle();
            getAll();
            Swal.fire({
                icon:'success',
                title: 'Product Usage period Saved',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to save Product Usage period',
                showConfirmButton: false,
                timer: 1500
            });
        } finally {
            setLoading(false);
        }
    };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedRaw ? 'Edit Product Usage Period' : 'Add Product Usage Period'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Enter Product Usage Period"
          value={rawname}
          onChange={(e) => setRawname(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (selectedRaw ? 'Updating...' : 'Adding...') : selectedRaw ? 'Update Product Usage Period' : 'Add Product Usage Period'}
        </Button>
      </ModalFooter>
    </Modal>
    </>
  )
}

export default Modaluseperiod