import { Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { BsArrowLeft } from 'react-icons/bs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi'
const UpdateBrandModal = ({onHide,open,id, getBrand,selectedUom}) => {
    const [brandName, setBrandName] = React.useState('');
    const [selectedFile, setSelectedFile] = useState(null);


    useEffect(() => {
      if (selectedUom) {
        setBrandName(selectedUom);
      } else {
        setBrandName('');
      }
    }, [selectedUom]);


    const updateBrand=async ()=>{
        try{
            const data ={
                 "name": brandName
            }
            const response =  await DataService.updateBrand(id,data);
            const productId= response?.data?.data?.id
            await uploadProductImage(productId);
            Swal.fire('Brand Updated Successfully');
            await getBrand(); // To get updated data after update operation.
            onHide();
        }catch(error){
            Swal.fire('Error', error.message, 'error');
        }
    }


    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file); // Store the actual file, not the preview URL
      }
    };
    

    const uploadProductImage = async (productId) => {
      if (!selectedFile) return;
    
      try {
        const formData = new FormData();
        formData.append('file', selectedFile); // Append the actual file, not the preview URL
    
        // Make sure to send the request to the correct API endpoint with productId in the URL
        const imageResponse = await DataService.uploadBrandLogo(formData, productId);
    
        console.log('Image uploaded successfully:', imageResponse.data);
    
        Swal.fire({
          icon: 'success',
          title: 'Image Uploaded Successfully',
          text: 'The product image has been uploaded successfully!',
        });
    
      } catch (imageError) {
        console.error('Error uploading image:', imageError);
    
        Swal.fire({
          icon: 'error',
          title: 'Image Upload Failed',
          text: 'There was an error uploading the product image.',
        });
      }
    };
  return (
    <div>
     <Modal isOpen={open} toggle={onHide}>
      <ModalHeader toggle={onHide} className="fw-bold">Update Brand</ModalHeader>
      <ModalBody>
        <TextField
          label="Enter Brand Name"
          value={brandName}
          onChange={(e)=>{setBrandName(e.target.value)}}
          variant="outlined"
          fullWidth
        />

           <TextField
              required
              fullWidth
              variant="outlined"
              type="file"
              name="productImage"
              onChange={handleFileChange} 
              inputProps={{
                accept: "image/*", // Allow only image files
              }}
            />

      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={onHide}
        //   disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={updateBrand}
        //   disabled={loading}
        >
            Update Brand
          {/* {loading ? 'Adding...' : 'Add Category'} */}
        </Button>
      </ModalFooter>
    </Modal>
</div>
  )
}

export default UpdateBrandModal