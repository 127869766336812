import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/system';
import DataTable from 'react-data-table-component';
import DataService from '../../services/requestApi';
// import AddManufactureModal from '../Modal/AddManufactureModal';
// import UpdateManufacture from '../Modal/UpdateManufacture';
import Swal from 'sweetalert2';
import { useAuth } from '../../contexts/AuthConext';
import AddUnifiedSM from './AddUnifiedSM';
import UpdateUnifiedSM from './UpdateUnifiedSM';
import { Image } from 'antd';
import { BASEURL } from '../../services/http-common';
import ViewSuppleir from './ViewSuppleir';


const UnifiedSMList = () => {
  const {saasId
} = useAuth(); 
  const [manufacturers, setManufacturers] = useState([]);
  const [MenufactureModalOpen, setMenufactureModalOpen] = useState(false);
  const [UpdaetMoadal, setUpdaetMoadal] = useState(false);
  const [selected, setSelected] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const fetchManufacturers = async (search = '' ) => {
    try {
      let response;
      if (search) {
        response = await DataService.getSearchUnifiedSM(saasId,search);
      } else {
       response = await DataService.getUnifiedSM(saasId);
      }
      setManufacturers(response.data.data);
    } catch (error) {
      console.error('Failed to fetch manufacturers', error);
    }
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchManufacturers( event.target.value); // Search from page 1
  };
  useEffect(() => {
    fetchManufacturers();
  }, []);

  // Define columns for react-data-table-component
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await DataService.deleteUnifiedSMById(id);
        Swal.fire('Deleted!', 'Unified Supplier Manufacturer has been deleted.', 'success');
        fetchManufacturers(); // Refresh the list after deletion
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete Unified Supplier Manufacturer', 'error');
    }
  };




  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Logo',
      cell: (row) => (
        <div>
         <Image width={40} height={40} src={`${BASEURL.ENDPOINT_URL}supplier-manufacture/get-image-by-id/${row.id}`}/>
        </div>
      ),
    },
    {
      name: 'Address',
      selector: (row) =>
        `${row.address?.state},${row.address?.country}, `,
      sortable: true,
    },
    {
      name: 'Contact Info',
      selector: (row) => `${row.contactInfo?.phone}`,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => row.actions,
      center: true,
      cell: (row) => (
        <>
          <IconButton aria-label="edit" onClick={() => { setSelected(row); setUpdaetMoadal(true); }}>
            <Edit style={{ color: 'green' }} />
          </IconButton>
          <IconButton aria-label="delete"  onClick={() => handleDelete(row.id)}>
            <Delete style={{ color: '#C81D20' }} />
          </IconButton>
           <ViewSuppleir row={row}/>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create Supplier Directory </h1>
            <Button className='fw-bold' style={{background:"#a855f7"}} variant="contained" color="primary" onClick={() => setMenufactureModalOpen(true)}>Create</Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4 fw-bold"> Supplier  Directory</h2>
          <div className="flex mb-2">
            <TextField label="Search or Browse Supplier "
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined" fullWidth />
          </div>
          <div style={{ height: "300px", overflow: "auto" }}>
          <DataTable
            columns={columns}
            data={manufacturers}
            pagination
            responsive
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </div>
        </div>

      </div>
      <UpdateUnifiedSM fetchManufacturers={fetchManufacturers} open={UpdaetMoadal} onHide={() => setUpdaetMoadal(false)} row={selected} />
      <AddUnifiedSM fetchManufacturers={fetchManufacturers} open={MenufactureModalOpen} onHide={() => setMenufactureModalOpen(false)} />
    </>
  );
};

export default UnifiedSMList;
