import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { useAuth } from "../../contexts/AuthConext";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";

const UpdateRetailerModal = ({ open, onHide, row, fetchRetailers }) => {
  const {
    isdCodes,
    countries,
    fetchCountries,fetchStates,states 
  } = useAuth();
  const handlecountryOpen = () => {
    fetchCountries();
  }; 
  const [errorPrimary, setErrorPrimary] = useState(''); // Error state for primary number
  const [errorSecondary, setErrorSecondary] = useState(''); // Error state for secondary number
  const [selectedIsdCode, setSelectedIsdCode] = useState('+91');

  const initialState = {
    
    name: "",
    phone: "",
    phone2: "",
    email: "",
    email1: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      // district: "",
      pincode: ""
    },
    productList: null // Setting productList to null
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (formData?.address?.country) {
      fetchStates(formData?.address?.country);
    }
  }, [formData?.address?.country]);


  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    if (row) {
      setFormData({
        name: row.name || "",
        phone: row.contactInfo?.phone || "",
        phone2: row.contactInfo?.phone2 || "",
        email: row.contactInfo?.email || "",
        email1: row.contactInfo?.email1 || "",
        address: {
          flatNo: row.address?.flatNo || "",
          cityArea: row.address?.cityArea || "",
          country: row.address?.country || "",
          state: row.address?.state || "",
          // district: row.address?.district || "",
          pincode: row.address?.pincode || ""
        },
        productList: null // Ensuring productList is sent as null
      });
    }
  }, [row]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedFile(file); // Store the actual file, not the preview URL
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
     
    if (name === 'phone') {
      validatePhoneNumber(value, selectedIsdCode, 'primary');
    } else if (name === 'phone2') {
      validatePhoneNumber(value, selectedIsdCode, 'secondary');
    }

    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      console.log("")
      setFormData({
        ...formData,
        address: { ...formData.address, [addressField]: value }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "name", "phone", "email", 
      "address.flatNo", "address.cityArea", "address.country",
      "address.state",  "address.pincode"
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value.trim()) {
        return field;
      }
    }
    return null;
  };

      // Phone number validation logic
      const validatePhoneNumber = (phone, isdCode, field) => {
        const selectedCountry = isdCodes.find((isd) => isd.code === isdCode);
        let errorMessage = '';
      
        if (selectedCountry) {
          const minLength = selectedCountry.minphoneLength; // Minimum phone length
          const maxLength = selectedCountry.maxphoneLength; // Maximum phone length
      
          // Check phone length
          if (phone?.length < minLength || phone?.length > maxLength) {
            if (minLength === maxLength) {
              errorMessage = `Phone number must be ${maxLength} digits long.`;
            } else {
              errorMessage = `Phone number must be between ${minLength} and ${maxLength} digits long.`;
            }
          }
        }
      
        // Set error message only if there is one
        if (field === 'primary') {
          setErrorPrimary(errorMessage || ''); // Set error message for primary phone
        } else if (field === 'secondary') {
          setErrorSecondary(errorMessage || ''); // Set error message for secondary phone
        }
      
        return errorMessage; // Return the error message if any
      };
    
  const handleSubmit = async () => {

    const primaryErrorMessage = validatePhoneNumber(formData.phone, selectedIsdCode, 'primary');
    const secondaryErrorMessage = validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary');

    // Check if there are any validation errors
    // Check if there are any validation errors
if (primaryErrorMessage) {
  // Display validation error message for primary phone
  Swal.fire({
    title: 'Validation Error!',
    text: primaryErrorMessage,
    icon: 'error',
    confirmButtonText: 'OK'
  });
  return; // Stop execution if validation fails
}

if (secondaryErrorMessage) {
  // Display validation error message for secondary phone
  Swal.fire({
    title: 'Validation Error!',
    text: secondaryErrorMessage,
    icon: 'error',
    confirmButtonText: 'OK'
  });
  return; // Stop execution if validation fails
}


    const missingField = validateForm();

    if (missingField) {
      Swal.fire({
        title: "Missing Information",
        text: `Please fill in the ${missingField.replace("address.", "")} field.`,
        icon: "warning",
      });
      return;
    }

    const dataToSend = {
      name: formData.name,
      contactInfo: {
        phone: formData.phone,
        phone2: formData.phone2,
        email: formData.email,
        email1: formData.email1,
      },
      address: {
        flatNo: formData.address.flatNo,
        cityArea: formData.address.cityArea,
        country: formData.address.country,
        state: formData.address.state,
        // district: formData.address.district,
        pincode: formData.address.pincode,
      },
      productList: null
    };

    try {
      await DataService.updateRetailer(row.retailerId, dataToSend);
      await handleImageUpload(row.retailerId)
      fetchRetailers()
      Swal.fire({
        title: "Retailer Updated",
        text: "Retailer details have been successfully updated.",
        icon: "success",
      }).then(() => {
        onHide();
      });
    } catch (error) {
     // Check if the API response contains a message field
     const errorMessage = error?.response?.data?.message || "Failed to update retailer details. Please try again later.";

     Swal.fire({
       title: "Error",
       text: errorMessage, // Displaying the error message from the API
       icon: "error",
     });
    }
  };
  

   //handle uploading image by retailer id
  const handleImageUpload = async (retailerId) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await DataService.uploadRetailerLogo(
          formData,
          retailerId
        );
        if (response.data.status) {
          console.log("Image uploaded successfully");
        } else {
          console.error("Error uploading image:", response.data.message);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      console.error("No image selected");
    }
  };


    // Handle ISD Code change
const handleIsdCodeChange = (event) => {
  const newIsdCode = event.target.value;
  setSelectedIsdCode(newIsdCode);
  validatePhoneNumber(formData.phone, newIsdCode, 'primary'); // Validate primary phone when ISD code changes
};

  return (
    <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
      <ModalHeader toggle={onHide}>
        <div className="d-flex align-items-center gap-4">
          <BsArrowLeft onClick={onHide} className="mouse-pointer" />
          <p className="fw-bold">Update Retailer</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <Card>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                         type="number"
                        fullWidth
                         
                        onBlur={() => validatePhoneNumber(formData.phone, selectedIsdCode, 'primary')} // Validate on blur
                        error={!!errorPrimary} // Apply error style if error exists for primary
                        helperText={errorPrimary} // Show error message for primary
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                value={selectedIsdCode}
                                onChange={handleIsdCodeChange}
                                displayEmpty
                                variant="standard"
                                style={{ marginRight: '8px', fontSize: '14px' }}
                              >
                                {isdCodes.map((isd) => (
                                  <MenuItem key={isd.code} value={isd.code}>
                                    {isd.country}
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                  
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                  type="number"
                    label="Mobile No. (Secondary)"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleInputChange}
                    onBlur={() => validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary')} // Validate on blur
                    error={!!errorSecondary} // Apply error style if error exists for secondary
                    helperText={errorSecondary} // S
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email 2"
                    name="email1"
                    value={formData.email1}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address.flatNo"
                    value={formData.address.flatNo}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City Area"
                    name="address.cityArea"
                    value={formData.address.cityArea}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    required
                    labelId="country-select-label"
                    id="country-select"
                    label="Country"
                        value={formData.address?.country?.toLowerCase()}
                        onChange={handleInputChange}
                        name="address.country"
                    className=" border border-gray-300 rounded-sm w-full"
                    onOpen={handlecountryOpen}
                  >
                    {countries &&
                      countries.map((el) => (
                        <MenuItem key={el.country_id} value={el.country_name.toLowerCase()}>
                          {el.country_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                  
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    label="State"
                    name="address.state"
                    value={formData.address.state}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  /> */}
                          <FormControl fullWidth>
  <InputLabel>State</InputLabel>
  <Select
    label="State"
    name="address.state"
    value={formData.address.state}
    onChange={handleInputChange}
  >
    {states.length > 0 ? (
      states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>No states available</MenuItem>
    )}
  </Select>
</FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="District"
                    name="address.district"
                    value={formData.address.district}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="ZipCode"
                    name="address.pincode"
                    value={formData.address.pincode}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                         label="Logo"
                        variant="outlined"
                        type="file"
                        name="productImage"
                        onChange={handleFileChange}
                        inputProps={{
                          accept: "image/*", // Allow only image files
                        }}
                        InputLabelProps={{
                          shrink: true, // Keeps the label open
                        }}
                      />
                    </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Container>
        <div className="flex justify-center space-x-8 col-span-full mt-2">
          <Button
            style={{
              borderRadius: '20px',
              padding: "10px",
              width: "200px"
            }}
            variant="outlined"
            color="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            style={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '12',
              background: '#2D059C',
              border: 'none',
              borderRadius: '20px',
              '&:hover': { background: '#2D059C' },
              background: "#4F46E5",
              padding: "10px",
              width: "200px"
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateRetailerModal;
