import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';

const CreateCo2modal = ({ isOpen, toggle, fetchgetUomList, selectedUom }) => {
  const [uomName, setUomName] = useState('');
  const [loading, setLoading] = useState(false);
  const {saasId
  } = useAuth(); 
  useEffect(() => {
    if (selectedUom) {
      setUomName(selectedUom.name);
    } else {
      setUomName('');
    }
  }, [selectedUom]);

  const handleUomSave = async () => {
    setLoading(true);
    try {
      if (selectedUom) {
        // Update existing UOM
        const response = await DataService.updatCo2(selectedUom.id, { name: uomName });
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Co2 updated successfully!',
          });
          
        }
      } else {
        // Add new UOM
        const response = await DataService.addCo2({saasId:saasId, name: uomName });
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Co2 added successfully!',
          });
        } else {
          // Show error message in Swal if status is false
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message || 'An error occurred',
          });
        }
      }
      fetchgetUomList();
      toggle();
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to save MSDS. Please try again later.';
    
    // Show error message in Swal
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
    });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedUom ? 'Edit Co2' : 'Add Co2'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Co2 Name"
          value={uomName}
          onChange={(e) => setUomName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleUomSave}
          disabled={loading}
        >
          {loading ? (selectedUom ? 'Updating...' : 'Adding...') : selectedUom ? 'Update Co2' : 'Add Co2'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateCo2modal;
