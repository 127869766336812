import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  InputAdornment,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DataService from "../services/requestApi";
import { useAuth } from "../contexts/AuthConext";
import Swal from "sweetalert2";
import ChangePasswordModal from "../components/Modal/ChangePasswordModal";
import {
  Email,
  Lock,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [error, setError] = useState("");
  const { login, saveUserData } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = async (data) => {
    try {
      const response = await DataService.Login(data);
      const redirectUrl = sessionStorage.getItem("redirectAfterLogin");

      if (response.data.status) {
        const token = response.data.data.token;
        const userData = {
          id: response.data.data.id,
          saasId: response.data.data.saasId,
          name: response.data.data.name,
          mobilephone: response.data.data.mobilephone,
          email: response.data.data.email,
          // token: response.data.data.token,
        };
        if (token) {
          login(token);
          saveUserData(userData);
          navigate("/");
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: "User not found. Email or Password incorrect",
            confirmButtonColor: "#2D059C",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: response.data.message || "Invalid login credentials",
          confirmButtonColor: "#2D059C",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: "User not found. Phone or Password incorrect",
        confirmButtonColor: "#2D059C",
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <>
      <Box className="flex flex-col md:flex-row h-screen bg-background">
        <Grid container>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              backgroundImage: "url(/Loginimg.png)", // Update with your image path
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Grid>
          <Grid
            className="p-5 mt-5 flex justify-center h-[550px]"
            item
            xs={12}
            md={5}
          >
            <Box className="max-w-md w-full bg-card p-8 rounded-lg  ">
              <div className="flex justify-center mb-1">
                <Typography variant="h4" className="font-bold text-black">
                  Log in
                </Typography>
              </div>
              <div className="flex justify-center mb-2">
                <Typography
                  component="label"
                  variant="body1"
                  className="block text-sm font-medium text-zinc-700"
                  htmlFor="full-name"
                >
                  To access your account
                </Typography>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
                {/* <Select
                  className="p-0"
                  // value={admin}
                  // onChange={(e) => setAdmin(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                  startAdornment={
                    // <div className='bg-blue-700'>
                    <InputAdornment position="start">
                      <Box
                        className="bg-green-them"
                        sx={{
                          // Change to your desired color
                          // borderRadius: '50%',     // Adjust as needed for rounded effect
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 55, // Set width for box
                          height: 55, // Set height for box
                        }}
                      >
                        <Person sx={{ color: "primary.contrastText" }} />
                      </Box>
                    </InputAdornment>
                    // </div>
                  }
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select> */}
                <TextField
                  {...register("email", { required: true })}
                  error={!!errors.email}
                  helperText={errors.email ? "Email is required" : ""}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email"
                  // label="Email"
                  name="email"
                  autoComplete="email"
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          className="bg-green-them"
                          sx={{
                            // Change to your desired color
                            // borderRadius: '50%',     // Adjust as needed for rounded effect
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 55, // Set width for box
                            height: 55, // Set height for box
                          }}
                        >
                          <Email className="text-white" />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  {...register("password", { required: true })}
                  error={!!errors.password}
                  helperText={errors.password ? "Password is required" : ""}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  // label="Password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          className="bg-green-them"
                          sx={{
                            // Change to your desired color
                            // borderRadius: '50%',     // Adjust as needed for rounded effect
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 55, // Set width for box
                            height: 55, // Set height for box
                          }}
                        >
                          <Lock className="text-white" />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />

                <p
                  onClick={toggleModal}
                  className="text-green-them cursor-pointer text-sm mt-2 block text-right hover:underline"
                >
                  I forget my password
                </p>
                <div className="flex justify-center mt-3">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mb: 2,
                      bgcolor: "#048A52",
                      "&:hover": { bgcolor: "#29d990" },
                      // borderRadius: '24px',
                      textTransform: "uppercase",
                      py: 1.5,
                    }}
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
              </form>
              {/* <Typography variant="body2" className="mt-4 text-muted-foreground text-center">
              Don’t have an account? 
              <a href="/register" className="text-accent hover:underline">Create an account</a>
            </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ChangePasswordModal isOpen={isModalOpen} toggle={toggleModal} />
    </>
  );
};

export default Login;
