import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import DataService from '../../../services/requestApi';
import Swal from 'sweetalert2';
import { useAuth } from '../../../contexts/AuthConext';

const TransportRouteModal = ({ isOpen, toggle, fetchtransportlist, selectedRoute }) => {
  const [transportName, setTransportName] = useState('');
  const [loading, setLoading] = useState(false);
  const {saasId
  } = useAuth(); 
  // Set transport name when editing a selected route, otherwise clear it for new routes
  useEffect(() => {
    if (selectedRoute) {
      setTransportName(selectedRoute.routeName); // Pre-fill transport name for edit
    } else {
      setTransportName(''); // Clear form for adding a new route
    }
  }, [selectedRoute]);

  // Clear form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setTransportName(''); // Reset the form when modal is closed
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (selectedRoute) {
        // Update existing transport route
        await DataService.updateTransportRoute(selectedRoute.id, { routeName: transportName });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Transport Route updated successfully!',
        });
      } else {
        // Add new transport route
        await DataService.addTransportRoute({saasId:saasId, routeName: transportName });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Transport Route added successfully!',
        });
      }
      fetchtransportlist(); // Refresh transport list
      toggle(); // Close modal
    } catch (error) {
      console.error('Error saving transport route:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedRoute ? 'Edit Transport Route' : 'Add Transport Route'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Enter Transport Route Name"
          value={transportName}
          onChange={(e) => setTransportName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (selectedRoute ? 'Updating...' : 'Adding...') : selectedRoute ? 'Update Transport Route' : 'Add Transport Route'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TransportRouteModal;
