import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';

const Transmodal = ({ isOpen, toggle, fetchgetUomList, selectedUom }) => {
  const [uomName, setUomName] = useState('');
  const [loading, setLoading] = useState(false);
  const {saasId
  } = useAuth(); 
  useEffect(() => {
    if (selectedUom) {
      setUomName(selectedUom.methodName);
    } else {
      setUomName('');
    }
  }, [selectedUom]);

  const handleUomSave = async () => {
    setLoading(true);
    try {
      if (selectedUom) {
        // Update existing UOM
        const response = await DataService.updatetransportmethod(selectedUom.id, { methodName: uomName });
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Transport method updated successfully!',
          });
        }
      } else {
        // Add new UOM
        const response = await DataService.addtransportmethod({saasId:saasId, methodName: uomName });
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Transport method added successfully!',
          });
        }
      }
      fetchgetUomList();
      toggle();
    } catch (error) {
      console.error('Failed to save UOM:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedUom ? 'Edit Transport Method' : 'Add Transport Method'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Transport Method Name"
          value={uomName}
          onChange={(e) => setUomName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleUomSave}
          disabled={loading}
        >
          {loading ? (selectedUom ? 'Updating...' : 'Adding...') : selectedUom ? 'Update Transport Method' : 'Add Transport Method'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Transmodal;
