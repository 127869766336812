import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';

const ModalH2o = ({ isOpen, toggle, fetchgeth2o, selectedH2o }) => {
    const [H2oname, setH2oname] = useState('');
    const [loading, setLoading] = useState(false);
    const {saasId
    } = useAuth(); 
    useEffect(() => {
        if (selectedH2o) {
            setH2oname(selectedH2o.name);
        }else {
            setH2oname('');
        }  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedH2o]);

    const handleSubmit = async () => {
    try {
        setLoading(true);
        if (selectedH2o) {
            await DataService.updateH2OImpact(selectedH2o.id, { name: H2oname });
        } else {
            await DataService.addH2OImpact({saasId:saasId, name: H2oname });
        }
        fetchgeth2o();
        toggle();
        setH2oname('');
        Swal.fire('Success', selectedH2o? 'H2o Impact updated successfully' : 'H2o Impact added successfully','success');
        
    } catch (error) {
      const errorMessage = error.response?.data?.message || ' Please try again later.';
    
      // Show error message in Swal
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
    }

    
  return (
    <>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedH2o ? 'Edit H2o Impact' : 'Add H2o Impact'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Enter H₂O Impact"
          value={H2oname}
          onChange={(e) => setH2oname(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleSubmit}
          // disabled={loading}
        >
          {selectedH2o ? 'Update H2o Impact' : 'Add H2o Impact'}
        </Button>
      </ModalFooter>
    </Modal>
    </>
  )
}

export default ModalH2o