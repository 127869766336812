import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import 'tailwindcss/tailwind.css';
import { Pie } from 'react-chartjs-2';

// Register the required elements and components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

// Updated data for outer and inner charts
const outerData = {
  labels: ['Biogenics 44%', 'Wood 56%'],
  datasets: [
    {
      label: 'Biogenics & Wood',
      data: [44, 56],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)', // Biogenics
        'rgba(255, 99, 132, 0.6)', // Wood
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)', // Biogenics
        'rgba(255, 99, 132, 1)', // Wood
      ],
      borderWidth: 1,
    },
  ],
};

const innerData = {
  labels: ['Metals 80%', 'Ferrum (Recycled) 20%'],
  datasets: [
    {
      label: 'Metals & Ferrum',
      data: [80, 20],
      backgroundColor: [
        'rgba(255, 206, 86, 0.6)', // Metals
        'rgba(54, 162, 235, 0.6)', // Ferrum (Recycled)
      ],
      borderColor: [
        'rgba(255, 206, 86, 1)', // Metals
        'rgba(54, 162, 235, 1)', // Ferrum (Recycled)
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          const label = tooltipItem.label || '';
          const value = tooltipItem.raw;
          return `${label}: ${value}%`;
        },
      },
    },
  },
};

function Chart() {
  return (
    <div className="relative w-full h-64">
      {/* Outer Pie Chart */}
      <Pie data={outerData} options={options} />
      {/* Inner Pie Chart inside the outer one */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-1/2 h-1/2">
          <Pie data={innerData} options={options} />
        </div>
      </div>
    </div>
  );
}

export default Chart;
