import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { CloudUpload } from "@mui/icons-material";
import { IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem, Dialog } from "@mui/material";
import { useAuth } from "../../contexts/AuthConext";

const UpdateUnifiedSM = ({ open, onHide, row, fetchManufacturers, }) => {
  const { contactInfo, address } = row
  const { saasId, isdCodes,
    fetchExpertiseList, expertise, supplierType, fetchsupplierTypeList,
    countries, fetchCountries,  states,fetchStates
  } = useAuth();
  const handlecountryOpen = () => {
    fetchCountries();
  };
  const handleDropdownOpen = () => {
    fetchExpertiseList();
  };
  const handlesupplierTypeOpen = () => {
    fetchsupplierTypeList();
  };
  const [errorPrimary, setErrorPrimary] = useState(''); // Error state for primary number
  const [errorSecondary, setErrorSecondary] = useState(''); // Error state for secondary number
  const [selectedIsdCode, setSelectedIsdCode] = useState('+91');
  const [selectedFile, setSelectedFile] = useState(null);
  const initialState = {
    name: row.name,
    certifications: [],
    phone: contactInfo?.phone || "",
    phone2: contactInfo?.phone2 || "",
    email: contactInfo?.email || "",
    email2: contactInfo?.email1 || "",
    certificationFiles: {}, // Handle File objects
    expertise: row.expertise,
    type:"",
    manufactureCost: "",
    supplierPrice: "",
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (formData?.address?.country) {
      fetchStates(formData?.address?.country);
    }
  }, [formData?.address?.country]);


  useEffect(() => {
    if (row) {
      setFormData({
        name: row.name,
        certifications: [],
        phone: contactInfo?.phone,
        phone2: contactInfo?.phone2,
        email: contactInfo?.email,
        email2: contactInfo?.email1,
        address: address && address,
        certificationFiles: {},
        expertise: row.expertise,
        type: row.type,
        manufactureCost: row.manufactureCost,
        supplierPrice: row.supplierPrice,

      });
    }
  }, [row])

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      validatePhoneNumber(value, selectedIsdCode, 'primary');
    } else if (name === 'phone2') {
      validatePhoneNumber(value, selectedIsdCode, 'secondary');
    }


    if (name === "certifications") {
      setFormData({
        ...formData,
        certifications: value,
        certificationFiles: value.reduce((acc, cert) => {
          acc[cert] = formData.certificationFiles[cert] || ''; // Initialize files if not present
          return acc;
        }, {})
      });
    } else if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      console.log("addressField: ", addressField, value);
      setFormData({
        ...formData,
        address: { ...formData.address, [addressField]: value }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleFileChange = (event, cert) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      certificationFiles: {
        ...formData.certificationFiles,
        [cert]: file // Store the File object
      }
    });
  };
  const OnFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedFile(file); // Store the actual file, not the preview URL
    }
  };
  const handleSubmit = async () => {
    const primaryErrorMessage = validatePhoneNumber(formData.phone, selectedIsdCode, 'primary');
    const secondaryErrorMessage = validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary');

    // Check if there are any validation errors
    // Check if there are any validation errors
    if (primaryErrorMessage) {
      // Display validation error message for primary phone
      Swal.fire({
        title: 'Validation Error!',
        text: primaryErrorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; // Stop execution if validation fails
    }

    if (secondaryErrorMessage) {
      // Display validation error message for secondary phone
      Swal.fire({
        title: 'Validation Error!',
        text: secondaryErrorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; // Stop execution if validation fails
    }



    const dataToSend = {
      saasId: saasId,
      name: formData?.name,
      certifications: formData?.certifications, // Already storing certifications as an array
      expertise: formData?.expertise,
      type: formData?.type,
      manufactureCost: formData?.manufactureCost,
      supplierPrice: formData?.supplierPrice,
      contactInfo: {
        phone: formData?.phone,
        phone2: formData?.phone2,
        email: formData?.email,
        email1: formData?.email2, // Ensure alternate email is stored as email1 in the payload
      },
      address: {
        flatNo: formData?.address?.flatNo,
        pincode: formData?.address?.pincode,
        cityArea: formData?.address?.cityArea,
        state: formData?.address?.state,
        country: formData?.address?.country,
      }


    };

    try {
      const response = await DataService.updateUnifiedSM(row.id, dataToSend);

      //   const manufacturerId = response.data.data.id; // Assume the API returns this
      if (response.data.status) {
        // Upload certification images if any
        // console.log(formData.certificationFiles?.length > 0, formData.certificationFiles);
        if (Object.keys(formData.certificationFiles).length > 0) {

          const uploadResponse = await fileUploadResponse(row.id, formData.certificationFiles);

          if (uploadResponse) {
            Swal.fire({
              title: " Supplier Updated Successfully",
              icon: "success",
              timer: 2000
            });
            
            await fetchManufacturers()
            onHide(); // Close the modal
          }
        } else {
          Swal.fire({
            title: "Supplier Updated Successfully",
            icon: "success",
            timer: 2000
          });
          await UploadSupplierimage(row.id)
          await fetchManufacturers()
          onHide(); // Close the modal
        }
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.response.data.message;
      Swal.fire({
        title: "Error Creating Manufacturer",
        icon: "error",
        text: errorMessage
      });
    }
  };
//Supplier Logo Upload
const UploadSupplierimage = async (Id)=>{
  try {
    if(selectedFile){
      //append the file to the form data
      const formData = new FormData();
      formData.append('file', selectedFile);
      const response = await DataService.uploadSupplierLogo(formData, Id);
      
      if (response.status === 200) {
        Swal.fire({
          title: "Supplier Logo Uploaded Successfully",
          icon: "success",
          timer: 2000
        });
      } else {
        throw new Error(response.data.message || "Supplier logo upload failed");
      }
    }
    
  } catch (error) {
    Swal.fire({
      title: "Error Uploading Supplier Logo",
      icon: "error",
      text: error.message,
    });
    console.log(error)
  }
}

  const fileUploadResponse = async (manufacturerId, certificationFiles) => {
    const formData = new FormData();

    Object.keys(certificationFiles).forEach((cert, index) => {
      const file = certificationFiles[cert];
      if (file) {
        formData.append(`certificate${index + 1}`, file);
      }
    });

    try {
      const response = await DataService.uploadUnifiedSM(formData, manufacturerId);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      Swal.fire({
        title: "Error Uploading Files",
        icon: "error",
        text: error.message,
      });
      return null;
    }
  };

  // Phone number validation logic
  const validatePhoneNumber = (phone, isdCode, field) => {
    const selectedCountry = isdCodes.find((isd) => isd.code === isdCode);
    let errorMessage = '';

    if (selectedCountry) {
      const minLength = selectedCountry.minphoneLength; // Minimum phone length
      const maxLength = selectedCountry.maxphoneLength; // Maximum phone length

      // Check phone length
      if (phone?.length < minLength || phone?.length > maxLength) {
        if (minLength === maxLength) {
          errorMessage = `Phone number must be ${maxLength} digits long.`;
        } else {
          errorMessage = `Phone number must be between ${minLength} and ${maxLength} digits long.`;
        }
      }
    }

    // Set error message only if there is one
    if (field === 'primary') {
      setErrorPrimary(errorMessage || ''); // Set error message for primary phone
    } else if (field === 'secondary') {
      setErrorSecondary(errorMessage || ''); // Set error message for secondary phone
    }

    return errorMessage; // Return the error message if any
  };

  // Handle ISD Code change
  const handleIsdCodeChange = (event) => {
    const newIsdCode = event.target.value;
    setSelectedIsdCode(newIsdCode);
    validatePhoneNumber(formData.phone, newIsdCode, 'primary'); // Validate primary phone when ISD code changes
  };

  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog visibility  
  const [currentImageSrc, setCurrentImageSrc] = useState(''); // State to hold the current image source  

  const handleImageClick = (src) => {
    setCurrentImageSrc(src); // Set the current image source  
    setOpenDialog(true); // Open the dialog  
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog  
    setCurrentImageSrc(''); // Clear the current image source  
  };
  return (
    <>
      <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold">Update Supplier</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardBody>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Email (Primary)"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Email (Secondary)"
                        value={formData.email2}
                        onChange={handleInputChange}
                        name="email2"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Certifications</InputLabel>
                        <Select
                          label="Certifications"
                          value={formData.certifications}
                          onChange={handleInputChange}
                          name="certifications"
                          multiple
                        >
                          <MenuItem value="certifications1">Certifications 1</MenuItem>
                          <MenuItem value="certifications2">Certifications 2</MenuItem>
                          <MenuItem value="certifications3">Certifications 3</MenuItem>
                          <MenuItem value="certifications4">Certifications 4</MenuItem>
                          <MenuItem value="certifications5">Certifications 5</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Dynamic File Upload Inputs */}
                    {/* Dynamic File Upload Inputs */}
                    {formData.certifications.map((cert) => {
                      const existingCertificateUrl = row[`certificate${cert.charAt(cert.length - 1)}`];

                      return (
                        <Grid item xs={12} sm={4} key={cert}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <TextField
                              label={`Upload ${cert}`}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                readOnly: true,
                                onClick: () => document.getElementById(cert).click(), // Trigger upload dialog  
                              }}
                              className="mb-2"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton component="label">
                                      <CloudUpload />
                                      <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        id={cert}
                                        onChange={(e) => handleFileChange(e, cert)}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="end">
                                    {existingCertificateUrl &&
                                      <img
                                        className="cursor-pointer"
                                        src={existingCertificateUrl}
                                        onClick={() => handleImageClick(existingCertificateUrl)}
                                        alt={`Certificate ${cert.charAt(cert.length - 1)}`}
                                        style={{
                                          width: '40px',
                                          height: '40px',
                                          objectFit: 'cover',
                                          borderRadius: '4px',
                                          marginRight: '8px',
                                        }}
                                      />
                                    }
                                  </InputAdornment>
                                ),


                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </Grid>
                      );
                    })}


                    <Grid item xs={12} sm={4}>

                      <TextField
                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                        type="number"
                        fullWidth
                        onBlur={() => validatePhoneNumber(formData.phone, selectedIsdCode, 'primary')} // Validate on blur
                        error={!!errorPrimary} // Apply error style if error exists for primary
                        helperText={errorPrimary} // Show error message for primary
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                value={selectedIsdCode}
                                onChange={handleIsdCodeChange}
                                displayEmpty
                                variant="standard"
                                style={{ marginRight: '8px', fontSize: '14px' }}
                              >
                                {isdCodes.map((isd) => (
                                  <MenuItem key={isd.code} value={isd.code}>
                                    {isd.country}
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Mobile No. (Secondary)"
                        value={formData.phone2}
                        onChange={handleInputChange}
                        name="phone2"
                        fullWidth
                        type="number"
                        onBlur={() => validatePhoneNumber(formData.phone2, selectedIsdCode, 'secondary')} // Validate on blur
                        error={!!errorSecondary} // Apply error style if error exists for secondary
                        helperText={errorSecondary} // Show error message for secondary
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>expertise</InputLabel>
                        <Select
                          required
                          label="expertise"
                          value={formData.expertise}
                          onChange={handleInputChange}
                          name="expertise"
                          onOpen={handleDropdownOpen}
                          fullWidth
                        >
                          {expertise.map((expertise) => (
                            <MenuItem key={expertise.id} value={expertise.expertiseName}>
                              {expertise.expertiseName}
                            </MenuItem>
                          ))}


                        </Select>
                      </FormControl>

                    </Grid>




                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Address"
                        value={formData.address?.flatNo}
                        onChange={handleInputChange}
                        name="address.flatNo"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="City Area"
                        value={formData.address?.cityArea}
                        onChange={handleInputChange}
                        name="address.cityArea"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    required
                    labelId="country-select-label"
                    id="country-select"
                    label="Country"
                    value={formData?.address?.country}
                    onChange={handleInputChange}
                    name="address.country"
                    className=" border border-gray-300 rounded-sm w-full"
                    onOpen={handlecountryOpen}
                  >
                    {countries &&
                      countries.map((el) => (
                        <MenuItem key={el.country_id} value={el.country_name}>
                          {el.country_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <TextField
                        label="State"
                        value={formData.address?.state}
                        onChange={handleInputChange}
                        name="address.state"
                        fullWidth
                      /> */}
                                      <FormControl fullWidth>
  <InputLabel>State</InputLabel>
  <Select
   label="State"
   value={formData.address?.state}
   onChange={handleInputChange}
   name="address.state"
  >
    {states.length > 0 ? (
      states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>No states available</MenuItem>
    )}
  </Select>
</FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="ZipCode"
                        value={formData.address?.pincode}
                        onChange={handleInputChange}
                        name="address.pincode"
                        fullWidth
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          required
                          label="Type"
                          value={formData.type}
                          onChange={handleInputChange}
                          onOpen={handlesupplierTypeOpen}
                          name="type"
                        >
                          {supplierType.map((supplier) => (
                            <MenuItem key={supplier.id} value={supplier.typeName}>
                              {supplier.typeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        label="Manufacturing Cost"
                        value={formData.manufactureCost}
                        onChange={handleInputChange}
                        name="manufactureCost"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        label="Supplier Pricing"
                        value={formData.supplierPrice}
                        onChange={handleInputChange}
                        name="supplierPrice"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        label="Logo"
                        variant="outlined"
                        type="file"
                        name="productImage"
                        onChange={OnFileChange}
                        inputProps={{
                          accept: "image/*", // Allow only image files
                        }}
                        InputLabelProps={{
                          shrink: true, // Keeps the label open
                        }}
                      />
                    </Grid> 
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </Container>
          <div className="flex justify-center space-x-8 col-span-full mt-2">
            <Button
              style={{
                borderRadius: '20px',
                padding: "10px",
                width: "200px"
              }}
              variant="outlined"
              color="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} style={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '12',
              background: '#2D059C',
              border: 'none',
              borderRadius: '20px',
              '&:hover': { background: '#2D059C' },
              background: "#4F46E5",
              padding: "10px",
              width: "200px"
            }}
              variant="contained"
              color="primary">
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* Dialog for Previewing Image */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md" // Customize as necessary  
        fullWidth
      >
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <img
            src={currentImageSrc}
            alt="Certificate Preview"
            style={{ width: '100%', height: 'auto' }} // Responsive image  
          />
        </div>
      </Dialog>
    </>
  )
}

export default UpdateUnifiedSM