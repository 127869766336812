import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsArrowLeft } from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';

const UpdateSupplierModal = ({ open, onHide, fetchSuppliers, row }) => {
    const { contactInfo, address } = row;

    const initialState = {
        firstName: row?.name?.split(' ')[0] || '',
        phone: contactInfo?.phone || '',
        phone2: contactInfo?.phone2 || '',
        email: contactInfo?.email || '',
        email1: contactInfo?.email1 || '',
        flatNo: address?.flatNo || '',
        cityArea: address?.cityArea || '',
        district: address?.district || '',
        state: address?.state || '',
        country: address?.country || '',
        pincode: address?.pincode || '',
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData(initialState); // Update the state when the row data changes
    }, [row]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const requiredFields = [
            "firstName",  "phone", "email",
            "flatNo", "cityArea", "district", "state",
            "country", "pincode"
        ];

        for (let field of requiredFields) {
            if (!formData[field] || formData[field].trim() === "") {
                return field;
            }
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form
        const missingField = validateForm();
        if (missingField) {
            Swal.fire({
                icon: "warning",
                title: 'Incomplete Form',
                text: `Please fill in the ${missingField} field.`,
            });
            return;
        }

        const payload = {
          // Assuming `row.id` contains the supplier ID
            name: formData.firstName,
            address: {
                flatNo: formData.flatNo,
                cityArea: formData.cityArea,
                country: formData.country,
                state: formData.state,
                district: formData.district,
                pincode: formData.pincode
            },
            sustainabilityPractices: row.sustainabilityPractices || 'Sustainable Practices', // Assuming this field may be static
            contactInfo: {
                phone: formData.phone,
                phone2: formData.phone2,
                email: formData.email,
                email1: formData.email1
            }
        };

        try {
            const response = await DataService.updateSupplier(row.supplierId, payload);
            if (response.status === 200) {
                Swal.fire('Success', 'Supplier updated successfully!', 'success');
                fetchSuppliers(1); // Refresh the supplier list
                onHide(); // Close the modal
            } else {
                Swal.fire('Error', 'Failed to update supplier', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to update supplier', 'error');
        }
    };

    return (
        <div>
            <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
                <ModalHeader toggle={onHide}>
                    <div className="d-flex align-items-center gap-4">
                        <BsArrowLeft onClick={onHide} className="mouse-pointer" />
                        <h2 className="text-center text-xl font-semibold">Update Supplier's Information</h2>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="flex justify-center items-center">
                        <div className="bg-white p-4 rounded-md shadow-md w-full max-w-4xl">
                            <form className="grid grid-cols-1 md:grid-cols-2 gap-4" onSubmit={handleSubmit}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                              
                                <TextField
                                    label="Mobile No. (Primary)"
                                    variant="outlined"
                                    fullWidth
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                     type="number"
                                />
                                <TextField
                                    label="Mobile No. (Secondary)"
                                    variant="outlined"
                                    fullWidth
                                    name="phone2"
                                    value={formData.phone2}
                                    onChange={handleInputChange}
                                     type="number"
                                />
                                <TextField
                                    label="Email (Primary)"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="Email (Secondary)"
                                    variant="outlined"
                                    fullWidth
                                    name="email1"
                                    value={formData.email1}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    name="flatNo"
                                    value={formData.flatNo}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="Area, Street, Sector, Village"
                                    variant="outlined"
                                    fullWidth
                                    name="cityArea"
                                    value={formData.cityArea}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="District"
                                    variant="outlined"
                                    fullWidth
                                    name="district"
                                    value={formData.district}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="Country"
                                    variant="outlined"
                                    fullWidth
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    label="Pincode"
                                    variant="outlined"
                                    fullWidth
                                    name="pincode"
                                    value={formData.pincode}
                                    onChange={handleInputChange}
                                     type="number"
                                />
                                <div className="flex justify-center space-x-8 col-span-full mt-2">
                                    <Button
                                        style={{
                                            borderRadius: '20px',
                                            padding: "10px",
                                            width: "200px"
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={onHide}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        style={{
                                            textTransform: 'none',
                                            color: '#fff',
                                            fontSize: '12',
                                            background: '#2D059C',
                                            border: 'none',
                                            borderRadius: '20px',
                                            '&:hover': { background: '#2D059C' },
                                            background: "#4F46E5",
                                            padding: "10px",
                                            width: "200px"
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UpdateSupplierModal;
