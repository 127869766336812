import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import Swal from "sweetalert2"; // Import SweetAlert2
import DataService from "../../services/requestApi";
import { useAuth } from "../../contexts/AuthConext";
import DataTable from "react-data-table-component";
import { Delete, Edit } from "@mui/icons-material";
import { BsArrowLeft } from "react-icons/bs";
import ModalforMaster from "../Master/Cutomemodal/ModalforMaster";

const AddCategoryModal = ({
  isOpen,
  toggle,
  fetchCategoryList,
  categoryList,
}) => {
  const [newCategory, setNewCategory] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updatedCategoryName, setUpdatedCategoryName] = useState("");
  const { saasId } = useAuth();
  const handleCategoryInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  useEffect(() => {
    console.log("first", categoryList);
    // fetchCategoryList()
  }, []);

  const handleCategorySave = async () => {
    try {
      const response = await DataService.addCategory({
        saasId: saasId,
        categoryName: newCategory,
      });

      if (response.data.status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Category added successfully!",
        });
        fetchCategoryList();
        setNewCategory("");
        // toggle();
      }
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  const handleEditClick = (category) => {
    setSelectedCategory(category);
    setUpdatedCategoryName(category.categoryName);
    setEditModalOpen(true);
  };

  const handleUpdateCategory = async () => {
    try {
      const data = {
        saasId: saasId,
        categoryName: updatedCategoryName,
      };

      const response = await DataService.updateCategory(
        selectedCategory.id,
        data
      );

      if (response.data.status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Category updated successfully!",
        });
        fetchCategoryList();
        setEditModalOpen(false);
        setSelectedCategory(null);
      }
    } catch (error) {
      console.error("Failed to update category:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        await DataService.deletecategory(id);
        fetchCategoryList();
      }
    } catch (error) {
      console.error("Failed to delete category", error);
    }
  };

  const columns = [
    {
      name: "Category Id",
      center: true,
      cell: (row) => row.id ?? "N/A",
      sortable: true,
    },

    {
      name: "Category Name",
      center: true,
      cell: (row) => row.categoryName ?? "N/A",
      sortable: true,
    },

    {
      name: "Actions",
      selector: (row) => row.actions,
      center: true,
      cell: (row) => (
        <div className="flex">
          <IconButton aria-label="edit" onClick={() => handleEditClick(row)}>
            <Edit style={{ color: "green" }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
            <Delete style={{ color: "#C81D20" }} />
          </IconButton>
          <ModalforMaster tab='Category' name={row.categoryName} saasid={row.saasId} id={row.id} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        size="xl"
        className="modal-fullscreen"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle} className="fw-bold">
          Category
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Card
              style={{
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      label="Category Name"
                      value={newCategory}
                      onChange={handleCategoryInputChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      className="mb-4 montserrat-text-normal fw-bold"
                      style={{
                        background: "#a855f7",
                        width: 225,
                        height: 50,
                      }}
                      variant="contained"
                      color="primary"
                      // style={{
                      //   textTransform: 'none',
                      //   color: '#fff',

                      //   background: '#a855f7',
                      //   // borderRadius: '20px',
                      //   transition: 'background 0.3s ease',
                      //   width: 225,
                      //   height: 50,
                      // }}
                      onClick={handleCategorySave}
                      //  className='fw-bold'
                    >
                      Create Category
                    </Button>
                  </Grid>
                </Grid>{" "}
              </CardBody>
            </Card>
            <Card
              className="mt-3"
              style={{
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              <CardBody>
                <div
                  style={{
                    height: "400px",
                    overflow: "auto",
                    marginTop: "20px",
                  }}
                >
                  <DataTable
                    columns={columns}
                    data={categoryList}
                    pagination
                    paginationServer
                    customStyles={{
                      rows: { style: { minHeight: "72px" } },
                      headCells: {
                        style: { paddingLeft: "8px", paddingRight: "8px" },
                      },
                      cells: {
                        style: { paddingLeft: "8px", paddingRight: "8px" },
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(false)}>
        <ModalHeader toggle={() => setEditModalOpen(false)}>
          Update Category
        </ModalHeader>
        <ModalBody>
          <TextField
            label="Category Name"
            value={updatedCategoryName}
            onChange={(e) => setUpdatedCategoryName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setEditModalOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleUpdateCategory}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
