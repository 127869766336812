import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function TransportInfo({ transportDetails, warehouseDetails }) {
  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
          mb: 3
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="text.primary" mb={2}>
          Transport Details
        </Typography>

        {/* Transport Method */}
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Transport Method:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {transportDetails?.transportMethod?.join(', ') || 'N/A'}
          </Typography>
        </Box>

        {/* Transport Route */}
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Transport Route:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {transportDetails?.transportRout?.join(', ') || 'N/A'}
          </Typography>
        </Box>

        {/* Transport Route Start and End */}
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Transport Route Start:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(transportDetails?.transportRoutStart).toLocaleString() || 'N/A'}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Transport Route End:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(transportDetails?.transportRoutEnd).toLocaleString() || 'N/A'}
          </Typography>
        </Box>
      </Paper>

      {/* <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="text.primary" mb={2}>
          Warehouse Details
        </Typography>

        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Warehouse Name:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {warehouseDetails?.warehouseName || 'N/A'}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Warehouse License No:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {warehouseDetails?.warehouseLicensNo || 'N/A'}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Warehouse Manager:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {warehouseDetails?.warehouseManager || 'N/A'}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="body2" fontWeight="bold" color="text.primary" mb={1}>
            Warehouse GST No:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {warehouseDetails?.warehouseGstNo || 'N/A'}
          </Typography>
        </Box>
      </Paper> */}
    </Box>
  );
}

export default TransportInfo;
