import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import DataService from "../../services/requestApi";
import { useAuth } from "../../contexts/AuthConext";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";

const AddRetailModal = ({ open, onHide, fetchRetailers }) => {
  const { saasId, isdCodes, fetchIsdCodes, countries, fetchCountries,fetchStates,states } =
    useAuth();

 

    
  const handlecountryOpen = () => {
    fetchCountries();
  };

  useEffect(() => {
    fetchIsdCodes();
  }, []);

  const [errorPrimary, setErrorPrimary] = useState(""); // Error state for primary number
  const [errorSecondary, setErrorSecondary] = useState(""); // Error state for secondary number
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedIsdCode, setSelectedIsdCode] = useState("+91");

  const initialState = {
    name: "",
    // certifications: "",
    phone: "",
    phone2: null,
    email: "",
    email1: null,
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      // district: "",
      pincode: "",
    },
    productList: null, // Setting productList to null
  };

  const [formData, setFormData] = useState(initialState);



  useEffect(() => {
    if (formData?.address?.country) {
      fetchStates(formData?.address?.country);
    }
  }, [formData?.address?.country]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      validatePhoneNumber(value, selectedIsdCode, "primary");
    } else if (name === "phone2") {
      validatePhoneNumber(value, selectedIsdCode, "secondary");
    }

    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData({
        ...formData,
        address: { ...formData.address, [addressField]: value },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value === "" ? null : value, // Set to null if the value is an empty string
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedFile(file); // Store the actual file, not the preview URL
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "name",
      "phone",
      "email",
      "address.flatNo",
      "address.cityArea",
      "address.country",
      "address.state",
      "address.pincode",
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value.trim()) {
        return field;
      }
    }
    return null;
  };

  // Phone number validation logic
  const validatePhoneNumber = (phone, isdCode, field) => {
    const selectedCountry = isdCodes.find((isd) => isd.code === isdCode);
    let errorMessage = "";

    if (selectedCountry) {
      const minLength = selectedCountry.minphoneLength; // Minimum phone length
      const maxLength = selectedCountry.maxphoneLength; // Maximum phone length

      // Check phone length
      if (phone?.length < minLength || phone?.length > maxLength) {
        if (minLength === maxLength) {
          errorMessage = `Phone number must be ${maxLength} digits long.`;
        } else {
          errorMessage = `Phone number must be between ${minLength} and ${maxLength} digits long.`;
        }
      }
    }

    // Set error message only if there is one
    if (field === "primary") {
      setErrorPrimary(errorMessage || ""); // Set error message for primary phone
    } else if (field === "secondary") {
      setErrorSecondary(errorMessage || ""); // Set error message for secondary phone
    }

    return errorMessage; // Return the error message if any
  };

  const handleSubmit = async () => {
    const primaryErrorMessage = validatePhoneNumber(
      formData.phone,
      selectedIsdCode,
      "primary"
    );
    const secondaryErrorMessage = validatePhoneNumber(
      formData.phone2,
      selectedIsdCode,
      "secondary"
    );

    // Check if there are any validation errors
    // Check if there are any validation errors
    if (primaryErrorMessage) {
      // Display validation error message for primary phone
      Swal.fire({
        title: "Validation Error!",
        text: primaryErrorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      return; // Stop execution if validation fails
    }

    if (secondaryErrorMessage) {
      // Display validation error message for secondary phone
      Swal.fire({
        title: "Validation Error!",
        text: secondaryErrorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      return; // Stop execution if validation fails
    }
    const missingField = validateForm();

    if (missingField) {
      Swal.fire({
        title: "Missing Information",
        text: `Please fill in the ${missingField.replace(
          "address.",
          ""
        )} field.`,
        icon: "warning",
      });
      return;
    }

    const dataToSend = {
      saasId: saasId,
      name: formData.name,
      // certifications: formData.certifications.split(","),
      contactInfo: {
        phone: formData.phone,
        phone2: formData.phone2,
        email: formData.email,
        email1: formData.email1,
      },
      address: {
        flatNo: formData.address.flatNo,
        cityArea: formData.address.cityArea,
        country: formData.address.country,
        state: formData.address.state,
        // district: formData.address.district,
        pincode: formData.address.pincode,
      },
      productList: null, // Ensuring productList is sent as null
    };

    try {
      const response = await DataService.addRetailer(dataToSend);
      if (response.data.status) {
        Swal.fire({
          title: "Retailer Created Successfully",
          icon: "success",
          timer: 2000,
        });
        //here call api for this retailer to upload image by retailer id
        handleImageUpload(response.data.data.retailerId);
        fetchRetailers();
        onHide(); // Close the modal
      } else {
        // Show error if status is false
        Swal.fire({
          title: "Error Creating Retailer",
          text: response.data.message || "An unknown error occurred",
          icon: "error",
        });
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          title: "Error Creating Retailer",
          text: error.response.data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error Creating Retailer",
          icon: "error",
          text: error.message || "An unknown error occurred",
        });
      }
    }
  };

  //handle uploading image by retailer id
  const handleImageUpload = async (retailerId) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await DataService.uploadRetailerLogo(
          formData,
          retailerId
        );
        if (response.data.status) {
          console.log("Image uploaded successfully");
        } else {
          console.error("Error uploading image:", response.data.message);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      console.error("No image selected");
    }
  };

  // Handle ISD Code change
  const handleIsdCodeChange = (event) => {
    const newIsdCode = event.target.value;
    setSelectedIsdCode(newIsdCode);
    validatePhoneNumber(formData.phone, newIsdCode, "primary"); // Validate primary phone when ISD code changes
  };


  return (
    <div>
      <Modal
        isOpen={open}
        toggle={onHide}
        size="xl"
        className="modal-fullscreen"
      >
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold">Create Retailer</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardBody>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        label="Certifications"
                        value={formData.certifications}
                        onChange={handleInputChange}
                        name="certifications"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                        type="number"
                        fullWidth
                        onBlur={() =>
                          validatePhoneNumber(
                            formData.phone,
                            selectedIsdCode,
                            "primary"
                          )
                        } // Validate on blur
                        error={!!errorPrimary} // Apply error style if error exists for primary
                        helperText={errorPrimary} // Show error message for primary
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                value={selectedIsdCode}
                                onChange={handleIsdCodeChange}
                                displayEmpty
                                variant="standard"
                                style={{ marginRight: "8px", fontSize: "14px" }}
                              >
                                {isdCodes.map((isd) => (
                                  <MenuItem key={isd.code} value={isd.code}>
                                    {isd.country}
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Mobile No. (Secondary)"
                        value={formData.phone2}
                        onChange={handleInputChange}
                        name="phone2"
                        type="number"
                        fullWidth
                        onBlur={() =>
                          validatePhoneNumber(
                            formData.phone2,
                            selectedIsdCode,
                            "secondary"
                          )
                        } // Validate on blur
                        error={!!errorSecondary} // Apply error style if error exists for secondary
                        helperText={errorSecondary} // S
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email (Primary)"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email (Secondary)"
                        value={formData.email1}
                        onChange={handleInputChange}
                        name="email1"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Address"
                        value={formData.address.flatNo}
                        onChange={handleInputChange}
                        name="address.flatNo"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City Area"
                        value={formData.address.cityArea}
                        onChange={handleInputChange}
                        name="address.cityArea"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* <TextField
                        label="Country"
                        value={formData.address.country}
                        onChange={handleInputChange}
                        name="address.country"
                        fullWidth
                      /> */}
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          required
                          labelId="country-select-label"
                          id="country-select"
                          label="Country"
                          value={formData.address.country}
                          onChange={handleInputChange}
                          name="address.country"
                          className=" border border-gray-300 rounded-sm w-full"
                          onOpen={handlecountryOpen}
                        >
                          {countries &&
                            countries.map((el) => (
                              <MenuItem
                                key={el.country_id}
                                value={el.country_name}
                              >
                                {el.country_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
  <InputLabel>State</InputLabel>
  <Select
    label="State"
    value={formData.address.state || ""}
    onChange={handleInputChange}
    name="address.state"
  >
    {states.length > 0 ? (
      states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>No states available</MenuItem>
    )}
  </Select>
</FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        label="District"
                        value={formData.address.district}
                        onChange={handleInputChange}
                        name="address.district"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="ZipCode"
                        value={formData.address.pincode}
                        onChange={handleInputChange}
                        name="address.pincode"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                         label="Logo"
                        fullWidth
                        variant="outlined"
                        type="file"
                        name="productImage"
                        onChange={handleFileChange}
                        inputProps={{
                          accept: "image/*", // Allow only image files
                        }}
                        InputLabelProps={{
                          shrink: true, // Keeps the label open
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>
            <div className="flex justify-center space-x-8 col-span-full mt-2">
              <Button
                style={{
                  borderRadius: "20px",
                  padding: "10px",
                  width: "200px",
                }}
                variant="outlined"
                color="secondary"
                onClick={onHide}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                type="submit"
                style={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "12",
                  background: "#2D059C",
                  border: "none",
                  borderRadius: "20px",
                  "&:hover": { background: "#2D059C" },
                  background: "#4F46E5",
                  padding: "10px",
                  width: "200px",
                }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddRetailModal;
