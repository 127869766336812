import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { CloudUpload } from "@mui/icons-material";
import { IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAuth } from "../../contexts/AuthConext";

const AddManufactureModal = ({ open, onHide ,fetchManufacturers}) => {
  const {saasId
  } = useAuth(); 
  const initialState = {
    name: "",
    certifications: [], // Ensure it's initialized as an empty array
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: ""
    },
    certificationFiles: {}
  };
  

  const [formData, setFormData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Handle address fields
    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address, // Ensure that existing address fields are not overwritten
          [addressField]: value
        }
      }));
    } 
    // Handle other fields
    else if (name === "certifications") {
      const certifications = Array.isArray(value) ? value : [value];
      setFormData((prevState) => ({
        ...prevState,
        certifications,
        certificationFiles: certifications.reduce((acc, cert) => {
          acc[cert] = prevState.certificationFiles[cert] || ''; // Initialize files if not present
          return acc;
        }, {})
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  

  const handleFileChange = (event, cert) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      certificationFiles: {
        ...formData.certificationFiles,
        [cert]: file // Store the File object
      }
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "name", "certifications", "phone", "email", "email2",
      "address.flatNo", "address.cityArea", "address.country",
      "address.state", "address.district", "address.pincode"
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return field;
      }
    }
    return null;
  };

  const handleSubmit = async () => {
    const missingField = validateForm();
  
    if (missingField) {
      Swal.fire({
        title: "Missing Information",
        text: `Please fill in the ${missingField.replace("address.", "")} field.`,
        icon: "warning",
      });
      return;
    }
  
    const dataToSend = {
      saasId:saasId,
      name: formData.name,
      certifications: formData.certifications,
      phone: formData.phone,
      phone2: formData.phone2,
      email: formData.email,
      email2: formData.email2,
      address: {
        flatNo: formData.address.flatNo,
        cityArea: formData.address.cityArea,
        country: formData.address.country,
        state: formData.address.state,
        district: formData.address.district,
        pincode: formData.address.pincode
      }
    };
  
    try {
      const response = await DataService.addMenufacture(dataToSend);
  
      const manufacturerId = response.data.data.id; // Assume the API returns this
      if (response.data.status) {
        const uploadResponse = await fileUploadResponse(manufacturerId, formData.certificationFiles);
  
        if (uploadResponse) {
          Swal.fire({
            title: "Manufacturer Created Successfully",
            icon: "success",
            timer: 2000
          });
          fetchManufacturers()
          onHide(); // Close the modal

          setFormData(initialState);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire({
          title: "Error Creating Manufacturer",
          text: error.response.data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error Creating Manufacturer",
          icon: "error",
          text: error.message || "An unknown error occurred",
        });
      }
    }
  };

  const fileUploadResponse = async (manufacturerId, certificationFiles) => {
    const formData = new FormData();
    
    Object.keys(certificationFiles).forEach((cert, index) => {
      const file = certificationFiles[cert];
      if (file) {
        formData.append(`certificate${index + 1}`, file);
      }
    });

    try {
      const response = await DataService.uploadmanufacturecerImage(formData, manufacturerId);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      Swal.fire({
        title: "Error Uploading Files",
        icon: "error",
        text: error.message,
      });
      return null;
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold">Create Manufacturer</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardBody>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Email (Primary)"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Email (Secondary)"
                        value={formData.email2}
                        onChange={handleInputChange}
                        name="email2"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Certifications</InputLabel>
                        <Select
                          label="Certifications"
                          value={formData.certifications}
                          onChange={handleInputChange}
                          name="certifications"
                          multiple
                        >
                          <MenuItem value="certifications1">Certifications 1</MenuItem>
                          <MenuItem value="certifications2">Certifications 2</MenuItem>
                          <MenuItem value="certifications3">Certifications 3</MenuItem>
                          <MenuItem value="certifications4">Certifications 4</MenuItem>
                          <MenuItem value="certifications5">Certifications 5</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Dynamic File Upload Inputs */}
                    {Array.isArray(formData.certifications) && formData.certifications.map((cert) => (
  <Grid item xs={12} sm={4} key={cert}>
    <TextField
      label={`Upload ${cert}`}
      variant="outlined"
      fullWidth
      value={formData.certificationFiles[cert]?.name || ''}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              component="label"
              style={{ background: "white", borderRadius: '4px' }}
            >
              <CloudUpload />
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, cert)}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      className="mb-2"
    />
  </Grid>
))}


                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                         type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Mobile No. (Secondary)"
                        value={formData.phone2}
                        onChange={handleInputChange}
                        name="phone2"
                         type="number"
                        fullWidth
                      />
                    </Grid>

                
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Address"
                        value={formData.address.flatNo}
                        onChange={handleInputChange}
                        name="address.flatNo"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="City Area"
                        value={formData.address.cityArea}
                        onChange={handleInputChange}
                        name="address.cityArea"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Country"
                        value={formData.address.country}
                        onChange={handleInputChange}
                        name="address.country"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="State"
                        value={formData.address.state}
                        onChange={handleInputChange}
                        name="address.state"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="District"
                        value={formData.address.district}
                        onChange={handleInputChange}
                        name="address.district"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Pincode"
                        value={formData.address.pincode}
                        onChange={handleInputChange}
                        name="address.pincode"
                         type="number"
                        fullWidth
                      />
                    </Grid>             
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </Container>
          <div className="flex justify-center space-x-8 col-span-full mt-2">
        <Button
                    style={{
                      borderRadius: '20px',
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
          <Button onClick={handleSubmit}   style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#2D059C',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#2D059C' },
                      background: "#4F46E5",
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="contained"
                    color="primary">
            Submit
          </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddManufactureModal;
