import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';
const Rawmaterialmodal = ({ isOpen, toggle, getAll, selectedRaw}) => {
    const [Rawname, setRawname] = useState('');
    const [loading, setLoading] = useState(false);
    const {saasId
    } = useAuth(); 
    useEffect(() => {
        if (selectedRaw) {
            setRawname(selectedRaw.name);
        }else{
            setRawname('');
        }
    }, [selectedRaw]);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (selectedRaw) {

                await DataService.updateRawMaterialImpact(selectedRaw.id, {name: Rawname});
                Swal.fire('Raw Material Impact Updated Successfully!', '', 'success');
            } else {
                await DataService.addRawMaterialImpact({saasId:saasId, name: Rawname });
                Swal.fire('Raw Material Impact Added Successfully!', '', 'success');
            }
            toggle();
            getAll();
            setLoading(false);
            setRawname('');
        } catch (error) {
          const errorMessage = error.response?.data?.message || ' Please try again later.';
    
          // Show error message in Swal
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
          });
        }
    }
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedRaw ? 'Edit Raw Material Impact' : 'Add Raw Material Impact'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Enter Raw Material Impact"
          value={Rawname}
          onChange={(e) => setRawname(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (selectedRaw ? 'Updating...' : 'Adding...') : selectedRaw ? 'Update Raw Material Impact' : 'Add Raw Material Impact'}
        </Button>
      </ModalFooter>
    </Modal>
    </>
  )
}

export default Rawmaterialmodal