import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, TextField, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Product from '../Product';
import ManufacturerDetails from './ManufacturerDetails';
import SupplierEntity from './SupplierEntity';
import DataService from '../../services/requestApi';
import SupplierChainEntity from './SupplierChainEntity';
import RetailEntity from './RetailEntity';
import { useAuth } from '../../contexts/AuthConext';
import UnifiedSupplierManufactureView from '../../Unified/UnifiedSupplierManufactureView';
import ImpactCard from './ImpactCard';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Pdf/PdfDocument';

const ProductTabs = () => {
  const {saasId
  } = useAuth(); 

  const location = useLocation();
  const { productId } = location.state || {};
  const [value, setValue] = useState(0);
  const [productData, setProductData] = useState({});
  const [Progresbar, setProgresbar] = useState({});
 const [manufacturerData, setManufacturerData] = useState({});
  const [supplierData, setSupplierData] = useState({});
  const [retailerData, setRetailerData] = useState({});
  const [unifiedSupplierManufacturerData, setUnifiedSupplierManufacturerData] = useState([]);
  const [impactEntity, setImpactEntity] = useState({});
  const [metrial, setmetrial] = useState([]);

  const [supplyChainEntity, setSupplyChainEntity] = useState({
    transportDetails: {},
    warehouseDetails: {},
    address: {},
    contactInfo: {},
  });




  const fetchSupplierChainData = async () => {
    try {
      const response = await DataService.getSupplierChainByProductId(productId);
      const data = response.data.data;
      
      // Extract necessary data
      const transportDetails = data.transportDetails;
      const warehouseDetails = data.warehouseDetails;
      const address = data.address;
      const contactInfo = data.contactInfo;
      setProgresbar(data?.progressBar);
      
      // Save to supplyChainEntity state
      setSupplyChainEntity({ 
        transportDetails, 
        warehouseDetails, 
        address, 
        contactInfo 
      });

      // Set product data
      setProductData({
        id:data.id,
        productId: data.product.productId,
        name: data.product.name,
        description: data.product.description,
        category: data.product.category,
        sku: data.product.sku,
        brand: data.product.brand,
        manufacturingDate: data.product.manufacturingDate,
        expirationDate: data.product.expirationDate,
        code: data.product.code,
        serialNumber: data.product.serialNumber,
        hsCode: data.product.hsCode,
        eanNumber: data.product.eanNumber,
        image: data.product.image,
        basicInfo: data.product.basicInfo,
        // materials: data.product.materials[0],
        progressBar: data.progressBar
      });
      setmetrial(data.product.materials)
      // Set retailer data correctly
      const retailer = data.retailer?.[0]; // Use optional chaining to avoid errors
      if (retailer) {
        setRetailerData({
          retailerId: retailer.retailerId,
          name: retailer.name,
          logo:retailer.logo,
          address: retailer.address,
          contactInfo: retailer.contactInfo,
        });
      }

      // Function to format date to DD MM YYYY
// Function to format date to "DD Month YYYY"
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  
  const day = date.getDate().toString().padStart(2, '0'); // Format day with leading zero if needed
  const month = date.toLocaleString("default", { month: "long" }); // Full month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
      // Set UnifiedSupplierManufacturer data
      const unifiedSupplierManufacturer = data?.unifiedProductSupplierManufacturer; // Optional chaining for safety
      if (unifiedSupplierManufacturer) {
        setUnifiedSupplierManufacturerData({
          supplierManufactureId: unifiedSupplierManufacturer.supplierManufactureId,
          saasId: unifiedSupplierManufacturer.saasId,
          name: unifiedSupplierManufacturer.name,
          number:unifiedSupplierManufacturer.number,

          manufactureDate: formatDate(unifiedSupplierManufacturer.manufactureDate),
          supplierDate: formatDate(unifiedSupplierManufacturer.supplierDate),
          supplierLocation: unifiedSupplierManufacturer.supplierLocation,
          paymentTerms: unifiedSupplierManufacturer.paymentTerms,
          deliveryTerms: unifiedSupplierManufacturer.deliveryTerms,
          warranty: unifiedSupplierManufacturer.warranty,
          manufactureCost: unifiedSupplierManufacturer.manufactureCost,
          supplierPrice: unifiedSupplierManufacturer.supplierPrice,
        });
      }


        // Set impactEntity data
    const impactEntityData = data?.impactEntity;
    if (impactEntityData) {
      setImpactEntity({
        id:impactEntityData.id,
        carbonFootprint: impactEntityData.carbonFootprint,
        waterUsage: impactEntityData.waterUsage,
        recyclability: impactEntityData.recyclability,
        sustainableMaterials: impactEntityData.sustainableMaterials,
        energyEfficiency: impactEntityData.energyEfficiency,
        ethicalProduction: impactEntityData.ethicalProduction,
        chemicalSafety: impactEntityData.chemicalSafety,
        transportationImpact: impactEntityData.transportationImpact,
        certifications: impactEntityData.certifications,
        durability: impactEntityData.durability
      });
    }

    } catch (error) {
      console.error('Failed to fetch supplier chain data', error);
    }
  };

  useEffect(() => {
  fetchSupplierChainData();
  }, [productId]);
  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Box className="px-5 mt-2" sx={{ width: '100%', overflow: 'hidden' }}>
    <Card className="p-2">

      <Tabs value={value} onChange={handleChange} aria-label="product tabs">
        <Tab label="Product Information" />
        {/* <Tab label="Manufacturer Entity" />
        <Tab label="Supplier Entity" /> */}
             <Tab label="Impact Information" /> 
        <Tab label="Logistics and distribution" />
        {/* <Tab label="Lifecycle Event Entity" /> */}
        <Tab label="Retail Information" />
        <Tab label="Supplier Information" />

      </Tabs>



{/* <Button variant="contained" color="secondary" style={{ margin: '10px', background: "red" }}>
          <PDFDownloadLink  
            document={  
              <PdfDocument  
              productData={productData}  
              impactEntity={impactEntity}  
              supplyChainEntity={supplyChainEntity}  
              retailerData={retailerData}  
              unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}  
          />
            }  
            fileName="product-data.pdf"  
          >  
            {({ blob, url, loading, error }) => (loading ? 'Preparing document...' : 'Download PDF')}  
          </PDFDownloadLink>  
        </Button>   */}
    </Card>
    <TabPanel value={value} index={0}>
      <Product metrial={metrial}  fetchSupplierChainData={fetchSupplierChainData} productData={productData} impactEntity={impactEntity} 
         supplyChainEntity={supplyChainEntity}  
         retailerData={retailerData}  
         unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}  />
    </TabPanel>

<TabPanel value={value} index={1}>
      <ImpactCard fetchSupplierChainData={fetchSupplierChainData} impactEntity={impactEntity} productData={productData} 
          supplyChainEntity={supplyChainEntity}  
          retailerData={retailerData}  
          unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}/>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <SupplierChainEntity fetchSupplierChainData={fetchSupplierChainData} impactEntity={impactEntity} productData={productData} 
          supplyChainEntity={supplyChainEntity}  
          retailerData={retailerData}  
          unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}  />
    </TabPanel>
    <TabPanel value={value} index={3}>
      <RetailEntity fetchSupplierChainData={fetchSupplierChainData}  impactEntity={impactEntity} productData={productData} 
          supplyChainEntity={supplyChainEntity}  
          retailerData={retailerData}  
          unifiedSupplierManufacturerData={unifiedSupplierManufacturerData} />
    </TabPanel>
    {/* Add other TabPanels for remaining tabs */}
    <TabPanel value={value} index={4}>
      <UnifiedSupplierManufactureView fetchSupplierChainData={fetchSupplierChainData} impactEntity={impactEntity} productData={productData} 
          supplyChainEntity={supplyChainEntity}  
          retailerData={retailerData}  
          unifiedSupplierManufacturerData={unifiedSupplierManufacturerData} />
    </TabPanel>
  </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ overflow: 'hidden' }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default ProductTabs;
