import http from "./http-common";

class DataService {
  //Api For Port 8089 / 8088
  Login(data) {
    return http.post("auth/login", data);
  }
  Signup(data) {
    return http.post(`auth/register`, data);
  }
  getById(id, data) {
    return http.get(`manufacturer/getby-id/${id}`,data);
  }
  addSupplier(data) {
    return http.post(`supplier/add-supplier`, data);
  }
  addRetailer(data) {
    return http.post(`retailer/add-retailer`, data);
  }
  addMenufacture(data) {
    return http.post(`manufacturer/create`, data);
  }

  createUnifiedSM(data) {
    return http.post(`supplier-manufacture/add-supplier-manufacture`, data);
  }
  uploadUnifiedSM(data, UnifiedSMID) {
    return http.post(`supplier-manufacture/save-supplier-manufacture-image/${UnifiedSMID}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
      },
    });
  }

  getSearchUnifiedSM(saasId ,name,data) {
    return http.get(`supplier-manufacture/search-by-name/${saasId}/${name}`,data);
  }


  deleteUnifiedSMById(ID) {
    return http.delete(`supplier-manufacture/delete-by-id/${ID}`);
  }

  updateUnifiedSM(ID , data) {
    return http.put(`/supplier-manufacture/update-supplier-manufacture/${ID}`, data);
  }
  

  getUnifiedSM(saasId) {
    return http.get(`supplier-manufacture/get-Allsupplier-manufacture/${saasId}`);
  }

  getManufacturers(saasId) {
    return http.get(`manufacturer/get-list/${saasId}`);
  }
  getproductNameList(saasId,page,data) {
    return http.get(`supplierchain/name-list/${saasId}/${page}`, data);
  }
  getSupplierChainByProductId(productId,data) {
    return http.get(`supplierchain/getby-productId/${productId}`, data);
  }

  getSearchMenufacture(saasId ,name,data) {
    return http.get(`manufacturer/search-by-name/${saasId}/${name}`,data);
  }
  getSearchSupplier( saasId,name,data) {
    return http.get(`supplier/search-by-name/${saasId}/${name}`,data);
  }
  getSearchRetailer(saasId,name,data) {
    return http.get(`retailer/search-by-name/${saasId}/${name}`,data);
  }

  searchproductByName(saasId,name,data) {
    return http.get(`product/search/by-name/${saasId}/${name}`,data);
  }
  searchProductsByCategory(saasId,name,data) {
    return http.get(`product/search/by-category/${saasId}/${name}`,data);
  }

  addProductbasiInfo(data) {
    return http.post(`product/add-product`, data);
  }
  UnifiedSupplierManufacturer(data) {
    return http.post(`supplier-manufacture/link-product-supplier-manufacture-image`,data);
  }
  
  linkManufactureProduct(manufactureid,selectedProductId) {
    return http.post(`product-manufacture/add-product-manufacture/${selectedProductId}/${manufactureid}`);
  }
  
  updatemanufacture(ID , data) {
    return http.put(`/manufacturer/update/${ID}`, data);
  }
  updateProduct(ID , data) {
    return http.put(`/product/update-product/${ID}`, data);
  }

  updateSupplier(ID,data) {
    return http.put(`supplier/update-supplier/${ID}`, data);
  }
  updateRetailer(ID,data) {
    return http.put(`retailer/update-retailer/${ID}`, data);
  }
  deleteRetailerById(ID) {
    return http.delete(`retailer/delete-retailer-by-id/${ID}`);
  }
  
  searchRetailerByName(saasId,name,data) {
    return http.get(`retailer/search-by-name/${saasId}/${name}`,data);
  }
  searchSuppliersByName(name,data) {
    return http.get(`supplier/search-by-name/${name}`,data);
  }

  deleteManufacturerById(ID) {
    return http.delete(`manufacturer/deleteby-id/${ID}`);
  }
  deleteSupplierById(ID) {
    return http.delete(`supplier/delete-supplier-by-id/${ID}`);
  }

  linkSupplierProduct(supplierId,ProductId) {
    return http.post(`product-supplier/add-product-supplier/${ProductId}/${supplierId}`);
  }
  linkRetailProduct(retailerId,selectedProductId) {
    return http.post(`retailer-product/${retailerId}/${selectedProductId}`);
  }
  SupplyChainEntity(data) {
    return http.post(`supplierchain/create`,data);
  }





  getBrandList(saasId) {
    return http.get(`/brand/get-list/${saasId}`);
  }
  updateBrand(id, data) { 
    return http.put(`/brand/update/${id}`, data);
  }
  
  deleteBrand(id) {
    return http.delete(`/brand/deleteby-id/${id}`);
  }
  createBrand(data) {
    return http.post('/brand/create', data);
  }
  GetTransportRoute(saasId) {
    return http.get(`/transport-route/get-all-transport-routes/${saasId}`);
  }
  updateTransportRoute(id, data) {
    return http.put(`/transport-route/update-transport-route/${id}`, data);
  }
  addTransportRoute(data){
    return http.post(`/transport-route/add-transport-route`, data);
  }
  DeleteTransport(id) {
    return http.delete(`/transport-route/delete-transport-route/${id}`);
  }




  getUomList(saasId) {
    return http.get(`/uom/get-list/${saasId}`);
  }

  getproductList() {
    return http.get('product/get-all-product');
  }

  //material api call's 
  GetTypeeofMaterial(saasId){
    return http.get(`material/types/${saasId}`)
  }
  GetbyMaterial(saasId,el){
    return http.get(`material/names/${saasId}/${el}`)
  }
  AddMaterialDetail(id,data){
    return http.put(`product/add-mList/${id}`,data)
  }



  getSuplierList(saasId,page,data) {
    return http.get(`supplier/get-supplier/${saasId}/${page}`, data);
  }
  getRetailerList(saasId,data) {
    return http.get(`retailer/get-retailer-list/${saasId}`, data);
  }

  uploadProductImage(data, productId) {
    return http.post(`product/save-product-image/${productId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
      },
    });
  }

  uploadmanufacturecerImage(data, manufacturerId) {
    return http.post(`manufacturer/save-manufacture-image/${manufacturerId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
      },
    });
  }

  uploadImapcat(data,id) {
    return http.put(`impact/add-file/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
      },
    });
  }

  createImpcat(saasId,productId,data) {
    return http.post(`impact/create/${saasId}/${productId}`, data);
  }
  addprofile(userId,data) {
    return http.post(`auth/save-profile-image/${userId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
      },
    });
  }
  changePassword(data) {
    return http.put('auth/change-password', data);
  }
  addCategory(data) {
    return http.post('product/add-category', data);
  }
 

  updateCategory(id,data){
    return http.put(`product/update-category/${id}`,data)
  }


  deletecategory(id) {
    return http.delete(`product/delete-category/${id}`);
  }
  getCategoryList(saasId,data) {
    return http.get(`product/get-category-list/${saasId}`, data);
  }

// uom api call

  addUom(data) {
    return http.post('uom/create', data);
  }
  deleteUom(id) {
    return http.delete(`uom/deleteby-id/${id}`);
  }
  updateUom(id,data){
    return http.put(`uom/update/${id}`,data)
  }
  getprofileimg(userId,data) {
    return http.get(`auth/get-profile-image/${userId}`, data);
  }


  // wum api call
  addWum(data) {
    return http.post('weight/add-weight-unit', data);
  }
  getWumList(saasId) {
    return http.get(`/weight/get-all-weight-units/${saasId}`);
  }
  updateWum(id,data){
    return http.put(`weight/update-weight-unit/${id}`,data)
  }
  deleteWum(id) {
    return http.delete(`weight/delete-weight-unit/${id}`);
  }

  // PLM api call
  getPLMList(saasId) {
    return http.get(`product/get-plm/${saasId}`);
  }
  addPLM(data) {
    return http.post('product/add-plm', data);
  }
  updatePLM(id,data){
    return http.put(`product/update-plm/${id}`,data)
  }
  deletePLM(id) {
    return http.delete(`product/delete-plm/${id}`);
  }


  // Transport methos
  GetTransportmethod(saasId) {
    return http.get(`transport-method/get-all-transport-methods/${saasId}`);
  }

  addtransportmethod(data) {
    return http.post('transport-method/add-transport-method', data);
  }

  updatetransportmethod(id,data){
    return http.put(`transport-method/update-transport-method/${id}`,data)
  }
  deletetransportmethod(id) {
    return http.delete(`transport-method/delete-transport-method/${id}`);
  }


  // get country
  getCountries() {
    return http.get(`countrystate/get-country`);
  }

  // Seacrh country
  searchCountriesByKeyword(name,data) {
    return http.get(`countrystate/get-country-by-keyword/${name}`,data);
  }
   
  // H2O api call 
  getH2OList(saasId) {
    return http.get(`/impact/get-h2o/${saasId}`);
  }
  
  //create h2o impact
  addH2OImpact(data) {
    return http.post('impact/h2o', data);
  }

  //update h2o impact
  updateH2OImpact(id,data){
    return http.put(`/impact/h2o/update/${id}`,data)
  }
  //delete h2o impact
  deleteH2OImpact(id) {
    return http.delete(`/impact/h2o/delete/${id}`);
  }

  //Raw material api call
  getRawMaterialList(saasId) {
    return http.get(`/impact/get-raw-material/${saasId}`);
  }

  //create raw material impact
  addRawMaterialImpact(data) {
    return http.post('/impact/raw-material', data);
  }

  //update raw material impact
  updateRawMaterialImpact(id,data){
    return http.put(`/impact/rawmaterial/update/${id}`,data)
  }

  //delete raw material impact
  deleteRawMaterialImpact(id) {
    return http.delete(`/impact/rawmaterial/delete/${id}`);
  }
 
  //Product user period get api
  getProductUserPeriodList(saasId) {
    return http.get(`/product-use-period/get-all-product-use-periods/${saasId}`);
  }
  //add product use period
  addProductUserPeriod(data) {
    return http.post('/product-use-period/add-product-use-period', data);
  }
  
  //update product use period
  updateProductUserPeriod(id,data){
    return http.put(`/product-use-period/update-product-use-period/${id}`,data)
  }
  //delete product use period
  deleteProductUserPeriod(id) {
    return http.delete(`/product-use-period/delete-product-use-period/${id}`);
  }

   // Sroi impact api call
   getSroiList(saasId) {
    return http.get(`impact/get-sroi/${saasId}`);
  }
  addSROI(data) {
    return http.post('impact/sroi', data);
  }
  updateSROI(id,data){
    return http.put(`impact/sroi/update/${id}`,data)
  }

  deleteSROI(id) {
    return http.delete(`impact/sroi/delete/${id}`);
  }


  // energyimpact
  getenergyimpactList(saasId) {
    return http.get(`impact/get-energy/${saasId}`);
  }
  addenergyimpact(data) {
    return http.post('impact/energy', data);
  }
  updateenergyimpact(id,data){
    return http.put(`impact/energy/update/${id}`,data)
  }

  deleteenergyimpact(id) {
    return http.delete(`impact/energy/delete/${id}`);
  }


   // Co2 impact
   getCitrateList(saasId) {
    return http.get(`impact/get-co2/${saasId}`);
  }
  addCo2(data) {
    return http.post('impact/co2', data);
  }
  updatCo2(id,data){
    return http.put(`impact/co2/update/${id}`,data)
  }

  deleteCo2(id) {
    return http.delete(`impact/co2/delete/${id}`);
  }



     // MSDS
     getMSDSList(saasId) {
      return http.get(`msds/get-all-msds/${saasId}`);
    }
    addMSDS(data) {
      return http.post('msds/add-msds', data);
    }
    updatMSDS(id,data){
      return http.put(`msds/update-msds/${id}`,data)
    }
  
    deleteMSDS(id) {
      return http.delete(`msds/delete-msds/${id}`);
    }



    
     // Future Utilization Opportunities
     getFUPSList(saasId) {
      return http.get(`future-utilization/get-all-future-utilizations/${saasId}`);
    }
    addFUPS(data) {
      return http.post('future-utilization/add-future-utilization', data);
    }
    updatFUPS(id,data){
      return http.put(`future-utilization/update-future-utilization/${id}`,data)
    }
  
    deleteFUPS(id) {
      return http.delete(`future-utilization/delete-future-utilization/${id}`);
    }



         // WarhouseOwnner
         getWarhouseOwnnerList(saasId) {
          return http.get(`warehouse/get-owner-list/${saasId}`);
        }
        addWarhouseOwnner(data) {
          return http.post('warehouse/create-owner', data);
        }
        updatWarhouseOwnner(id,data){
          return http.put(`warehouse/update-owner/${id}`,data)
        }
      
        deleteWarhouseOwnner(id) {
          return http.delete(`warehouse/deleteby-owner-id/${id}`);
        }


          // WarhouseName
          getWarhouseNameList(saasId) {
            return http.get(`warehouse/get-all-warehouses-name/${saasId}`);
          }
          addWarhouseName(data) {
            return http.post('warehouse/add-warehouse-name', data);
          }
          updatWarhouseName(id,data){
            return http.put(`warehouse/update-warehouse-name/${id}`,data)
          }
        
          deleteWarhouseName(id) {
            return http.delete(`warehouse/delete-warehouse-name/${id}`);
          }



             // dominant
             getdominantList(saasId) {
              return http.get(`/material/get-all-materials/${saasId}`);
            }
            addMaterial(data) {
              return http.post('material/add', data);
            }
            updatMaterial(id,data){
              return http.put(`material/update/${id}`,data)
            }
          
            deleteMaterial(id) {
              return http.delete(`material/delete/${id}`);
            }

            // ocr 
            addOCR(data) {
              return http.post('impact/ocr',  data, {
                headers: {
                  'Content-Type': 'multipart/form-data', // Required for file uploads
                },
              })}



     // me
     me() {
      return http.get(`/auth/getpage-permission-user`);
    }
    userPermission(id) {
      return http.get(`/auth/getpage-permission-user/${id}`);
    }

    updatPermissionStatus(id,data){
      return http.put(`auth/updatepage-permission-user/${id}`,data)
    }


       // AllUser
   AllUser(saasId) {
    return http.get(`/auth/getall-user/${saasId}`);
  }

//  search user
searchUserByName(saasId,name,data) {
  return http.get(`auth/get-user/${saasId}/${name}`,data);
}
// delete User
deleteUserById(ID) {
  return http.delete(`auth/delete-user/${ID}`);
}
updatUserById(id,data){
  return http.put(`auth/admin-update-user/${id}`,data)
}

// search method by api endpoint
searchapiendpointByName(name,data) {
  return http.get(`api-access-rule/get-by-httpMethod/${name}`,data);
}
// search Role by api endpoint
searchapiendpointByRole(name,data) {
  return http.get(`api-access-rule/getAuthentication-by-role/${name}`,data);
}
// add/give access api endpoint
accessapiendpoint(data) {
  return http.post('api-access-rule/add', data);
}


// delete access api endpoint
deleteapiendpointById(ID) {
  return http.delete(`api-access-rule/remove/${ID}`);
}

// get product supplychain count by mounts and year
getsupplychaincountbymounts(saasId,monts,year,data) {
  return http.get(`supplierchain/by-date/${saasId}/${monts}/${year}`, data);
}
// get count by dasboard
getdasboardcount(saasId,data) {
  return http.get(`auth/getall-user-count/${saasId}`, data);
}

// impact update 
updateimpact(id, data) { 
  return http.put(`/impact/update/${id}`, data);
}
// Retailer link update 
updateRetailerlink(id,retailerId, data) { 
  return http.put(`/supplierchain/update-retailer/${id}/${retailerId}`, data);
}
// SM link update 
updateSMlink(id,smId, data) { 
  return http.put(`/supplierchain/update-supManu/${id}/${smId}`, data);
}
// update logistick data update 
updatelogisticklink(id, data) { 
  return http.put(`/supplierchain/update/${id}`, data);
}

// get isd code
getisdcode(data) {
  return http.get(`isdcode/getAllIsd`, data);
}

// supplychain delete 
deleteSupplychainById(ID) {
  return http.delete(`supplierchain/deleteby-id/${ID}`);
}

//get AllSupplier Type
getSupplierType(saasId) {
  return http.get(`/expertise-supplier-type/get-type-list/${saasId}`);
}
//Add  Supplier Type
AllSupplierType(data) {
  return http.post('expertise-supplier-type/add-type', data);
}
// Update  Supplier Type
UpdateSupplierType(id,data){
  return http.put(`expertise-supplier-type/update-type/${id}`,data)
}
//delete  Supplier Type

deleteSupplierType(id) {
  return http.delete(`expertise-supplier-type/delete-type-by-id/${id}`);
}

//get AllexpertiseType
getExpertise(saasId) {
  return http.get(`/expertise-supplier-type/get-expertise-list/${saasId}`);
}
//Add Expertise
AddExpertise(data) {
  return http.post('expertise-supplier-type/add-expertise', data);
}
// Update Expertise
UpdateSupplierExpertise(id,data){
  return http.put(`expertise-supplier-type/update-expertise/${id}`,data)
}

//delete Expertise

deleteExpertise(id) {
  return http.delete(`expertise-supplier-type/delete-expertise-by-id/${id}`);
}

// brnad logo Uploded

uploadBrandLogo(data, productId) {
  return http.put(`brand/save-logo/${productId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data', // Required for file uploads
    },
  });
}

//get AllexpertiseType
getBrandName(brandName) {
  return http.get(`/brand/get-image-by-name/${brandName}`);
}

//Retailer Logo Uploaded

uploadRetailerLogo(formData, productId) {
  return http.put(`/retailer/save-logo/${productId}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data', // Required for file uploads
    },
  });
}

  //Supplier Logo Uploaded
  uploadSupplierLogo(formData, SuppId) {
  return http.put(`/supplier-manufacture/save-logo/${SuppId}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data', // Required for file uploads
    },
  });
}

// get S
}
export default new DataService();
