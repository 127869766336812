import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Chip, OutlinedInput, FormHelperText, Box, Button, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataService from '../services/requestApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'
import { useAuth } from '../contexts/AuthConext';
const MatrialSelecteComonent = ({selectedKeys,setSelectedKeys,item,AddMaterail,addedMaterial, deleteAddmaterial}) => {
    console.log(item,selectedKeys)
    const {saasId
    } = useAuth(); 
  const [open, setOpen] = useState(false);
  const [material, setMaterial] = useState('');
  const [percentage, setpercentage] = useState('');

  // const Biogenics = ['Wood', 'Leather Chrome', 'Folt', 'Spruce Wood', 'Wool'];
  // const Metals = ['Iron', 'Copper', 'almunium', 'Cobalt', 'Lethium'];
  // const Plastics = ['fiber', 'Plastic'];
  // const Glass = ['Gas Glass', 'Liquid Glass'];
  // const Paper = ['Normal Plastic', 'Thin Plastic'];
  const [option, setoption] = useState([])
  // const option = ()=>{
  //   switch (item){
  //       case "Biogenics":
  //           return Biogenics;
  //       case "Metals":
  //           return Metals;
  //       case "Plastics":
  //           return Plastics;
  //       case "Glass":
  //            return Glass;
  //       case "Paper":
  //           return Paper;
  //        default:
  //               return Biogenics;
  //   }

  // }

  const GetByMaterila=async ()=>{
    try {
      const response = await DataService.GetbyMaterial(saasId,item)
      console.log(response.data.data)
      setoption(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
   if(item){
    GetByMaterila()
   }
  }, [item])
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedKeys(typeof value === 'string' ? value.split(',') : value);
    setOpen(false); // Close the dropdown menu after each selection
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {addedMaterial&& addedMaterial?.filter((el)=>el.materialType== item).length>0 &&<TableContainer className='my-3' component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead>
         <TableRow>
           <TableCell>Type</TableCell>
           <TableCell align="right">Name</TableCell>
           <TableCell align="right">Percent</TableCell>
           <TableCell align="right">Action</TableCell>
           {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
           <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
         </TableRow>
       </TableHead>
       <TableBody>
         {addedMaterial&& addedMaterial?.filter((el)=>el.materialType== item).map((row) => (
           <TableRow
             key={row.name}
             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
           >
             <TableCell component="th" scope="row">
               {row.materialType}
             </TableCell>
             <TableCell align="right">{row.materialName}</TableCell>
             <TableCell align="right">{row.persentOfProduct}</TableCell>
             <TableCell align="right" >
             <IconButton aria-label="delete">
             <CloseIcon  className='cursor-pointer' onClick={()=>{deleteAddmaterial(row)}}/>

             </IconButton>
                
              </TableCell>
             {/* <TableCell align="right">{row.carbs}</TableCell>
             <TableCell align="right">{row.protein}</TableCell> */}
           </TableRow>
         ))}
       </TableBody>
     </Table>
   </TableContainer>
}
    <FormControl fullWidth variant="outlined" >
    <InputLabel id="multi-select-label">{item}</InputLabel>
    <Select
      labelId="multi-select-label"
      value={selectedKeys}
      onChange={handleChange}
      input={<OutlinedInput label={item} />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected &&selected?.map((value) => (
            <Chip  key={value} label={value} />
            
          ))}
        </Box>
      )}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 224,
          },
        },
      }}
    >
      {option.map((option) => (
        <MenuItem onClick={()=>{setMaterial(option)}} key={option} value={option}>
          {option} 
         
        </MenuItem>
      ))}
    </Select>
    <TextField  
                              label="Percentage of product"
                              variant="outlined"
                              type="number"
                              fullWidth
                              margin="dense"
                              onChange={(e)=>setpercentage(e.target.value)}
                              // value={tabFields[el]?.percentage || ''}
                              // onChange={(e) => handleTabFieldChange(el, 'percentage', e.target.value)}
                            />
    {selectedKeys.length === 0 && (
      <FormHelperText>This fields are required.</FormHelperText>
    )}
    <Button onClick={()=>{AddMaterail(item,material,percentage)}} style={{ background: "#4F46E5", padding: "10px", width: "40px" }} variant="contained" color="primary">Add</Button>
  </FormControl>
  </> )
}

export default MatrialSelecteComonent