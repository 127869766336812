import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem } from '@mui/material';
import QRCode from 'react-qr-code'; 
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';
import { FaClock, FaBox, FaRecycle, FaWeight, FaClipboard, FaCubes, FaFlask, FaInfoCircle } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Tabs, Tab } from 'react-bootstrap';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Chart from './Productpages/Chart';
import ImpactCard from './Productpages/ImpactCard';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import EditProductModal from './Modal/EditProductModal';
import { useAuth } from '../contexts/AuthConext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Productpages/Pdf/PdfDocument';


const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

const scrollTable = {
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};


const Product = ({metrial,productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData,fetchSupplierChainData,}) => {
  const qrRef = useRef();
  const {saasId
  } = useAuth();

  const downloadQRCode = () => {
    if (qrRef.current) {
     
      // Temporarily set the QR code's height for the download
      qrRef.current.style.height = '100px';
      qrRef.current.style.width = '100px';
  
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleMenuClose()
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
          handleMenuClose()
        })
        .finally(() => {
          // Restore the original size after the download
          qrRef.current.style.height = '30px';
          qrRef.current.style.width = '30px';
          handleMenuClose()
        });
    }
  };
  
    const [key, setKey] = useState('biogenics');

    const cardRef = useRef(); // Add a reference for the card to download as PDF
    const downloadPDF = () => {  
      const input = cardRef.current;  
    
      // Ensure the image is fully loaded before capturing the canvas  
      const images = input.querySelectorAll('img');  
      const imagePromises = Array.from(images).map((img) => {  
        return new Promise((resolve) => {  
          if (img.complete) {  
            resolve();  
          } else {  
            img.onload = resolve;  
            img.onerror = resolve;  
          }  
        });  
      });  
    
      Promise.all(imagePromises).then(() => {  
        // After all images are loaded, render the canvas and generate the PDF  
        html2canvas(input, {  
          useCORS: true, // Enable CORS to allow cross-origin images  
          allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
        })  
          .then((canvas) => {  
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF();  
            const imgProps = pdf.getImageProperties(imgData);  
            const pdfWidth = pdf.internal.pageSize.getWidth();  
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  
    
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
            pdf.save('retailer-details.pdf');  
          })  
          .catch((error) => {  
            console.error('Failed to generate PDF:', error);  
          });  
      });  
    };
    

    // product page
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);


  const handleEditClick = () => {
    setFormValues(productData);
    setModalOpen(true);
  };

  useEffect(() => {
 console.log("productData",productData)
 console.log("saasId",saasId)

  }, [])
  


  const handleSaveClick = async () => {
    try {
      const response = await DataService.updateProduct(productData?.productId, formValues);
      const productId = response.data.data.productId;
      if (response.data.status) {
        Swal.fire({
          title: 'Success!',
          text: 'Product updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        await uploadProductImage(productId);
        setModalOpen(false);
        fetchSupplierChainData()
      } else {
        console.error('Failed to update product:', response.data.message);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.response?.data?.error || 'Failed to update product.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error('Failed to update product:', error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await DataService.getCategoryList(saasId);
      if (response.data.status) {
        setCategoryList(response.data.data);
      } else {
        console.error('Failed to fetch category list:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadProductImage = async (productId) => {
    if (!selectedFile) return;
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      // Log the FormData object to check if the file is appended correctly
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      const imageResponse = await DataService.uploadProductImage(formData, productId);
  
      console.log('Image uploaded successfully:', imageResponse.data);
  
      Swal.fire({
        icon: 'success',
        title: 'Image Uploaded Successfully',
        text: 'The product image has been uploaded successfully!',
      });
  
    } catch (imageError) {
      console.error('Error uploading image:', imageError);
  
      Swal.fire({
        icon: 'error',
        title: 'Image Upload Failed',
        text: 'There was an error uploading the product image.',
      });
    }
  };
  

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAndClose = () => {
    handleEditClick();
    handleMenuClose();
  };
  return (
    <div style={containerWrapperStyle}>

    <div   className="scrollable-container " style={scrollableContainerStyle}>
     
      
          <Card style={{background:"#EBFEF6"}} className="w-full  mx-auto  rounded-lg">
          {/* <Typography variant="h6" className="mb-6">
             Product Entity
            </Typography> */}
             <Box style={{background:"#048a52"}} className="flex justify-between p-2 text-white">
                  <Typography  variant="h5" className="fw-bold mt-2 mx-2">{productData?.name}</Typography>
              
                  <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{color:"white"}} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
     
      >
        <MenuItem style={{ background: "#EBFEF6" }}>
          <Card style={{ background: "#EBFEF6" }} className="mb-1 w-full max-w-3xl mx-auto rounded-lg">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditAndClose}  aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        metrial={metrial}
                        productData={productData}
                        impactEntity={impactEntity}
                        supplyChainEntity={supplyChainEntity}
                        retailerData={retailerData}
                        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}
                      />
                    }
                    fileName="product-data.pdf"
                    style={{ textDecoration: 'none' }}
                  >
                    {({ loading }) => (
                      <IconButton aria-label="download">
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7.5}>
              
                <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap mt-2"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 135,
               height: 25,
             }}
             onClick={downloadQRCode}
            >Download QRCode </Button>
           
                </Grid>
                <Grid item xs={4.5} textAlign="right">
                <QRCode ref={qrRef} value={`https://onescan.digital/productDetails/${productData?.productId}`} size={100}  style={{height:"30px"}}/>
     
            
          
                </Grid>
              </Grid>
            </CardContent>

       
          </Card>
    
        </MenuItem>
      </Menu>
                     </Box>
                   
            <Grid container  spacing={2}>
              <Grid item xs={12} sm={4}>
                <img src={productData?.image} alt="Wooden Table"  className="w-[200px] h-[200px] p-3" crossOrigin="anonymous" />
                <Grid item xs={12} style={{background:"#EBFEF6"}} className=" rounded-lg p-3  shadow-md mx-4 gap-3 mt-2">
  {/* <FaInfoCircle className="text-blue-500 mt-1" /> */}
   {/* Add the description icon */}
  <Typography variant="body1" className="text-black fw-bold">
    {productData?.description}
  </Typography>
</Grid>
              </Grid>
 

<Grid item xs={12} sm={8}>
  <Grid container spacing={2} className='mt-1'>
  {/* <Typography variant="body1" className="mb-4 mt-4">
                  {productData?.description}
                  </Typography> */}
    {/* Row 1 */}
    <Grid item xs={6} className="flex items-center">
      <FaClock className="mr-2 mt-1 text-blue-500" /> {/* Blue icon */}
      <Typography className='fw-bold' variant="body2">Use period (years)</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography  className='fw-bold' variant="body1">{productData?.basicInfo?.usePeriod}</Typography>
    </Grid>
{/* 
    <Grid item xs={6} className="flex items-center">
      <FaBox className="mr-2 mt-1 text-green-500" /> 
      <Typography variant="body2">Dominant material group</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography variant="body1">{productData?.materials}</Typography>
    </Grid> */}

    {/* Row 2 */}
    <Grid item xs={6} className="flex items-center">
      <FaRecycle className="mr-2 mt-1 text-orange-500" /> {/* Orange icon */}
      <Typography  className='fw-bold' variant="body2">Next use application</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography  className='fw-bold' variant="body1">{productData?.basicInfo?.useApplication}</Typography>
    </Grid>

    <Grid item xs={6} className="flex items-center">
      <FaFlask className="mr-2 mt-1 text-red-500" /> {/* Red icon */}
      <Typography  className='fw-bold' variant="body2">End of use treatment</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography  className='fw-bold' variant="body1">{productData?.basicInfo?.usedTreatment}</Typography>
    </Grid>

    {/* Row 3 */}
    <Grid item xs={6} className="flex items-center">
      <FaWeight className="mr-2 mt-1 text-purple-500" /> {/* Purple icon */}
      <Typography  className='fw-bold' variant="body2">Weight</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography  className='fw-bold' variant="body1">{productData?.basicInfo?.weight} {productData?.basicInfo?.weightUnit}</Typography>
    </Grid>

    <Grid item xs={6} className="flex items-center">
      <FaCubes className="mr-2 mt-1 text-teal-500" /> {/* Teal icon */}
      <Typography  className='fw-bold' variant="body2">Quantity</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography  className='fw-bold' variant="body1">{productData?.basicInfo?.quantity} {productData?.basicInfo?.quantityUnit}</Typography>
    </Grid>

    {/* Row 4 */}
    <Grid item xs={6} className="flex items-center">
      <FaClipboard className="mr-2 mt-1 text-yellow-500" /> {/* Yellow icon */}
      <Typography  className='fw-bold' variant="body2">MSDS</Typography>
    </Grid>
    <Grid item xs={6} className="flex items-center">
      <Typography style={{  whiteSpace: 'pre-line' }}  className='fw-bold' variant="body1">{productData?.basicInfo?.msds}</Typography>
    </Grid>
  </Grid>
</Grid>


            </Grid>
        
          </Card>

          <Card style={{background:"#EBFEF6"}} className="mt-3 p-2 w-full  mx-auto   rounded-lg">
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="h6" align="center">
        Product Dominant material group
      </Typography>
    </Grid>
<hr />
    <Grid item xs={12}>
      <TableContainer className=" max-h-28 scroll-my-4   rounded-lg">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Material Type</strong></TableCell>
              <TableCell><strong>Material Name</strong></TableCell>
     
            </TableRow>
          </TableHead>
          <TableBody>
            {metrial?.map((material) => (
              <TableRow key={material?.id}>
                <TableCell>{material?.id}</TableCell>
                <TableCell>{material?.materialType}</TableCell>
                <TableCell>{material?.materialName}</TableCell>
            
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
</Card>

    
           
    </div>
    <EditProductModal
        modalOpen={modalOpen}
        fetchSupplierChainData={fetchSupplierChainData}
        setModalOpen={setModalOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        productData={productData}
        handleSaveClick={handleSaveClick}
        categoryList={categoryList}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
      />
    </div>
  );
};

export default Product;
