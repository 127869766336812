import React, { useEffect, useState } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Checkbox, FormControl, FormLabel, Box, Typography, Grid, LinearProgress } from '@mui/material';
import axios from 'axios';
import DataService from '../services/requestApi';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useAuth } from '../contexts/AuthConext';
import PassportSummary from './PassportSummary/PassportSummary';
const EndProductStepSix = ({ handleNext }) => {
  const {Endprogress,step2progress,step3progress,step4progress, saasId, savestep5Progress
  } = useAuth(); 



  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState("");


  const [supplierData, setSupplierData] = useState({
    saasId:saasId,
    supplierId: "",
    name: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: "",
    },
    contactInfo: {
      phone: "",
      phone2: "",
      email: "",
      email1: "",
    },
  });

 // Required fields list
 const requiredFields = [
  { key: 'name' },
  { key: 'address.flatNo' },
  { key: 'address.cityArea' },
  { key: 'address.state' },
  { key: 'address.district' },
  { key: 'address.pincode' },
  { key: 'contactInfo.phone' },
  { key: 'contactInfo.phone2' },
  { key: 'contactInfo.email' },
  { key: 'contactInfo.email1' },
];

// Helper function to get nested values
const getNestedValue = (obj, key) => {
  return key.split('.').reduce((o, k) => (o && o[k] !== undefined ? o[k] : ""), obj);
};

// Calculate progress based on filled fields
const calculateProgress = () => {
  const totalFields = requiredFields.length;
  const filledFields = requiredFields.filter(({ key }) => {
    const fieldValue = getNestedValue(supplierData, key);
    return fieldValue !== ""; // Check if the field is not empty
  }).length;

  return (filledFields / totalFields) * 100;
};

const progress = calculateProgress();

useEffect(() => {
  const progress = calculateProgress();
  savestep5Progress(progress); // Save progress in context whenever it changes
}, [supplierData]);


const handleSearchChange = (event) => {  
  const value = event.target.value;  
  setSearchTerm(value);  
  if (value) {  
    fetchSupplierData(value);  
  } else {  
    setSearchData([]); // Clear search data if the input is empty  
  }  
};  

const fetchSupplierData = async (name) => {  
  try {  
    const response = await DataService.getSearchSupplier(saasId,name);  
    if (response.data.status && Array.isArray(response.data.data)) {  
      setSearchData(response.data.data);  
      
      // Automatically fill the supplierData with selected supplier information  
      if (response.data.data.length > 0) {  
        // Assuming the first supplier in the list is selected for autofill  
        const supplier = response.data.data[0];  // Modify as needed  
        setSupplierData({  
          supplierId: supplier.supplierId,  
          name: supplier.name,  
          address: {  
            flatNo: supplier.address.flatNo,  
            cityArea: supplier.address.cityArea,  
            country: supplier.address.country,  
            state: supplier.address.state,  
            district: supplier.address.district,  
            pincode: supplier.address.pincode,  
          },  
          contactInfo: {  
            phone: supplier.contactInfo.phone,  
            phone2: supplier.contactInfo.phone2,  
            email: supplier.contactInfo.email,  
            email1: supplier.contactInfo.email1,  
          },  
        });  
      }  
      
    } else {  
      setSearchData([]); // Clear search data if no results  
    }  
  } catch (error) {  
    console.error('Error fetching supplier data:', error);  
    setSearchData([]); // Clear search data in case of an error  
  }  
};  
  // Link supplier

  const handlelinkmanufactureProduct = async () => {
    try {
      const productId = localStorage.getItem('productId');
      const { supplierId } = supplierData;

      if (!productId || !supplierId) {
        Swal.fire({
          title: 'Error',
          text: 'Product ID or Manufacturer ID is missing',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const response = await DataService.linkSupplierProduct(supplierId, productId);

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Product linked to Supplier successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          handleNext();
          localStorage.setItem("Supplir_id", supplierId)
          
        });
      }
    } catch (error) {
      console.error("API call failed", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to link manufacturer product',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };


  // progress bar
const stepsProgress = [
  { name: 'Basic Information', stepNumber: 1, totalSteps: 7, progress: Endprogress },
  { name: 'Materials', stepNumber: 2, totalSteps: 7, progress: step2progress },
  { name: 'Impact', stepNumber: 3, totalSteps: 7, progress: step3progress }, // Example percentage for Materials
  { name: 'Manufacturer', stepNumber: 4, totalSteps: 7, progress: step4progress }, // Example percentage for Materials

  { name: 'Supplier Entity ', stepNumber: 5, totalSteps: 7, progress: progress }, // Example percentage for Materials

];
  return (
    <div style={containerWrapperStyle}>

    <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
      <Grid container alignItems="center" spacing={2} item xs={12} md={7}>
        <Box sx={{ p: 2 }}>
          <Typography style={{ color: "GrayText" }} variant="h6" component="h6" mb={2} gutterBottom>Step 5/7</Typography>
          <Typography className='fw-bold' variant="h6">Supplier Entity</Typography>
          <FormLabel component="legend">Supplier Entity by filling in basic information.</FormLabel>
          <Box className="p-3 bg-white rounded-lg shadow-md">
          <Typography variant="h6" className="mb-3">Supplier's name</Typography>
          <InputGroup className="mb-4">
      <Input
        type="text"
        fullWidth
        placeholder="Search Supplier's name"
        list="Supplier"
        className='p-3'
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <InputGroupText>
        <FaSearch />
      </InputGroupText>
      <datalist id="Supplier">  
  {searchData && searchData.map((supplier) => (  
    <option key={supplier.supplierId} value={supplier.name} />  
  ))}  
</datalist>  
    </InputGroup>
            <Typography variant="h6" className="mb-3">Supplier ID  (  Unique identifier for each supplier )</Typography>
            <TextField
              fullWidth
              label="Supplier ID"
              variant="outlined"
             
              className="mb-4"
              value={supplierData.supplierId}
              onChange={(e) => setSupplierData({ ...supplierData, supplierId: e.target.value })}
              disabled
            />
            
{/*             
            <Typography variant="h6" className="mb-3">Select supplier location</Typography>
            <RadioGroup row className="mb-4">
              <FormControlLabel
                value="auto"
                control={<Radio />}
                label="Auto fill address (Geographical location of the manufacturer)"
              />
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Manual address fill form"
              />
            </RadioGroup>
            <Typography variant="h6" className="mb-3">Geographical location of the manufacturer</Typography>
            <Box className="mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.825171935421!2d77.3601719!3d28.6457607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfab0e02a2d93%3A0x5f72346f616fbbb7!2s242%2C%20Niti%20Khand%20I%2C%20Indirapuram%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201010%2C%20India!5e0!3m2!1sen!2sus!4v1635088137177!5m2!1sen!2sus"
                width="100%"
                height="200"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Box> */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Address" variant="outlined" className="mb-4" value={supplierData.address.flatNo} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, flatNo: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Area, Street, Sector, Village" variant="outlined" className="mb-4" value={supplierData.address.cityArea} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, cityArea: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="District" variant="outlined" className="mb-4" value={supplierData.address.district} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, district: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="State" variant="outlined" className="mb-4" value={supplierData.address.state} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, state: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Country" variant="outlined" className="mb-4" value={supplierData.address.country} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, country: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Pincode" variant="outlined" className="mb-4" value={supplierData.address.pincode} onChange={(e) => setSupplierData({ ...supplierData, address: { ...supplierData.address, pincode: e.target.value } })} />
              </Grid>
            </Grid>
            <Typography variant="h6" className="mb-3">Contact Info ( Contact details for the supplier )</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Mobile No. (Primary)" variant="outlined" className="mb-4" value={supplierData.contactInfo.phone} onChange={(e) => setSupplierData({ ...supplierData, contactInfo: { ...supplierData.contactInfo, phone: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Mobile No. (Secondary)" variant="outlined" className="mb-4" value={supplierData.contactInfo.phone2} onChange={(e) => setSupplierData({ ...supplierData, contactInfo: { ...supplierData.contactInfo, phone2: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Email (Primary)" variant="outlined" className="mb-4" value={supplierData.contactInfo.email} onChange={(e) => setSupplierData({ ...supplierData, contactInfo: { ...supplierData.contactInfo, email: e.target.value } })} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth label="Email (Secondary)" variant="outlined" className="mb-4" value={supplierData.contactInfo.email1} onChange={(e) => setSupplierData({ ...supplierData, contactInfo: { ...supplierData.contactInfo, email1: e.target.value } })} />
              </Grid>
            </Grid>
          </Box>
          <div className='flex justify-center mt-3 mb-5'>
       <Button  onClick={handlelinkmanufactureProduct}   style={{ background: "#4F46E5",  padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
       Step 6 : Retail Entity
       </Button>    </div>
        </Box>
      </Grid>


      <Grid style={{ margin: "3px" }} className='mx-3' md={4} spacing={2} >
      <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

           </Grid>
    </Grid>
    </div>
  );
};

export default EndProductStepSix;
