import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  ThemeProvider,
  createTheme,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { useAuth } from '../contexts/AuthConext';
import DataService from '../services/requestApi';
import { useParams } from 'react-router-dom'; // Import useParams
// Custom theme to match the purple accent color
const theme = createTheme({
  palette: {
    primary: {
      main: '#6750A4',
    },
  },
});



function PermissionManagement() {
// const { permission,  } = useAuth(); // Make sure to call the function
const { userId } = useParams();
  const [permissions, setPermissions] = useState([]);

  const GetUserpermission = async () => {
    try {
      const response = await DataService.userPermission(userId);
      setPermissions(response.data.data.permission);
    
      console.log('fetch permission', response.data.data.permission);

    } catch (error) {
      setPermissions(null);
      console.error('Failed to fetch permission', error);
    }
  };


useEffect(() => {
  GetUserpermission()
}, [])




  const handleToggle = async (id) => {
    const updatedPermissions = permissions?.map((item) =>
      item?.id === id ? { ...item, enabled: !item.enabled } : item
    );
    
    // setPermissions(updatedPermissions);

    // Extract the page IDs of the enabled permissions
    const enabledPermissions = updatedPermissions
      .filter((item) => item.enabled)
      .map((item) => item?.id);

    // Prepare the payload
    const payload = {
      pageId: enabledPermissions,
    };

    try {
      // Call the DataService's updatPermissionStatus function
      const response = await DataService.updatPermissionStatus(userId, payload); // Assuming the user ID is 3, replace as necessary
      console.log('API response:', response.data);
      if(response.data.status){
        GetUserpermission()
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ margin: 'auto', padding: 4, }}>
        <Typography variant="h5" gutterBottom>
          Permission Management
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Permission Management allows access to the user.
        </Typography>
        <Card elevation={3}>
          <CardContent>
            {/* Box with fixed height and scrolling */}
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <List>
                {permissions?.map((item, index) => (
                  <React.Fragment key={item?.id}>
                    <ListItem
                    className='mb-2 px-4'
                      secondaryAction={
                        <Switch
                          edge="end"
                          checked={item?.status}
                          onChange={() => handleToggle(item?.id)}
                          color="primary"
                        />
                      }
                      disablePadding
                    >
                      <ListItemText
                        primary={item?.name}
                        secondary='Permission Management allow to access to user'
                        sx={{ my: 1 }}
                      />
                    </ListItem>
                    {index < permissions?.length - 1 && (
                      <Divider component="li" variant="fullWidth" light />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}

export default PermissionManagement;
