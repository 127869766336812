import React, { useEffect, useState } from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Grid,
  LinearProgress,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import Swal from "sweetalert2";
import DataService from "../services/requestApi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthConext";
import PassportSummary from "./PassportSummary/PassportSummary";

const EndProductStepSeven = ({ handleNext }) => {
  const {
    isdCodes,
    fetchIsdCodes,
    saasId,
    WarehouseName,
    fetchsetWarehouseNameList,
    WarhouseOwnner,
    fetchWarhouseOwnnerList,
    transport,
    getAllTransport,
    Transportmethod,
    fetchgetserTransportmethodList,
    // progress
    countries,
    totalProgress,
    fetchCountries,
    Endprogress,
    step2progress,
    step3progress,
    step4progress,
    step5progress,
    step6progress,
    savestep7Progress,
    states,
    fetchStates,
  } = useAuth();

  const handlecountryOpen = () => {
    fetchCountries();
  };

  const handleWarhouseOwnnerDropdownOpen = () => {
    fetchWarhouseOwnnerList();
  };

  const handleWarehouseNameDropdownOpen = () => {
    fetchsetWarehouseNameList();
  };
  const handleTransportDropdownOpen = () => {
    getAllTransport();
  };
  const handleTransportmethodDropdownOpen = () => {
    fetchgetserTransportmethodList();
  };
  const navigat = useNavigate();

  const productId = localStorage.getItem("productId");
  const Maunf_id = localStorage.getItem("UnifiedSM_id");
  // const Supplir_id = localStorage.getItem("Supplir_id")
  const Reatailr_id = localStorage.getItem("Reatailr_id");
  const [address, setAddress] = useState({
    flatNo: "",
    cityArea: "",
    country: "",
    state: "",
    // "district": "",
    pincode: "",
  });

  useEffect(() => {
    if (address.country) {
      fetchStates(address.country);
    }
  }, [address.country]);

  const [data, setData] = useState({
    saasId: saasId,
    supplier_name: "Supplier Name1",
    progress_bar: totalProgress,
    location: "new york",
    logisticsPartners: ["Partner 1", "Partner 2"],
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    UnifiedSupplierManufacturerId: Maunf_id,
    product_id: productId,
    // "supplier_id": [Supplir_id],
    retailer_id: [Reatailr_id],
    transport_method: [],
    transport_rout: [],
    transport_rout_start: "",
    transport_rout_end: "",
    warehouse_name: "",
    warehouse_license_no: "",
    warehouse_manager: "",
    warehouse_gst_no: "",
    address: address,
  });
  // Required fields list
  const requiredFields = [
    { key: "phone" },
    { key: "phone2" },
    { key: "email" },
    { key: "email2" },
    { key: "transport_rout" },
    { key: "transport_method" },
    { key: "transport_rout_start" },
    { key: "transport_rout_end" },
    { key: "warehouse_name" },
    { key: "warehouse_manager" },
    { key: "warehouse_license_no" },
    { key: "warehouse_gst_no" },
  ];

  // Helper function to get nested values
  const getNestedValue = (obj, key) => {
    return key
      .split(".")
      .reduce((o, k) => (o && o[k] !== undefined ? o[k] : ""), obj);
  };

  // Calculate progress based on filled fields
  const calculateProgress = () => {
    const totalFields = requiredFields.length;
    const filledFields = requiredFields.filter(({ key }) => {
      const fieldValue = getNestedValue(data, key);

      // For arrays, check if they are not empty
      if (Array.isArray(fieldValue)) {
        return fieldValue.length > 0; // Valid only if it contains items
      }

      // For other types, check if the value is not empty
      return fieldValue !== "";
    }).length;

    return (filledFields / totalFields) * 100;
  };
  const progress = calculateProgress();

  useEffect(() => {
    const progress = calculateProgress();
    savestep7Progress(progress); // Save progress in context whenever it changes
  }, [data]);

  // dropdown close
  const [openTransport, setOpenTransport] = useState(false); // Transport Routes dropdown state

  // Transport Routes dropdown handlers
  const handleTransporttDropdownOpen = () => setOpenTransport(true);
  const handleTransportDropdownClose = () => setOpenTransport(false);

  const [openTransportMethod, setOpenTransportMethod] = useState(false); // Transport Methods dropdown state

  // Transport Methods dropdown handlers
  const handleTransporttmethodDropdownOpen = () => setOpenTransportMethod(true);
  const handleTransportmethodDropdownClose = () =>
    setOpenTransportMethod(false);

  // Sync address to data whenever it changes
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      address: address,
    }));
  }, [address]);

  const handelaAdressChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const scrollableContainerStyle = {
    height: "80vh", // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };

  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };

  const [errorPrimary, setErrorPrimary] = useState(""); // Error state for primary number
  const [errorSecondary, setErrorSecondary] = useState(""); // Error state for secondary number

  const [selectedIsdCode, setSelectedIsdCode] = useState("+91");

  const handelChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [e.target.name]: e.target.value });
    // Validate both primary and secondary numbers based on the field name
    if (name === "phone") {
      validatePhoneNumber(value, selectedIsdCode, "primary");
    } else if (name === "phone2") {
      validatePhoneNumber(value, selectedIsdCode, "secondary");
    }
    if (e.target.name === "transport_rout") {
      setOpenTransport(false);
    } else if (e.target.name === "transport_method") {
      setOpenTransportMethod(false);
    }
  };

  // progress bar
  const stepsProgress = [
    {
      name: "Basic Information",
      stepNumber: 1,
      totalSteps: 6,
      progress: Endprogress,
    },
    {
      name: "Materials",
      stepNumber: 2,
      totalSteps: 6,
      progress: step2progress,
    },
    { name: "Impact", stepNumber: 3, totalSteps: 6, progress: step3progress }, // Example percentage for Materials
    {
      name: "UnifiedSupplierManufacturer",
      stepNumber: 4,
      totalSteps: 6,
      progress: step4progress,
    }, // Example percentage for Materials

    {
      name: "Retail Entity",
      stepNumber: 5,
      totalSteps: 6,
      progress: step5progress,
    }, // Example percentage for Materials
    {
      name: "Supply Chain Entity",
      stepNumber: 6,
      totalSteps: 6,
      progress: progress,
    }, // Example percentage for Materials
  ];

  const handleDeleteTransportRoute = (value) => {
    handleTransportDropdownClose();
    const newRoutes = data.transport_rout.filter((route) => route !== value);
    setData((prevData) => ({ ...prevData, transport_rout: newRoutes }));
  };

  const handleDeleteTransportMethod = (value) => {
    handleTransportmethodDropdownClose();
    const newMethods = data.transport_method.filter(
      (method) => method !== value
    );
    setData((prevData) => ({ ...prevData, transport_method: newMethods }));
  };

  // isd code

  // Phone number validation logic
  const validatePhoneNumber = (phone, isdCode, field) => {
    const selectedCountry = isdCodes.find((isd) => isd.code === isdCode);
    let errorMessage = "";

    if (selectedCountry) {
      const minLength = selectedCountry.minphoneLength; // Minimum phone length
      const maxLength = selectedCountry.maxphoneLength; // Maximum phone length

      // Check phone length
      if (phone?.length < minLength || phone?.length > maxLength) {
        if (minLength === maxLength) {
          errorMessage = `Phone number must be ${maxLength} digits long.`;
        } else {
          errorMessage = `Phone number must be between ${minLength} and ${maxLength} digits long.`;
        }
      }
    }

    // Set error message only if there is one
    if (field === "primary") {
      setErrorPrimary(errorMessage || ""); // Set error message for primary phone
    } else if (field === "secondary") {
      setErrorSecondary(errorMessage || ""); // Set error message for secondary phone
    }

    return errorMessage; // Return the error message if any
  };

  useEffect(() => {
    fetchIsdCodes();
  }, []);

  const AddSupplyChain = async () => {
    try {
      const primaryErrorMessage = validatePhoneNumber(
        data.phone,
        selectedIsdCode,
        "primary"
      );
      const secondaryErrorMessage = validatePhoneNumber(
        data.phone2,
        selectedIsdCode,
        "secondary"
      );

      // Check if there are any validation errors
      // Check if there are any validation errors
      if (primaryErrorMessage) {
        // Display validation error message for primary phone
        Swal.fire({
          title: "Validation Error!",
          text: primaryErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Stop execution if validation fails
      }

      if (secondaryErrorMessage) {
        // Display validation error message for secondary phone
        Swal.fire({
          title: "Validation Error!",
          text: secondaryErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Stop execution if validation fails
      }

      const response = await DataService.SupplyChainEntity(data);

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Link Supplier Product successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          // Clear address and data state
          setAddress({
            flatNo: "",
            cityArea: "",
            country: "",
            state: "",
            // district: "",
            pincode: "",
          });

          setData({
            supplier_name: "",
            progress_bar: "",
            location: "",
            logisticsPartners: [],
            phone: "",
            phone2: "",
            email: "",
            email2: "",
            UnifiedSupplierManufacturerId: "",
            product_id: "",
            supplier_id: [],
            retailer_id: [],
            transport_method: [],
            transport_rout: [],
            transport_rout_start: "",
            transport_rout_end: "",
            warehouse_name: "",
            warehouse_license_no: "",
            warehouse_manager: "",
            warehouse_gst_no: "",
            address: {
              flatNo: "",
              cityArea: "",
              country: "",
              state: "",
              // district: "",
              pincode: "",
            },
          });

          // Remove productId, Maunf_id, Supplir_id, Reatailr_id from localStorage
          localStorage.removeItem("productId");
          localStorage.removeItem("impactId");
          localStorage.removeItem("materialId");

          localStorage.removeItem("UnifiedSM_id");
          localStorage.removeItem("Reatailr_id");

          // localStorage.removeItem("Supplir_id");

          navigat("/Product");
        });
      }
      console.log(response);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      console.log(error);
    }
  };

  // isd code code

  // Handle ISD Code change
  const handleIsdCodeChange = (event) => {
    const newIsdCode = event.target.value;
    setSelectedIsdCode(newIsdCode);
    validatePhoneNumber(data.phone, newIsdCode, "primary"); // Validate primary phone when ISD code changes
  };

  return (
    <div style={containerWrapperStyle}>
      <Grid
        container
        spacing={2}
        className="scrollable-container p-3"
        style={scrollableContainerStyle}
      >
        <Grid container alignItems="center" spacing={2} item xs={12} md={7}>
          <Box sx={{ p: 2 }}>
            <Typography
              style={{ color: "GrayText" }}
              variant="h6"
              component="h6"
              mb={2}
              gutterBottom
            >
              Step 6/6
            </Typography>
            <Typography className="fw-bold" variant="h6">
              Logistics and distribution
            </Typography>
            <FormLabel component="legend">
              Logistics and distribution by filling in basic information.
            </FormLabel>
            <Box className="p-3 mt-3 bg-white rounded-lg shadow-md">
              {/* <Typography variant="h6" gutterBottom>
      Supply Chain ID  ( Unique identifier for each supply chain )
      </Typography> */}

              <Grid container spacing={2}>
                {/* Transport Details */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Transport Details (Information about the transport methods
                    and routes)
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Transport Routes */}
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="transport-rout-label">
                          Transport Routes *
                        </InputLabel>
                        <Select
                          required
                          fullWidth
                          open={openTransport}
                          onClose={handleTransportDropdownClose}
                          onOpen={handleTransporttDropdownOpen}
                          labelId="transport-rout-label"
                          id="transport-rout"
                          multiple
                          name="transport_rout"
                          value={data.transport_rout || []}
                          onChange={handelChange}
                          onFocus={handleTransportDropdownOpen}
                          renderValue={(selected) => (
                            <div className="flex flex-wrap gap-1">
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  style={{ zIndex: "99999" }}
                                  onDelete={() =>
                                    handleDeleteTransportRoute(value)
                                  }
                                  label={
                                    transport.find(
                                      (category) => category.routeName === value
                                    )?.routeName
                                  }
                                />
                              ))}
                            </div>
                          )}
                        >
                          {transport.map((category) => (
                            <MenuItem
                              key={category.id}
                              value={category.routeName}
                            >
                              {category.routeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Transport Methods */}
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="transport-method-label">
                          Transport Methods *
                        </InputLabel>
                        <Select
                          required
                          open={openTransportMethod}
                          onClose={handleTransportmethodDropdownClose}
                          onOpen={handleTransporttmethodDropdownOpen}
                          labelId="transport-method-label"
                          id="transport-method"
                          multiple
                          name="transport_method"
                          value={data.transport_method || []}
                          onChange={handelChange}
                          onFocus={handleTransportmethodDropdownOpen}
                          renderValue={(selected) => (
                            <div className="flex flex-wrap gap-1">
                              {selected.map((value) => (
                                <Chip
                                  style={{ zIndex: "99999" }}
                                  onDelete={() =>
                                    handleDeleteTransportMethod(value)
                                  }
                                  key={value}
                                  label={
                                    Transportmethod.find(
                                      (category) =>
                                        category.methodName === value
                                    )?.methodName
                                  }
                                />
                              ))}
                            </div>
                          )}
                        >
                          {Transportmethod.map((category) => (
                            <MenuItem
                              key={category.id}
                              value={category.methodName}
                            >
                              {category.methodName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Transport Routes Start */}
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Transport Routes Start Date & Time *"
                        placeholder="Select Date and Time"
                        variant="outlined"
                        name="transport_rout_start"
                        type="datetime-local"
                        value={data.transport_rout_start}
                        onChange={handelChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* Transport Routes End */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Transport Routes End Date & Time *"
                        placeholder="Select Date and Time"
                        variant="outlined"
                        name="transport_rout_end"
                        type="datetime-local"
                        value={data.transport_rout_end}
                        onChange={handelChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Warehouse Details */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Warehouse Details ( Information about storage locations)
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Warehouse Name */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Warehouse Name"
                        placeholder="Warehouse Name"
                        variant="outlined"
                        name="warehouse_name"
                        value={data.warehouse_name}
                        onChange={handelChange}
                        select
                        SelectProps={{ native: true }}
                        onFocus={handleWarehouseNameDropdownOpen}
                      >
                        <option value=""></option>
                        {WarehouseName?.map((category) => (
                          <option key={category.id} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Warehouse Owner Name */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Warehouse Owner Name "
                        placeholder="Warehouse Owner Name"
                        variant="outlined"
                        name="warehouse_manager"
                        value={data.warehouse_manager}
                        onChange={handelChange}
                        select
                        SelectProps={{ native: true }}
                        onFocus={handleWarhouseOwnnerDropdownOpen}
                      >
                        <option value=""></option>
                        {WarhouseOwnner?.map((category) => (
                          <option key={category.id} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Warehouse License No */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Warehouse License No "
                        placeholder="Warehouse License Number"
                        variant="outlined"
                        name="warehouse_license_no"
                        value={data.warehouse_license_no}
                        onChange={handelChange}
                      />
                    </Grid>
                    {/* Warehouse GST No */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Warehouse GST No "
                        placeholder="Warehouse GST Number"
                        variant="outlined"
                        name="warehouse_gst_no"
                        value={data.warehouse_gst_no}
                        onChange={handelChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Warehouse Address */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Warehouse Address ( Information about storage locations)
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Flat, Company */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Address "
                        placeholder="Address"
                        variant="outlined"
                        name="flatNo"
                        value={address.flatNo}
                        onChange={handelaAdressChange}
                      />
                    </Grid>
                    {/* Area, Street, Sector, Village */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="Area, Street, Sector, Village "
                        placeholder="Area, Street, Sector, Village"
                        variant="outlined"
                        name="cityArea"
                        value={address.cityArea}
                        onChange={handelaAdressChange}
                      />
                    </Grid>
                    {/* District */}
                    {/* <Grid item xs={6}>
              <TextField
              required
                fullWidth
                label="District *"
                placeholder="District"
                variant="outlined"
                name='district'
                value={address.district}
                onChange={handelaAdressChange}
              />
            </Grid> */}

                    {/* Country */}
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          required
                          labelId="country-select-label"
                          id="country-select"
                          label="Country"
                          value={address.country}
                          onChange={handelaAdressChange}
                          name="country"
                          className=" border border-gray-300 rounded-sm w-full"
                          onOpen={handlecountryOpen}
                        >
                          {countries &&
                            countries.map((el) => (
                              <MenuItem
                                key={el.country_id}
                                value={el.country_name}
                              >
                                {el.country_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {/* <TextField
              required
                fullWidth
                label="Country "
                placeholder="Country"
                variant="outlined"
                name='country'
                value={address.country}
                onChange={handelaAdressChange}
              /> */}
                    </Grid>

                    {/* State */}
                    <Grid item xs={6}>
                      {/* <TextField
              required
                fullWidth
                label="State"
                placeholder="State"
                variant="outlined"
                name='state'
                value={address.state}
                onChange={handelaAdressChange}
              /> */}

                      <FormControl fullWidth>
                        <InputLabel>State</InputLabel>
                        <Select
                          label="State"
                          placeholder="State"
                          variant="outlined"
                          name="state"
                          value={address.state}
                          onChange={handelaAdressChange}
                        >
                          {states.length > 0 ? (
                            states.map((state) => (
                              <MenuItem key={state.name} value={state.name}>
                                {state.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No states available</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Pincode */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        label="ZipCode"
                        placeholder="ZipCode"
                        variant="outlined"
                        name="pincode"
                        value={address.pincode}
                        onChange={handelaAdressChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className="p-3 mt-3 bg-white rounded-lg shadow-md">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h4 className="text-xl font-semibold mb-2">
                    Logistics Partner ( Details about the logistics partners
                    involved )
                  </h4>
                </Grid>
                <Grid item xs={6}>
                  <h3 className="text-lg font-medium mb-2">
                    Mobile No.* (Primary)
                  </h3>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    placeholder="Mobile No. (Primary)"
                    name="phone"
                    type="number"
                    value={data.phone}
                    onChange={handelChange}
                    onBlur={() =>
                      validatePhoneNumber(
                        data.phone,
                        selectedIsdCode,
                        "primary"
                      )
                    } // Validate on blur
                    error={!!errorPrimary} // Apply error style if error exists for primary
                    helperText={errorPrimary} // Show error message for primary
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            value={selectedIsdCode}
                            onChange={handleIsdCodeChange}
                            displayEmpty
                            variant="standard"
                            style={{ marginRight: "8px", fontSize: "14px" }}
                          >
                            {isdCodes.map((isd) => (
                              <MenuItem key={isd.code} value={isd.code}>
                                {isd.country}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3 className="text-lg font-medium mb-2">
                    Mobile No.* (Secondary)
                  </h3>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    placeholder="Mobile No. (Secondary)"
                    name="phone2"
                    value={data.phone2}
                    onChange={handelChange}
                    type="number"
                    onBlur={() =>
                      validatePhoneNumber(
                        data.phone2,
                        selectedIsdCode,
                        "secondary"
                      )
                    } // Validate on blur
                    error={!!errorSecondary} // Apply error style if error exists for secondary
                    helperText={errorSecondary} // Show error message for secondary
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3 className="text-lg font-medium mb-2">Email* (Primary)</h3>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    placeholder="Email (Primary)"
                    name="email"
                    value={data.email}
                    onChange={handelChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3 className="text-lg font-medium mb-2">
                    Email* (Secondary)
                  </h3>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    placeholder="Email (Secondary)"
                    name="email2"
                    value={data.email2}
                    onChange={handelChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <div className="flex justify-center mt-3 mb-5">
              <Button
                onClick={() => AddSupplyChain()}
                className="fw-bold"
                style={{
                  background: "#a855f7",
                  padding: "15px",
                  width: "400px",
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                Save Logistics and distribution
              </Button>{" "}
            </div>
          </Box>
        </Grid>
        <Grid style={{ margin: "3px" }} className="mx-3" md={4} spacing={2}>
          <PassportSummary
            title="Passport Summary"
            stepsProgress={stepsProgress}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EndProductStepSeven;
