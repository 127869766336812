import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import QRCode from 'react-qr-code'; 

import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';


import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

import * as htmlToImage from 'html-to-image';
import { Image } from 'antd';


const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};




const ManufacturerDetails = ({manufacturerData}) => {
  const qrRef = useRef();
  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };
    const [key, setKey] = useState('biogenics');
  return (
    <Container maxWidth="lg"   className="scrollable-container " style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
        <Card className="p-10">
      
          <Grid container spacing={2}>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                Manufacturer ID
              </Typography>
              <Typography variant="body1">
               {manufacturerData?.id}
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                Manufacturer's name
              </Typography>
              <Typography variant="body1">
              {manufacturerData?.name}
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography className='p-2' variant="body2" color="textSecondary">
                Manufacturer mobile no.
              </Typography>
              <Typography className='p-2' variant="body1">
                (+91) {manufacturerData?.contactInfo?.phone} (Primary)
              </Typography>
              <Typography className='p-2' variant="body1">
                (+91) {manufacturerData?.contactInfo?.phone2} (Secondary)
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography className='p-2' variant="body2" color="textSecondary">
                Manufacturer E-mail
              </Typography>
              <Typography className='p-2' variant="body1">
              {manufacturerData?.contactInfo?.email} (Primary)
              </Typography>
              <Typography className='p-2' variant="body1">
              {manufacturerData?.contactInfo?.email1}  (Secondary)
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Manufacturer Address
              </Typography>
              <Typography variant="body1">
              {manufacturerData?.address?.flatNo},{manufacturerData?.address?.cityArea},{manufacturerData?.address?.district},{manufacturerData?.address?.state},{manufacturerData?.address?.country}
              </Typography>
            </Grid>
          </Grid>
       
      </Card>
        </Grid>
        <Grid item xs={12} md={4}>
        <Card className=" mb-1">
        <CardContent>
        <Grid  container alignItems="center">
          <Grid  item xs={10}>
            <Typography variant="body1">Edit Passport</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" marginTop={2}>
          <Grid item xs={10}>
            <Typography variant="body1">Download PDF</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="download">
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{height:"100px"}}/>
            </div>
            <div className="flex justify-center ">
            <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px]"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 235,
               height: 35,
             }}
             onClick={downloadQRCode}
            >Download QRCode Button</Button>
            
            </div>
            <div className="flex justify-center ">
            <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            
            </div>
          </Card>
        </Grid>
      </Grid>
      <Card className="p-10 mt-3" style={{marginBottom:"50px"}}>
      <div className='flex justify-between mb-2'>
        <Typography className='fw-bold'>Certifications</Typography>
        </div>
      <Grid container spacing={4}>
      
      {manufacturerData?.certificates?.map((certificate, index) => (
  certificate ? ( // Check if the certificate has a valid value
    <Grid item xs={1} key={index}>
      <Image src={certificate} alt={`Certificate ${index + 1}`} className="w-14 h-14" />
    </Grid>
  ) : null // Don't render anything if the certificate is not a valid image
))}

       </Grid>
        </Card>
    </Container>
  );
};

export default ManufacturerDetails;
