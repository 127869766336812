import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  styled,
  IconButton
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: 'none',
  maxWidth: 600,
  width: '100%',
  maxHeight: '90vh',
  overflowY: 'auto',
}));

const RetailerInfoModal = ({row}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const row = {
  //   "retailerId": 2,
  //   "name": "Modern Homes India",
  //   "saasId": "101",
  //   "logo": "https://onescanprdapi.photonsoftwares.com/prod/api/v1/retailer/get-image-by-id/2",
  //   "address": {
  //       "id": 3,
  //       "flatNo": "8A, Gda Market",
  //       "cityArea": "Indira Colony, Mohan Nagar, Rajendra Nagar",
  //       "country": "India",
  //       "state": "Uttar Pradesh",
  //       "district": null,
  //       "pincode": "201007"
  //   },
  //   "contactInfo": {
  //       "id": 3,
  //       "phone": "9310848080",
  //       "phone2": "1452789658",
  //       "email": "modern@gmail.com",
  //       "email1": "modern@gmail.com"
  //   }
  // };

  return (
    <div>
      <IconButton onClick={handleOpen} aria-label="view">
            <Visibility style={{ color: 'green' }} />
          </IconButton>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        View Retailer Info
      </Button> */}
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="retailer-modal-title"
        aria-describedby="retailer-modal-description"
      >
        <ModalContent>
          <Typography id="retailer-modal-title" variant="h4" component="h2" gutterBottom>
            {row?.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Retailer ID: {row?.retailerId} | SAAS ID: {row?.saasId}
          </Typography>
          <Card sx={{ display: 'flex', mb: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={row?.logo}
              alt={`${row?.name} logo`}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h6" gutterBottom>
                  Address
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {row?.address?.flatNo}<br />
                  {row?.address?.cityArea}<br />
                  {row?.address?.state}, {row?.address?.country}<br />
                  PIN: {row?.address?.pincode}
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Phone:</strong> {row?.contactInfo?.phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Alternate Phone:</strong> {row?.contactInfo?.phone2}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Email:</strong> {row?.contactInfo?.email}
              </Typography>
            </Grid>
            {row?.contactInfo.email1 !== row?.contactInfo?.email && (
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Alternate Email:</strong> {row?.contactInfo?.email1}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </ModalContent>
      </StyledModal>
    </div>
  );
};

export default RetailerInfoModal;