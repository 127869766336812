import axios from 'axios'
import {isDev} from '../Constant/URL.js'
import Cookies from "js-cookie";


export const BASEURL = {
   ENDPOINT_URL: isDev?"https://2fi7otjoy2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/":"https://onescanprdapi.photonsoftwares.com/prod/api/v1/",
}


// export const BASEURL = {
//     ENDPOINT_URL: isDev?"https://2fi7otjoy2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/":"https://onescanprdapi.photonsoftwares.com/prod/api/v1/",
//  }

export const authToken =Cookies.get('authToken')
export default axios.create({
    baseURL: `${BASEURL.ENDPOINT_URL}`,
    headers: {
        'Content-Type': 'application/json',
         "Authorization":`Bearer ${authToken}`
     }
});

