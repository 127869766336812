import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import BasicData from './BasicData';
import Materials from './Materials';
import SupplierDaitas from './SupplierDaitas';
import RetailerInfo from './RetailerInfo';
import TransportInfo from './TransportInfo';
import ManufacturerInfo from './ManufacturerInfo';

function NavigationTabs({
  productData,
  manufacturerInfo,
  supplierInfo,
  retailerInfo,
  transportDetails,
  warehouseDetails,
  materials
}) {
  const tabs = [
    "Product Info",
    "Component Parts & Key Materials",
    "Manufacturer Info",
    "Supplier Info",
    "Retailer Info",
    "Transport Info",
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Render content based on the selected tab
  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <BasicData productData={productData} />;
      case 1:
        return <Materials materials={materials} />;
      case 2:
        return <ManufacturerInfo manufacturerInfo={manufacturerInfo} />;
      case 3:
        return <SupplierDaitas supplierInfo={supplierInfo} />;
      case 4:
        return <RetailerInfo retailerInfo={retailerInfo} />;
      case 5:
        return <TransportInfo   transportDetails={transportDetails}
        warehouseDetails={warehouseDetails} />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Navigation Tabs"
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: 2,
          },
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
          bgcolor: 'background.paper',
          py: 1,
          px: 2,
        }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} />
        ))}
      </Tabs>

      <Box>
        {renderContent()}
      </Box>
    </Box>
  );
}

export default NavigationTabs;
