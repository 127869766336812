import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import Navbar from './Navbar';
import Cookies from "js-cookie";

const Layout = ({ children,handleReset }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isAuthenticated = Cookies.get("authToken");
if (isAuthenticated) {
  return (

    <Box className="flex flex-col h-screen">
      <Header toggleSidebar={toggleSidebar} className="w-full" />
      <Grid container className="flex-grow">
        {isSidebarOpen && (
          <Grid item xs={12} md={2.2}>
            <Box height="100%">
              <Sidebar handleReset={handleReset}/>
            </Box>
          </Grid>
        )}
        <Grid
          // className="bg-gray-200"
          item
          xs={12}
          md={isSidebarOpen ? 9.8 : 12}
          style={{ display: 'flex', flexDirection: 'column', background:"#FAFAFA" }}
        >
          {/* <Navbar className="w-full" /> */}
          <main className="p-1 mt-2 flex-grow" style={{ overflowY: 'auto' }}>
            {children}
          </main>
        </Grid>
      </Grid>
    </Box>
  );
  
}else{

  return children
}
  
 
};

export default Layout;
