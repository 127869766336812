import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function RetailerInfo({ retailerInfo }) {
  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="text.primary" mb={2}>
          Retailer Details
        </Typography>

        {retailerInfo && retailerInfo.length > 0 && (
          retailerInfo.map((retailer, index) => (
            <Box key={index} mb={3}>
              {/* Retailer Name */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Retailer Name:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {retailer.name || 'N/A'}
                </Typography>
              </Box>

              {/* Retailer Address */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Address:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {retailer.address 
                    ? ` ${retailer.address.state}, ${retailer.address.country}, ${retailer.address.pincode}` 
                    : 'N/A'}
                </Typography>
              </Box>

              {/* Retailer Contact Info */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Contact Info:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {retailer.contactInfo 
                    ? `${retailer.contactInfo.phone}, ${retailer.contactInfo.email}` 
                    : 'N/A'}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Paper>
    </Box>
  );
}

export default RetailerInfo;
