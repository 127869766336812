import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function Materials({ materials }) {
  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Materials
        </Typography>
        {materials.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No materials available.
          </Typography>
        ) : (
          materials.map((material, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="body2" color="text.primary">
                    Material Type:
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {material.materialType || 'N/A'}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="body2" color="text.primary">
                    Percentage of Product:
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {material.persentOfProduct || 'N/A'}%
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Paper>
    </Box>
  );
}

export default Materials;
