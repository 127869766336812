import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function SupplierDaitas({ supplierInfo }) {
  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="text.primary" mb={2}>
          Supplier Details
        </Typography>

        {supplierInfo && supplierInfo.length > 0 && (
          supplierInfo.map((supplier, index) => (
            <Box key={index} mb={3}>
              {/* Supplier Name */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Supplier Name:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {supplier.name || 'N/A'}
                </Typography>
              </Box>

              {/* Supplier Address */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Address:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {supplier.address 
                    ? ` ${supplier.address.state}, ${supplier.address.country}, ${supplier.address.pincode}` 
                    : 'N/A'}
                </Typography>
              </Box>

              {/* Supplier Contact Info */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Contact Info:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {supplier.contactInfo 
                    ? `${supplier.contactInfo.phone}, ${supplier.contactInfo.email}` 
                    : 'N/A'}
                </Typography>
              </Box>

              {/* Supplier Sustainability Practices */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body2" color="text.primary">
                  Sustainability Practices:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {supplier.sustainabilityPractices || 'N/A'}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Paper>
    </Box>
  );
}

export default SupplierDaitas;
