// Navbar.js
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../src/assets/oneScan.png'; // Update the path to your logo image

function ProductNavbar() {
  return (
    <AppBar 
    style={{background:"#EBFEF6"}}
  
    sx={{
        py: 1.5,
        backgroundColor: 'background.default',
        boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
      }} position="static" >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="logo">
          <img src={Logo} alt="Logo" style={{ height: 40 }} /> {/* Adjust logo size */}
        </IconButton>
     
      </Toolbar>
    </AppBar>
  );
}

export default ProductNavbar;
