import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton, Box, Tooltip ,Menu, MenuItem } from '@mui/material';
import { FaCloud, FaTint, FaRecycle, FaBox, FaBolt, FaBullseye,FaIndustry  , FaExclamationTriangle, FaTruck, FaCheckCircle, FaClock,FaBiohazard  } from 'react-icons/fa';
import * as htmlToImage from 'html-to-image';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import QRCode from 'react-qr-code'; 
import { Link } from 'react-router-dom';
import UpdateImpactModal from './UpdateSupplyChain.js/UpdateImpactmodal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SpaIcon from '@mui/icons-material/Spa';
import FactoryIcon from '@mui/icons-material/Factory';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Pdf/PdfDocument';
import CarbonFootprintpng from '../../assets/Natural Food.png';  
import WaterUsagepng from '../../assets/Water.png';  
import Recyclabilitypng from '../../assets/Recycle.png';  
import SustainableMaterialspng from '../../assets/Environment Care.png';  
import EnergyEfficiencypng from '../../assets/Flash On.png';  
import EthicalProductionpng from '../../assets/Factory.png';  
import ChemicalSafetypng from '../../assets/Biohazard.png';  
import TransportationImpactpng from '../../assets/Container Truck.png';  
import Certificationspng from '../../assets/Certification.png';  
import Durabilitypng from '../../assets/Time Machine.png';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const questions = [
  "Uses less energy.",
  "Lasts longer.",
  "Can be easily repaired.",
  "Parts can be easily disassembled and put to further use.",
  "Can be easily recycled.",
  "Contains more recycled content.",
  "Has a lower carbon and environmental footprint over its lifecycle.",
];

// Extra information for each metric


const ImpactCard = ({metrial, productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData ,fetchSupplierChainData}) => {
  const [modal, setModal] = useState(false);

  const extraInfo = [
    `Carbon Footprint:
     - Raw Material Emissions: ${impactEntity?.carbonFootprint?.rawMaterialEmissions} kg CO2
     - Production Emissions: ${impactEntity?.carbonFootprint?.productionEmissions} kg CO2
     - Transportation Emissions: ${impactEntity?.carbonFootprint?.transportationEmissions} kg CO2
     - Use Emissions: ${impactEntity?.carbonFootprint?.useEmissions} kg CO2
     - Disposal Emissions: ${impactEntity?.carbonFootprint?.disposalEmissions} kg CO2
     - Total: ${impactEntity?.carbonFootprint?.totalCarbonFootprint} kg CO2`,
  
    `Water Usage:
     - Production: ${impactEntity?.waterUsage?.waterUsedInProduction} liters
     - Packaging: ${impactEntity?.waterUsage?.waterUsedInPackaging} liters
     - Transportation: ${impactEntity?.waterUsage?.waterUsedInTransportation} liters
     - Total: ${impactEntity?.waterUsage?.totalWaterUsage} liters`,
  
    `Recyclability:
     - Recyclable Material Weight: ${impactEntity?.recyclability?.recyclableMaterialWeight} kg
     - Total Material Weight: ${impactEntity?.recyclability?.totalMaterialWeight} kg
     - Total Recyclability: ${impactEntity?.recyclability?.totalRecyclability}%`,
  
    `Sustainable Materials:
     - Sustainable Material Weight: ${impactEntity?.sustainableMaterials?.sustainableMaterialWeight} kg
     - Total Product Weight: ${impactEntity?.sustainableMaterials?.totalProductWeight} kg
     - Total Sustainable: ${impactEntity?.sustainableMaterials?.totalSustainable}%`,
  
    `Energy Efficiency:
     - Output Energy: ${impactEntity?.energyEfficiency?.outputEnergy} kWh
     - Input Energy: ${impactEntity?.energyEfficiency?.inputEnergy} kWh
     - Efficiency Ratio: ${impactEntity?.energyEfficiency?.totalEnergyEfficiency}`,
  
    `Ethical Production:
     - Ethical Practices: ${impactEntity?.ethicalProduction?.ethicalPractices}
     - Total Practices: ${impactEntity?.ethicalProduction?.totalPractices}
     - Total Ethical Production: ${impactEntity?.ethicalProduction?.totalEthicalProduction}%`,
  
    `Chemical Safety:
     - Safe Chemicals: ${impactEntity?.chemicalSafety?.safeChemicals}
     - Total Chemicals Used: ${impactEntity?.chemicalSafety?.totalChemicalsUsed}
     - Total Chemical Safety: ${impactEntity?.chemicalSafety?.totalChemicalSafety}%`,
  
    `Transportation Impact:
     - Distance: ${impactEntity?.transportationImpact?.distance} km
     - Emission Factor: ${impactEntity?.transportationImpact?.emissionFactor} gCO2/km
     - Total Emissions: ${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2`,
  
    `Certifications:
     - Achieved Certifications: ${impactEntity?.certifications?.certifications}
     - Possible Certifications: ${impactEntity?.certifications?.totalPossibleCertifications}
     - Certification Score: ${impactEntity?.certifications?.totalCertificationScore * 100}%`,
  
    `Durability:
     - Expected Lifespan: ${impactEntity?.durability?.expectedLifespan} years
     - Actual Lifespan: ${impactEntity?.durability?.actualLifespan} years
     - Durability Score: ${impactEntity?.durability?.totalDurabilityScore}%`
  ];
  // Define vibrant colors for each icon
  const metrics = [
    { icon: <img src={CarbonFootprintpng} alt="Carbon Footprint"  style={{ width: 30, height: 30 }}  />, label: "Carbon Footprint", value: `${impactEntity?.carbonFootprint?.totalCarbonFootprint} kg CO2` },
    { icon: <img src={WaterUsagepng} alt="Water Usage" style={{ width: 30, height: 30 }} />, label: "Water Usage", value: `${impactEntity?.waterUsage?.totalWaterUsage} L` },
    { icon: <img src={Recyclabilitypng} alt="Recyclability"  style={{ width: 30, height: 30 }}  />, label: "Recyclability", value: `${impactEntity?.recyclability?.totalRecyclability}%` },
    { icon: <img src={SustainableMaterialspng} alt="Sustainable Materials"  style={{ width: 30, height: 30 }}  />, label: "Sustainable Materials", value: `${impactEntity?.sustainableMaterials?.totalSustainable}%` },
    { icon: <img src={EnergyEfficiencypng} alt="Energy Efficiency"  style={{ width: 30, height: 30 }}  />, label: "Energy Efficiency", value: `${impactEntity?.energyEfficiency?.totalEnergyEfficiency}%` },
    { icon: <img src={EthicalProductionpng} alt="Ethical Production"  style={{ width: 30, height: 30 }}  />, label: "Ethical Production", value: `${impactEntity?.ethicalProduction?.totalEthicalProduction}%` },
    { icon: <img src={ChemicalSafetypng} alt="Chemical Safety"  style={{ width: 30, height: 30 }}  />, label: "Chemical Safety", value: `${impactEntity?.chemicalSafety?.totalChemicalSafety}%` },
    { icon: <img src={TransportationImpactpng} alt="Transportation Impact"  style={{ width: 30, height: 30 }}  />, label: "Transportation Impact", value: `${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2` },
    { icon: <img src={Certificationspng} alt="Certifications"  style={{ width: 30, height: 30 }}  />, label: "Certifications", value: `${impactEntity?.certifications?.totalCertificationScore}%` },
    { icon: <img src={Durabilitypng} alt="Durability"  style={{ width: 30, height: 30 }}  />, label: "Durability", value: `${impactEntity?.durability?.totalDurabilityScore}%` },
  ];



const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

const scrollTable = {
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};

const qrRef = useRef();
const downloadQRCode = () => {
  if (qrRef.current) {
    // Temporarily set the QR code's height for the download
    qrRef.current.style.height = '100px';
    qrRef.current.style.width = '100px';

    htmlToImage.toPng(qrRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        handleMenuClose()
      })
      .catch((error) => {
        console.error('Failed to download QR code:', error);
        handleMenuClose()
      })
      .finally(() => {
        // Restore the original size after the download
        qrRef.current.style.height = '30px';
        qrRef.current.style.width = '30px';
        handleMenuClose()
      });
  }
};





// download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};



const [anchorEl, setAnchorEl] = useState(null);

const handleMenuOpen = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleMenuClose = () => {
  setAnchorEl(null);
};

const handleEditAndClose = () => {
  setModal(true)
  handleMenuClose();
};
  return (
    <>
       <div style={containerWrapperStyle}>

<div   className="scrollable-container " style={scrollableContainerStyle}>
  
      {/* Metrics Section */}
      <Card style={{background:"#EBFEF6"}} className="w-full  mx-auto  rounded-lg"
      //  style={{maxHeight:"430px",overflowY:"scroll"}}
       >
      <Box style={{background:"#048a52"}} className="flex justify-between  p-2 text-white">
      <Typography variant="h5" className="fw-bold mt-1 mx-2  ">{productData?.name}</Typography>

      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{color:"white"}} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
     
      >
        <MenuItem style={{ background: "#EBFEF6" }}>
          <Card style={{ background: "#EBFEF6" }} className="mb-1 w-full max-w-3xl mx-auto rounded-lg">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditAndClose}  aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        metrial={metrial}
                        productData={productData}
                        impactEntity={impactEntity}
                        supplyChainEntity={supplyChainEntity}
                        retailerData={retailerData}
                        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}
                      />
                    }
                    fileName="product-data.pdf"
                    style={{ textDecoration: 'none' }}
                  >
                    {({ loading }) => (
                      <IconButton aria-label="download">
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7.5}>
              
                <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap mt-2"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 135,
               height: 25,
             }}
             onClick={downloadQRCode}
            >Download QRCode </Button>
           
                </Grid>
                <Grid item xs={4.5} textAlign="right">
                <QRCode ref={qrRef} value={`https://onescan.digital/productDetails/${productData?.productId}`} size={100}  style={{height:"30px"}}/>
     
            
          
                </Grid>
              </Grid>
            </CardContent>

       
          </Card>
    
        </MenuItem>
      </Menu>
      </Box>
        <Grid container className='p-2 px-4' spacing={4} justifyContent="center">
          {metrics.map((metric, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={6} className="flex flex-col  items-center">
              <div className="bg-gray-200 rounded-full p-2 flex items-center justify-center ">
                {metric.icon}
              </div>
              <div className="flex flex-col px-4">
                <Typography variant="body1" className="text-center font-medium mb-1 fw-bold">
                  {metric.label}
                  <Tooltip   title={
    <Typography variant="body2" style={{ color: 'white', whiteSpace: 'pre-line' }}>
      {extraInfo[index]}
    </Typography>
  }
  arrow>
                          <IconButton size="small" color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                </Typography>
                <Typography variant="body1"   className="text-start fw-bold text-green-600" 
  style={{ fontFamily: 'Poppins, Inter, sans-serif', letterSpacing: '0.5px' }}>
                  {metric.value}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Card>
  
    
   
      </div>
</div>

<UpdateImpactModal fetchSupplierChainData={fetchSupplierChainData} impactEntity={impactEntity} open={modal} onHide={() => setModal(false)} />
    </>
  );
};

export default ImpactCard;


