import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Checkbox, FormControl, FormLabel, Box, Typography, Grid, LinearProgress, Select, InputLabel, Chip, MenuItem } from '@mui/material';
import { FormGroup } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import DataService from '../services/requestApi';
import { useAuth } from '../contexts/AuthConext';
import Swal from 'sweetalert2';
import PassportSummary from './PassportSummary/PassportSummary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress'; 
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Image } from 'antd';
// import ImpactCalculator from '../ImpactCalculatorSutainbility/ImpactCalculator';
const questions = [
  "Uses less energy?",
  "Lasts longer?",
  "Can be easily repaired?",
  "Parts can be easily disassembled and put to further use?",
  "Contains fewer substances of concern?",
  "Can be easily recycled?",
  "Contains more recycled content?",
  "Has a lower carbon and environmental footprint over its lifecycle?"
];





const EndProductStepFour = ({ handleNext,categories,setCategories }) => {
  const { H2Olist,Citrate,energyimpact,Sroi,rawMaterielList,
      GetRawMaterila,fetchCitrateList,fetchgetsetenergyimpactList,fetchgetSroiList,getH2OList,

      Endprogress,step2progress,savestep3Progress,saasId
  } = useAuth(); 

  const [uploadStatus, setUploadStatus] = useState({}); 
  const [loading, setLoading] = useState({});




const handleH2ODropdownOpen = () => {
  getH2OList();
};

const handleSroiDropdownOpen = () => {
  fetchgetSroiList();
};
const handleenergyimpactDropdownOpen = () => {
  fetchgetsetenergyimpactList();
};
const handleCitrateDropdownOpen = () => {
  fetchCitrateList();
};

const handleRawMaterilDropdownOpen = () => {
  GetRawMaterila();
};

  const [SROI, setSROI] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [impactValues, setImpactValues] = useState({
    co2: [],
    rawMaterial: [],
    energy: [],
    h2o: []
  });

  const [imageUrls, setImageUrls] = useState({}); // State to store image URLs
 const [radioValue, setRadioValue] = useState([]); 
// Your existing handleFileChange function
const handleFileChange = async (event, index) => {
  const file = event.target.files[0];
  if (file) {
    // Update selected files state
    setSelectedFiles((prev) => ({ ...prev, [index]: file }));

    // Create a FormData object
    const formData = new FormData();
    formData.append('file', file);

      // Set loading state
      setLoading((prev) => ({ ...prev, [index]: true }));
        // Create a URL for the image preview
        const imageUrl = URL.createObjectURL(file);
        setImageUrls((prev) => ({ ...prev, [index]: imageUrl })); 
    try {
      // Use DataService to send the file
      const response = await DataService.addOCR(formData);
      // Show the response message using SweetAlert
      Swal.fire({
        title: response.data.status ? 'Success' : 'Error',
        text: response.data.message,
        icon: response.data.status ? 'success' : 'error',
        confirmButtonText: 'OK'
      });
         // Update upload status
         if (response.data.status) {
          setUploadStatus((prev) => ({ ...prev, [index]: 'success' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'yes' }));
        } else if (response.data.message === "Certificate is expired.") {
          // If certificate is expired, tick "No"
          setUploadStatus((prev) => ({ ...prev, [index]: 'expired' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
        } else {
          // For other errors, tick "No"
          setUploadStatus((prev) => ({ ...prev, [index]: 'error' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
        }
    } catch (error) {
      // Handle errors
      console.error('Error uploading file', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while uploading the file.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      setUploadStatus((prev) => ({ ...prev, [index]: 'error' }));
      setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
    } finally {
      // Reset loading state
      setLoading((prev) => ({ ...prev, [index]: false }));
    }
  }
};



  
// close select work  


// Manage dropdown open state
const [open, setOpen] = useState(false); // For CO2 Impact
const [open1, setOpen1] = useState(false); // For Raw Material Impact

// Handlers for CO2 dropdown
const handleOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

// Handlers for Raw Material dropdown
const handleOpen1 = () => {
  setOpen1(true);
};
const handleClose1 = () => {
  setOpen1(false);
};

const [openEnergy, setOpenEnergy] = useState(false); // For Energy Impact dropdown
const [openH2O, setOpenH2O] = useState(false); // For H2O Impact dropdown

// Handlers for Energy dropdown
const handleOpenEnergy = () => {
  setOpenEnergy(true);
};
const handleCloseEnergy = () => {
  setOpenEnergy(false);
};

// Handlers for H2O dropdown
const handleOpenH2O = () => {
  setOpenH2O(true);
};
const handleCloseH2O = () => {
  setOpenH2O(false);
};

// Modify handleImpactChange to close dropdowns
const handleImpactChange = (e) => {
  setImpactValues({ ...impactValues, [e.target.name]: e.target.value });

  // Close the respective dropdown after selection
  if (e.target.name === 'co2') {
    setOpen(false);  // Close CO2 dropdown
  } else if (e.target.name === 'rawMaterial') {
    setOpen1(false);  // Close Raw Material dropdown
  } else if (e.target.name === 'energy') {
    setOpenEnergy(false);  // Close Energy dropdown
  } else if (e.target.name === 'h2o') {
    setOpenH2O(false);  // Close H2O dropdown
  }
};

  const handlImpactFileSubmit = async (Impactid) => {
    const formData = new FormData();
    // formData.append('saasId', saasId);

    // formData.append('sroiMinutes', SROI);
    // formData.append('c02', impactValues.co2);
    // formData.append('rawMaterial', impactValues.rawMaterial);
    // formData.append('energy', impactValues.energy);
    // formData.append('h20', impactValues.h2o);
  
    questions.forEach((question, index) => {
      const selectedAnswer = document.querySelector(`input[name="question-${index}-answer"]:checked`);
      if (selectedAnswer) {
        formData.append(question.split(' ').join(''), selectedAnswer.value === "yes");
      } else {
        console.warn(`No answer selected for question ${index + 1}: ${question}`);
      }
  
      if (selectedFiles[index]) {
        formData.append(`${question.split(' ').join('')}Image`, selectedFiles[index]);
      }
    });
  
    try {
      const response = await DataService.uploadImapcat(formData, Impactid); 
      console.log('API response:', response.data);
    //  const impactId = response?.data?.data?.id
    //  localStorage.setItem('impactId', impactId);

      if (response.data.status) {
        await Swal.fire({
          icon: 'success',
          title: 'Impact Added Successfully',
          text: 'The product has been added successfully!',
        });
        
        handleNext(); 
        
        
        }

    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

 

  const scrollableContainerStyle = {
    height: "80vh",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px",
    boxSizing: "border-box",
  };

  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  };


  // 
  const handleChipDelete = (value, type,dropdownType) => {  
     // Close the relevant dropdown based on the dropdownType  
  if (dropdownType === 'co2') {  
    handleClose();  
  } else if (dropdownType === 'rawMaterial') {  
    handleClose1();  
  } else if (dropdownType === 'energy') {  
    handleCloseEnergy();  
  }  
  else if (dropdownType === 'h2o') {  
    handleCloseH2O();  
  } 
    const newValues = impactValues[type].filter((item) => item !== value);  
    setImpactValues((prev) => ({ ...prev, [type]: newValues }));  
  };  







  // const requiredFields = ['sroiMinutes', 'co2', 'rawMaterial', 'energy', 'h2o'];


  // const calculateProgress = () => {
  //   const totalFields = requiredFields.length + 1; 
  //   let filledFields = 0;
  

  //   if (SROI && SROI.length > 0) filledFields++;
  

  //   requiredFields.forEach((field) => {
  //     const fieldValue = impactValues[field];
  //     if (Array.isArray(fieldValue)) {

  //       if (fieldValue.length > 0) filledFields++;
  //     } else {
  
  //       if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "") {
  //         filledFields++;
  //       }
  //     }
  //   });
  

  //   if (Object.keys(selectedFiles).length > 0 && Object.values(selectedFiles).some(file => file)) {
  //     filledFields++;
  //   }
  
  //   return (filledFields / totalFields) * 100;
  // };
  
  // const progress = calculateProgress();
  
  // useEffect(() => {
  //   const progress = calculateProgress();
  //   savestep3Progress(progress); 
  // }, [impactValues, selectedFiles]);
  
  




  const toggleCategory = (index) => {
    const newCategories = [...categories];
    newCategories[index].isOpen = !newCategories[index].isOpen;
    setCategories(newCategories);
  };

  const handleInputChange = (categoryIndex, metricIndex, value) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].metrics[metricIndex].value = value;
    setCategories(newCategories);
  };



  const handleSubmit = async () => {
    const productId = localStorage.getItem("productId")
    if (!productId ) {
      Swal.fire({
        title: 'Error',
        text: 'Product  ID is missing',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    const impactData = {
      carbonFootprint: {
        rawMaterialEmissions: parseFloat(categories[0].metrics[0].value) || 0,
        productionEmissions: parseFloat(categories[0].metrics[1].value) || 0,
        transportationEmissions: parseFloat(categories[0].metrics[2].value) || 0,
        useEmissions: parseFloat(categories[0].metrics[3].value) || 0,
        disposalEmissions: parseFloat(categories[0].metrics[4].value) || 0,
      },
      waterUsage: {
        waterUsedInProduction: parseFloat(categories[1].metrics[0].value) || 0,
        waterUsedInPackaging: parseFloat(categories[1].metrics[1].value) || 0,
        waterUsedInTransportation: parseFloat(categories[1].metrics[2].value) || 0,
      },
      recyclability: {
        recyclableMaterialWeight: parseFloat(categories[2].metrics[0].value) || 0,
        totalMaterialWeight: parseFloat(categories[2].metrics[1].value) || 0,
      },
      sustainableMaterials: {
        sustainableMaterialWeight: parseFloat(categories[3].metrics[0].value) || 0,
        totalProductWeight: parseFloat(categories[3].metrics[1].value) || 0,
      },
      energyEfficiency: {
        outputEnergy: parseFloat(categories[4].metrics[0].value) || 0,
        inputEnergy: parseFloat(categories[4].metrics[1].value) || 0,
      },
      ethicalProduction: {
        ethicalPractices: parseFloat(categories[5].metrics[0].value) || 0,
        totalPractices: parseFloat(categories[5].metrics[1].value) || 0,
      },
      chemicalSafety: {
        safeChemicals: parseFloat(categories[6].metrics[0].value) || 0,
        totalChemicalsUsed: parseFloat(categories[6].metrics[1].value) || 0,
      },
      transportationImpact: {
        distance: parseFloat(categories[7].metrics[0].value) || 0,
        emissionFactor: parseFloat(categories[7].metrics[1].value) || 0,
      },
      certifications: {
        certifications: parseFloat(categories[8].metrics[0].value) || 0,
        totalPossibleCertifications: parseFloat(categories[8].metrics[1].value) || 0,
      },
      durability: {
        expectedLifespan: parseFloat(categories[9].metrics[0].value) || 0,
        actualLifespan: parseFloat(categories[9].metrics[1].value) || 0,
      }
    };
   
    try {
      const response = await DataService.createImpcat(saasId,productId, impactData);
       const Impactid =response.data.data.id
       if (response.data.status){
        localStorage.setItem('impactId', Impactid);
         
         handlImpactFileSubmit(Impactid)
     

       }
       

      console.log("Impact Data Submitted:", response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.log(error)
    
    }
  };
  
  

  const calculateProgress = () => {
    const totalCategories = categories.length;
    let filledCategories = 0;
  
    categories.forEach((category) => {
      const allMetricsFilled = category.metrics.every(metric => metric.value !== "" && metric.value !== null && metric.value !== undefined);
      if (allMetricsFilled) filledCategories++;
    });
  
    // Check if files are uploaded
    const filesUploaded = Object.keys(selectedFiles).length > 0 && Object.values(selectedFiles).some(file => file);
    if (filesUploaded) filledCategories++; // Add to filled categories if files are uploaded
  
    const totalFields = totalCategories + 1; // Total categories + 1 for file check
    return (filledCategories / totalFields) * 100;
  };
  
  const progress = calculateProgress();
  
  useEffect(() => {
    const progress = calculateProgress();
    savestep3Progress(progress); // Save calculated progress
  }, [categories, selectedFiles]);
  
  const stepsProgress = [
    { name: 'Basic Information', stepNumber: 1, totalSteps: 6, progress: Endprogress },
    { name: 'Materials', stepNumber: 2, totalSteps: 6, progress: step2progress },
    { name: 'Impact', stepNumber: 3, totalSteps: 6, progress: progress }, // Example percentage for Materials

  ];
  return (
    <div style={containerWrapperStyle}>
      <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
        <Grid item xs={12} md={7}>
        <Box className=" mx-auto p-4 space-y-4 bg-white rounded-lg shadow">
            <Typography style={{ color: "GrayText" }} variant="h6" component="h6" mb={2} gutterBottom>Step 3/7</Typography>
            <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">Impact</Typography>
            <FormLabel component="legend">The impact of your products can be calculated by the system, or you can fill in your own impact numbers.</FormLabel>

           
      {categories.map((category, categoryIndex) => (
        <Box key={category.name} className="border rounded-lg overflow-hidden">
          <button
            className="flex justify-between items-center w-full px-4 py-3 text-left font-medium text-gray-900 bg-gray-50"
            onClick={() => toggleCategory(categoryIndex)}
            aria-expanded={category.isOpen}
            aria-controls={`category-${categoryIndex}-content`}
          >
            {category.name}
            {category.isOpen ? <FaChevronUp className="h-5 w-5" /> : <FaChevronDown className="h-5 w-5" />}
          </button>
          {category.isOpen && (
            <Box id={`category-${categoryIndex}-content`} className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {category.metrics.map((metric, metricIndex) => (
                <Box key={metric.label} className="space-y-2">
                  <FormLabel htmlFor={`${category.name}-${metric.label}`} className="text-sm font-medium text-gray-700">
                    {metric.label}
                  </FormLabel>
                  <Box className="flex items-center space-x-2">
                    <TextField
                    
                      id={`${category.name}-${metric.label}`}
                      variant="outlined"
                      placeholder="Enter Value"
                      type="number"
                      value={metric.value}
                      onChange={(e) => handleInputChange(categoryIndex, metricIndex, e.target.value)}
                      className="flex-grow"
                      InputProps={{
                        endAdornment: (
                          <span className="text-sm text-gray-500">{metric.unit}</span>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}

       <div className="p-6 mt-2 bg-white rounded-md shadow-md">
                <Typography variant="h6" component="div" className="mb-4">
                Proof of Sustainability :
                </Typography>
                {questions.map((question, index) => (
                  <FormGroup key={index} className="mb-4">
                    <Typography variant="body1" className="mb-2">
                      • {question}  ( jpg and png only )
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                      <Grid item xs={12} md={7}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<CloudUpload />}
                          component="label"
                          className="text-black mb-2 rounded"
                          style={{ background: "#D9D9D9" }}
                        >
                          Upload Certificate Image
                          <TextField
                     type="file"
                  hidden
                  onChange={(event) => handleFileChange(event, index)}
                  inputProps={{
                    accept: "image/*", // Allow only image files
                  }}
                          />
                        </Button>
                        {loading[index] ? (
                <div className="flex items-center mt-2">
                  <CircularProgress size={24} style={{ marginRight: '8px' }} />
                  <Typography variant="body2">Uploading...</Typography>
                </div>
              ) : (
                selectedFiles[index] && (
                  <Typography variant="body2" className="mt-2 text-wrap  flex items-center">
                    {uploadStatus[index] === 'success' && (
                      <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                    )}
                    Selected File: {selectedFiles[index].name}
                  </Typography>
                )
              )}
</Grid>
<Grid className='flex justify-end' item xs={12} md={5}>
 {imageUrls[index] && (
                <Image
                  width={70} // Adjust width as needed
                  src={imageUrls[index]}
                  alt="Preview"
                  className="mt-2"
                />
              )}
              </Grid>
              </Grid>
                      </Grid>

                      {/* <Grid item xs={12} md={2}>

    {uploadStatus[index] === 'success' && (
    <Typography  variant="body2" color="green" className="mt-2 fw-bold">
      Certificate is valid
    </Typography>
  )}
  {uploadStatus[index] === 'expired' && (
    <Typography variant="body2" color="red" className="mt-2 fw-bold">
      Certificate is expired
    </Typography>
  )}
</Grid> */}


                    </Grid>
                  </FormGroup>
                ))}
              </div>
 
            <div className='flex justify-center mt-3 mb-5'>
              <Button onClick={handleSubmit} className='fw-bold'  style={{ background: "#a855f7", padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
                Step 4: Unified Supplier Manufacturer Entity
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid style={{ margin: "3px" }}  className='mx-3' md={4} spacing={2} >
        <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

       {/* <ImpactCalculator /> */}
          </Grid>
    
      </Grid>
    </div>
  );
};

export default EndProductStepFour;
