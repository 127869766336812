import React, { useState } from "react";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import DataService from '../services/requestApi';
import { Delete } from "@mui/icons-material";


const ApiPermissionPage = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState([]); // State for storing API endpoints

  const [formData, setFormData] = useState({
    role: "",
    httpMethod: "",
    apiEndPoint: ""
  });

  const [endpoints, setEndpoints] = useState([]); // State for storing API endpoints

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // If HTTP method is selected, fetch corresponding API endpoints
    if (name === "httpMethod") {
      fetchApiEndpoints(value); // Call the function to get the endpoints
    }

    if (name === "role") {
      // Call API to fetch data based on selected role
      fetchApiDataByRole(value);
    }
  };

  // Fetch API endpoints based on HTTP method
  const fetchApiEndpoints = async (method) => {
    try {
      const response = await DataService.searchapiendpointByName(method);
      if (response.data.status) {
        setEndpoints(response.data.data); // Set endpoints from the response
      }
    } catch (error) {
      console.error("Error fetching API endpoints:", error);
      Swal.fire({
        title: "Error Fetching API Endpoints",
        icon: "error",
        text: error.message
      });
    }
  };


  const fetchApiDataByRole = async (role) => {
    try {
      const response = await DataService.searchapiendpointByRole(role);
      if (response.data.status) {
        setdata(response.data.data); // Set endpoints from the response
      }
    } catch (error) {
      console.error("Error fetching API endpoints:", error);
      Swal.fire({
        title: "Error Fetching API Endpoints",
        icon: "error",
        text: error.message
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const payload  = {
        apiEndPoint: formData.apiEndPoint,
        httpMethod: formData.httpMethod,
        role: formData.role
      };
      // Handle success (e.g., show success message)
      const response = await DataService.accessapiendpoint(payload);
      console.log("API Permission data:", response );

      Swal.fire({
        title: "API Permission Created Successfully",
        icon: "success",
        timer: 2000
      });

    } catch (error) {
      Swal.fire({
        title: "Error Creating API Permission",
        icon: "error",
        text: error.message
      });
      console.error("Error creating API permission:", error);
    }
  };





  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await DataService.deleteapiendpointById(id);
        Swal.fire('Deleted!', 'apiendpoint has been deleted.', 'success');
     
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete apiendpoint', 'error');
    }
  };


  // Sample data for DataTable
  const columns = [
    { name: "Role", selector: row => row.role },
    { name: "HTTP Method", selector: row => row.httpMethod },
    { name: "API Endpoint", selector: row => row.name },
    {
      name: 'Actions',
      cell: (row) => (
        <>
     
           <IconButton aria-label="delete" >
            <Delete style={{ color: '#C81D20' }}   onClick={() => handleDelete(row.id)} />
          </IconButton>
  
        </>
      ),
      width: '250px', 
    },
  ];

  // const data = [
  //   { id: 1, role: "ADMIN", httpMethod: "GET", apiEndPoint: "/api/v1/user" },
  //   { id: 2, role: "USER", httpMethod: "POST", apiEndPoint: "/api/v1/api-access-rule/add" },
  //   { id: 3, role: "SUPPLIER", httpMethod: "PUT", apiEndPoint: "/api/v1/api-access-rule/edit" },
  // ];

  return (
    <Box p={3}>


      {/* Card with Shadow */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h5" gutterBottom>
        API Access 
      </Typography>
        <Grid container className="mt-3" spacing={2}>
          {/* Role Select Menu */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                variant="outlined"
              >
                <MenuItem value="USER">USER</MenuItem>
                <MenuItem value="SUPPLIER">SUPPLIER</MenuItem>
                <MenuItem value="RETAILER">RETAILER</MenuItem>
                <MenuItem value="OPS_TEAM">OPS TEAM</MenuItem>
                <MenuItem value="OPS_MANAGER">OPS MANAGER</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* HTTP Method Select Menu */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Method</InputLabel>
              <Select
                label="Method"
                name="httpMethod"
                value={formData.httpMethod}
                onChange={handleInputChange}
                variant="outlined"
              >
                {/* <MenuItem value="GET">GET</MenuItem> */}
                <MenuItem value="POST">ADD</MenuItem>
                <MenuItem value="PUT">UPDATE</MenuItem>
                <MenuItem value="DELETE">DELETE</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* API Endpoint Select Menu */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>API Endpoint</InputLabel>
              <Select
                label="API Endpoint"
                name="apiEndPoint"
                value={formData.apiEndPoint}
                onChange={handleInputChange}
                variant="outlined"
              >
                {/* Dynamically populate the endpoints */}
                {endpoints.map((endpoint) => (
                  <MenuItem key={endpoint.id} value={endpoint.apiEndPoint}>
                    {endpoint.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <Button className="mt-2" color="primary" onClick={handleSubmit} variant="contained" sx={{ mr: 1 }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
      {/* DataTable for Static Entries */}
      <Typography variant="h6" gutterBottom>
        API Permission Entries
      </Typography>
      <DataTable
        columns={columns}
        data={data}
        pagination
      />
    </Box>

    </Box>
  );
};

export default ApiPermissionPage;
