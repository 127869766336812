import React, { useEffect, useState } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, FormLabel, Box, Typography, Grid, LinearProgress, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddManufactureModal from './Modal/AddManufactureModal';
import DataService from '../services/requestApi';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Image } from 'antd';
import { useAuth } from '../contexts/AuthConext';
import PassportSummary from './PassportSummary/PassportSummary';

const VisuallyHiddenInput = styled('input')({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0,0,0,0)',
  border: '0',
});

const EndProductStepFive = ({ handleNext }) => {
const {Endprogress,step2progress,step3progress, saasId,  savestep4Progress
} = useAuth(); 
  
  const [formData, setFormData] = useState({
    saasId:saasId,
    manufacturerId: '',
    name: '',
    flatNo: '',
    cityArea: '',
    country: '',
    state: '',
    district: '',
    pincode: '',
    phone: '',
    phone2: '',
    email: '',
    email1: '',
    certifications: []
  });
  // Required fields list
  const requiredFields = [
    'name', 'flatNo', 'cityArea', 
    'phone', 'phone2',

  ];

  // Calculate progress based on filled fields
  const calculateProgress = () => {
    const totalFields = requiredFields.length;
    const filledFields = requiredFields.filter((field) => {
    const fieldValue = formData[field];
      // Check if the field is filled for single value fields, or has items for arrays
      return Array.isArray(fieldValue) ? fieldValue.length > 0 : fieldValue !== "";
    }).length;

    return (filledFields / totalFields) * 100;
  };

  const progress = calculateProgress();

  useEffect(() => {
    const progress = calculateProgress();
    savestep4Progress(progress); // Save progress in context whenever it changes
  }, [formData]);

  const [manufacturers, setManufacturers] = useState([]);
  const [isAutoFill, setIsAutoFill] = useState(true);
 

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await DataService.getManufacturers(saasId);
        setManufacturers(response.data.data);
      } catch (error) {
        console.error('Failed to fetch manufacturers', error);
      }
    };
    fetchManufacturers();
  }, []);

  const handleManufacturerNameChange = (e) => {
    const manufacturerName = e.target.value;
    const selectedManufacturer = manufacturers.find((m) => m.name === manufacturerName);

    if (selectedManufacturer) {
      setFormData({
        ...formData,
        manufacturerId: selectedManufacturer.id,
        name: selectedManufacturer.name,
        flatNo: selectedManufacturer.address.flatNo,
        cityArea: selectedManufacturer.address.cityArea,
        country: selectedManufacturer.address.country,
        state: selectedManufacturer.address.state,
        district: selectedManufacturer.address.district,
        pincode: selectedManufacturer.address.pincode,
        phone: selectedManufacturer.contactInfo.phone,
        phone2: selectedManufacturer.contactInfo.phone2,
        email: selectedManufacturer.contactInfo.email,
        email1: selectedManufacturer.contactInfo.email1,
        certificate1: selectedManufacturer.certificate1,
        certificate2: selectedManufacturer.certificate2,
        certificate3: selectedManufacturer.certificate3,

        certificate4: selectedManufacturer.certificate4,
        certificate5: selectedManufacturer.certificate5,


      });
    } else {
      setFormData({ ...formData, name: manufacturerName });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };



  const handlelinkmanufactureProduct = async () => {
    try {
      const productId = localStorage.getItem('productId');
      const { manufacturerId } = formData;

      if (!productId || !manufacturerId) {
        Swal.fire({
          title: 'Error',
          text: 'Product ID or Manufacturer ID is missing',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const { phone, phone2 } = formData;

      // Check if 'phone' is numeric
      if (!/^\d+$/.test(phone)) {
        Swal.fire({
          title: 'Error',
          text: 'Phone number must be numeric',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone' is exactly 10 digits
      if (phone.length !== 10) {
        Swal.fire({
          title: 'Error',
          text: 'Phone number must be exactly 10 digits',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone2' is numeric
      if (!/^\d+$/.test(phone2)) {
        Swal.fire({
          title: 'Error',
          text: 'Alternate phone number must be numeric',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone2' is exactly 10 digits
      if (phone2.length !== 10) {
        Swal.fire({
          title: 'Error',
          text: 'Alternate phone number must be exactly 10 digits',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const response = await DataService.linkManufactureProduct( saasId, manufacturerId, productId);

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Product linked to Manufacturer successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          handleNext();
          localStorage.setItem("Maunf_id",manufacturerId)
        });
      }
    } catch (error) {
      console.error("API call failed", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to link manufacturer product',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

// progress bar
const stepsProgress = [
  { name: 'Basic Information', stepNumber: 1, totalSteps: 7, progress: Endprogress },
  { name: 'Materials', stepNumber: 2, totalSteps: 7, progress: step2progress },
  { name: 'Impact', stepNumber: 3, totalSteps: 7, progress: step3progress }, // Example percentage for Materials
  { name: 'Manufacturer', stepNumber: 4, totalSteps: 7, progress: progress }, // Example percentage for Materials


];
  return (
    <>
      <div style={containerWrapperStyle}>
      <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
        <Grid item xs={12} md={7}>
          <Box >
            <Typography variant="h6" style={{ color: "GrayText" }} mb={2}>Step 4/7</Typography>
            <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">
              Manufacturer Entity
            </Typography>
            <FormLabel component="legend">Fill in basic manufacturer information.</FormLabel>
            <Box className="p-3 bg-white rounded-lg shadow-md">
              <Typography variant="h6" className="mb-3">Manufacturer Name</Typography>
              <InputGroup className="mb-4">
      <Input
        type="text"
        className='p-3'
        placeholder="Search Manufacturer Name"
        list="manufacturers"
        name="name"
        value={formData.name}
        onChange={handleManufacturerNameChange}
      />
      <InputGroupText>
        <FaSearch />
      </InputGroupText>
      <datalist id="manufacturers">
        {manufacturers.map((manufacturer) => (
          <option key={manufacturer.id} value={manufacturer.name} />
        ))}
      </datalist>
    </InputGroup>


              {/* <Typography variant="h6" className="mb-3">Select Manufacturer Location</Typography>
              <RadioGroup row value={isAutoFill ? "auto" : "manual"} onChange={(e) => setIsAutoFill(e.target.value === "auto")}>
                <FormControlLabel value="auto" control={<Radio />} label="Auto fill address" />
                <FormControlLabel value="manual" control={<Radio />} label="Manual address fill form" />
              </RadioGroup> */}

              {/* {isAutoFill ? (
                <Typography variant="h6" className="mb-3">Geographical Location</Typography>
              ) : (
                <> */}
                  <Typography variant="h6" className="mb-3">Manual Address</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Address"
                        variant="outlined"
                        className="mb-4"
                        name="flatNo"
                        value={formData.flatNo}
                        onChange={handleInputChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Area, Street, Sector, Village"
                        variant="outlined"
                        className="mb-4"
                        name="cityArea"
                        value={formData.cityArea}
                        onChange={handleInputChange}
                        disabled
                      />
                    </Grid>
                    {/* Add more fields here */}
                  </Grid>
                {/* </>
              )} */}

              <Typography variant="h6" className="mb-3">Contact Info</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Mobile No. (Primary)"
                    variant="outlined"
                    className="mb-4"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Mobile No. (Secondary)"
                    variant="outlined"
                    className="mb-4"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleInputChange}
                    disabled
                  />
                </Grid>
                {/* Add more fields here */}
              </Grid>
            </Box>
            <Box className="p-3 mt-3 bg-white rounded-lg shadow-md">
              <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">
                Certifications
              </Typography>
              <FormLabel component="legend">List of certifications held by the manufacturer</FormLabel>
              <Grid container spacing={2}>
             
              <Grid className='' item xs={6} sm={4} md={2}>
  {formData?.certificate1 && (
    <Image src={formData.certificate1} alt="label" className="w-14 h-14" />
  )}
</Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate2 && (
    <Image src={formData.certificate2} alt="label" className="w-14 h-14" />
  )}
  </Grid>
  <Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate3 && (
    <Image src={formData.certificate3} alt="label" className="w-14 h-14" />
  )} </Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate4 && (
    <Image src={formData.certificate4} alt="label" className="w-14 h-14" />
  )} </Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate5 && (
    <Image src={formData.certificate5} alt="label" className="w-14 h-14" />
  )}
</Grid>
         
              </Grid>
            </Box>
            <div className='flex justify-center mt-3 mb-5'>
              <Button
                onClick={handlelinkmanufactureProduct}
                style={{ background: "#4F46E5", padding: "15px", width: "400px" }}
                variant="contained"
                color="primary"
                fullWidth
              >
                Step 5: Supplier Entity
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid style={{ margin: "3px" }} className='mx-3' md={4} spacing={2} >
       

<PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

          </Grid>
 
      </Grid>

      </div>

    </>
  );
};

export default EndProductStepFive;
