import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Brand from './BrandMaster/Brand';
import Uom from './UomMaster/Uom';
import WeightUnitMaster from './WeightUnitMaster/WeightUnitMaster';
import PLM from './PLM/PLM';
import Alltransport from '../Supplychainmater/Transportroute/Alltransport';
import AllSROI from '../ImpactData/SROI/AllSROI';
import AllCo2 from '../ImpactData/CO2/AllCo2';
import AllRawmaterial from '../ImpactData/Rawmaterial/AllRawmaterial';
import Allenergyimpact from '../ImpactData/EnergyImpact/Allenergyimpact';
import AllH2OImpact from '../ImpactData/H2OImpact/AllH2OImpact';
import AllMsds from '../Supplychainmater/MSDS/AllMsds';
import AllFUPS from '../ImpactData/FUPS/AllFUPS';
import AllProductUsePeriod from '../Supplychainmater/ProductPeriod/AllProductUsePeriod';
import AllWarhouseOwner from './Warhouse/AllWarhouseOwner';
import AllWarehouseName from './WarehouseName/AllWarehouseName';
import GetTransPortMethod from '../Supplychainmater/TransPortmethod/GetTransPortMethod';
import AllDominant from '../Supplychainmater/Dominant/AllDominant';
import { useAuth } from '../../contexts/AuthConext';
import GetAllSupplierType from './SupplierType/GetAllSupplierType';
import GetAllSupplierExpertise from './SupplierExpertise/GetAllSupplierExpertise';



const Datamanagement = () => {
  const { getAllTransport ,fetchBrands,fetchgetSroiList,fetchExpertiseList} = useAuth();


  const [currentPage, setCurrentPage] = useState('1');
  const [Activetab, setActivetab] = useState('');

  // Handle page change for pagination
  const handlePageChange = (page) => {
    console.log("this pagination page", page);
    setCurrentPage(page);
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        {/* <Link to={'/'}> <IoArrowBack className='mt-2 fs-3'/> </Link>  */}
      </div>
      <Tabs
        defaultActiveKey="Brand"
        id="uncontrolled-tab-example"
        className="mb-3 fw-bold"
        fill
        style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
        onSelect={(key) => {
          setActivetab(key);
          // Trigger the API call only when this specific tab is selected
          if (key === "Product End-of-Life Management") {
            getAllTransport();
          }
          if (key === "Brand") {
            fetchBrands();
          }
          if (key === "SROI Impact") {
            fetchgetSroiList();
          }
        }}
      >
        <Tab eventKey="Brand" title="Master Data">
          <Tabs
            defaultActiveKey="Brand"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key);
              // Handle tab changes within Product Management
            }}
          >
            <Tab eventKey="Brand" title="Brand">
              <Brand tab={Activetab}/>
            </Tab>
            <Tab eventKey="Unit of Measure" title="Unit of Measure">
                <WeightUnitMaster tab={Activetab} />
            </Tab>
            <Tab eventKey="Quantity Unit" title="Quantity Unit">
            <Uom tab={Activetab} />

            </Tab>
            <Tab eventKey="Product End-of-Life Management" title="PLM">
            <PLM tab={Activetab} />
               </Tab>
               <Tab eventKey="Warehouse Owner" title="Warehouse Owner">
            <AllWarhouseOwner tab={Activetab} />
               </Tab>

               <Tab eventKey="Warehouse Name" title="Warehouse Name">
            <AllWarehouseName tab={Activetab}/>
               </Tab>

          </Tabs>
        </Tab>
        <Tab eventKey="SROI Impact" title="Sustainability Impact Data">
        <Tabs
            defaultActiveKey="SROI Impact"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key)
              // Handle tab changes within Product Management
            }}
          >
            <Tab eventKey="SROI Impact" title="SROI Impact">
           <AllSROI tab={Activetab}/>
            </Tab>
            <Tab eventKey="CO₂ Impact" title="CO₂ Impact">
            <AllCo2 tab={Activetab}/>
            </Tab>
            <Tab eventKey="Raw Material Impact" title="Raw Material Impact">
            <AllRawmaterial tab={Activetab}/>
            </Tab>
            <Tab eventKey="Energy Impact" title="Energy Impact">
            <Allenergyimpact tab={Activetab}/>
            </Tab>
            <Tab eventKey="H₂O Impact" title="H₂O Impact">
             <AllH2OImpact tab={Activetab}/>
            </Tab>
            <Tab  eventKey="Future Utilization" title="FUO">
             <AllFUPS tab={Activetab}/>
            </Tab>
          </Tabs>
        </Tab>
        <Tab eventKey="Transport Route" title="Product Lifecycle Data"
        >
        <Tabs
            defaultActiveKey="Transport Route"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key);
              // Trigger the API call only when this specific tab is selected
       
            }}
          >
            <Tab eventKey="Transport Route" title="Transport Route">
             <Alltransport tab={Activetab} />
            </Tab>
            <Tab eventKey="Transport Method" title="Transport Method">
              <GetTransPortMethod tab={Activetab} />

            </Tab>
            <Tab eventKey="MSDS" title="MSDS">
              <AllMsds tab={Activetab}/>
            </Tab>
            <Tab eventKey="Product Usage Period" title="Product Usage Period">
              <AllProductUsePeriod tab={Activetab} />
            </Tab>

            <Tab eventKey="Dominant" title="Dominant">
              <AllDominant tab={Activetab} />
            </Tab>
          </Tabs>
        </Tab>
 

        <Tab eventKey="Supplier Type" title="Supplier Data"

        >
        <Tabs
            defaultActiveKey="Supplier Type"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key);
              // Trigger the API call only when this specific tab is selected
            
              if (key === "Supplier Expertise") {
                fetchExpertiseList();
              }
       
            }}
          >
            <Tab eventKey="Supplier Type" title="Supplier Type">
             <GetAllSupplierType tab={Activetab} />
            </Tab>
            <Tab eventKey="Supplier Expertise" title="Supplier Expertise">
              <GetAllSupplierExpertise tab={Activetab} />

            </Tab>
  
   
          </Tabs>
        </Tab>
      </Tabs>
    </>
  )
}

export default Datamanagement;
