// EditProductModal.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Chip,
  OutlinedInput,
  Checkbox
} from "@mui/material";
import Swal from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAuth } from '../../contexts/AuthConext';
import DataService from '../../services/requestApi';
import { Image } from 'antd';

const EditProductModal = ({ modalOpen, setModalOpen, productData,fetchSupplierChainData}) => {
  const { brands,wumList ,PLM,MSDS,FUPS,Productuselist,
    fetchBrands,fetchgetUomList,fetchgetPlmList,fetchMSDSList,fetchFUPSList,GetProductUserPeriod,
    categoryList,
    fetchCategoryList,
    unitMessure,
    fetchgetUnitOfmessureList,
    saveProgress ,saasId
} = useAuth(); 

  // basic info state

  
// Basic info state
const [formValues, setFormValues] = useState({
  name: productData?.name || "",
  description: productData?.description || "",
  weight: productData?.basicInfo?.weight || "",
  weightUnit: productData?.basicInfo?.weightUnit || "",
  quantity: productData?.basicInfo?.quantity || "",
  quantityUnit: productData?.basicInfo?.quantityUnit || "",
  length: productData?.basicInfo?.length || "",
  width: productData?.basicInfo?.width || "",
  height: productData?.basicInfo?.height || "",
  usePeriod: productData?.basicInfo?.usePeriod || "",
  msds: productData?.basicInfo?.msds || [],
  nextUseApplication: productData?.basicInfo?.useApplication || [],
  endOfUseTreatment: productData?.basicInfo?.usedTreatment || [],
  code: productData?.code || "",
  serialNumber: productData?.serialNumber || "",
  hsCode: productData?.hsCode || "",
  eanNumber: productData?.eanNumber || "",
  category: productData?.category || "",
  brand: productData?.brand || "",
  manufacturingDate: productData?.manufacturingDate || "",
  expirationDate: productData?.expirationDate || ""
});

useEffect(() => {
  if (productData) {
    setFormValues({
      name: productData?.name || "",
      description: productData?.description || "",
      weight: productData?.basicInfo?.weight || "",
      weightUnit: productData?.basicInfo?.weightUnit || "",
      quantity: productData?.basicInfo?.quantity || "",
      quantityUnit: productData?.basicInfo?.quantityUnit || "",
      length: productData?.basicInfo?.length || "",
      width: productData?.basicInfo?.width || "",
      height: productData?.basicInfo?.height || "",
      usePeriod: productData?.basicInfo?.usePeriod || "",
      msds: productData?.basicInfo?.msds || [],
      nextUseApplication: productData?.basicInfo?.useApplication || [],
      endOfUseTreatment: productData?.basicInfo?.usedTreatment || [],
      code: productData?.code || "",
      serialNumber: productData?.serialNumber || "",
      hsCode: productData?.hsCode || "",
      eanNumber: productData?.eanNumber || "",
      category: productData?.category || "",
      brand: productData?.brand || "",
      manufacturingDate: productData?.manufacturingDate || "",
      expirationDate: productData?.expirationDate || ""
    });
  } else {
    setFormValues('');
  }

  fetchMSDSList()
  fetchFUPSList()
  fetchgetPlmList()
}, [productData]);

const handleDropdownOpen = () => {
fetchBrands();
};

const handleUnitmessureDropdownOpen = () => {
fetchgetUnitOfmessureList();
};
const handleDropCategorydownOpen = () => {
fetchCategoryList()

};
// Handle dropdown open event for UOM list
const handleUomDropdownOpen = () => {
fetchgetUomList();
};

// Handle dropdown open event for PLM list
const handlePlmDropdownOpen = () => {
fetchgetPlmList();
};

// Handle dropdown open event for MSDS list
const handleMsdsDropdownOpen = () => {
fetchMSDSList();
};

// Handle dropdown open event for FUPS list
const handleFupsDropdownOpen = () => {
fetchFUPSList();
};
const handleProductUserPeriodDropdownOpen = () => {
GetProductUserPeriod();
};

const [weightOption, setWeightOption] = useState("enterWeight");
const [selectedFile, setSelectedFile] = useState(null);
const [filePreview, setFilePreview] = useState(null);

const handleWeightOptionChange = (event) => {
  setWeightOption(event.target.value);
};
const handleChange = async (event) => {
  const { name, value } = event.target;

  // Check if the changed field is 'manufacturingDate'
  if (name === 'manufacturingDate') {
    const today = new Date();
    const newManufacturingDate = new Date(value);

    // If the new date is in the past, show Swal alert
    if (newManufacturingDate < today) {
      const { value: shouldUsePreviousDate } = await Swal.fire({
        title: 'Manufacturing Date is in the past',
        text: 'Do you want to use today\'s date instead?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (shouldUsePreviousDate) {
        // Set manufacturing date to today if the user confirms
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: today.toISOString().split('T')[0], // Set it to today’s date
        }));
        return; // Exit to prevent further processing
      } else {
        // If user chooses not to proceed, just set the value
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        return; // Exit to prevent further processing
      }
    }
  }

  // For other fields or when no date conflict occurs
  setFormValues((prevValues) => ({
    ...prevValues,
    [name]: value,
  }));
};




// close dropdown
const [openMsds, setOpenMsds] = useState(false); // MSDS dropdown state
const [openFups, setOpenFups] = useState(false); // End of Use Treatment dropdown state
const [openPlm, setOpenPlm] = useState(false); // Product End-of-Life Management dropdown state

// MSDS dropdown handlers
const handleMsdssDropdownOpen = () => setOpenMsds(true);
const handleMsdsDropdownClose = () => setOpenMsds(false);

// End of Use Treatment dropdown handlers
const handleFupssDropdownOpen = () => setOpenFups(true);
const handleFupsDropdownClose = () => setOpenFups(false);

// Product End-of-Life Management dropdown handlers
const handlePlmsDropdownOpen = () => setOpenPlm(true);
const handlePlmDropdownClose = () => setOpenPlm(false);



const handleMultipleSelectChange = (event) => {
  const { name, value } = event.target;
  setFormValues((prevValues) => ({
      ...prevValues,
      [name]: typeof value === 'string' ? value.split(',') : value,
  }));

    // Close the respective dropdown after selection
if (event.target.name === 'msds') {
  setOpenMsds(false);
} else if (event.target.name === 'nextUseApplication') {
  setOpenFups(false);
} else if (event.target.name === 'endOfUseTreatment') {
  setOpenPlm(false);
}
};






const handleChipDelete = (valueToDelete) => {
handleMsdsDropdownClose()
// Remove the selected item from the formValues.msds array
const updatedMsds = formValues.msds.filter((selected) => selected !== valueToDelete);

// Update the form values
setFormValues((prevValues) => ({
  ...prevValues,
  msds: updatedMsds,
}));
};


// Chip delete handlers  
const handleFupsChipDelete = (valueToDelete) => {  
handleFupsDropdownClose()
const updatedFups = formValues.nextUseApplication.filter((selected) => selected !== valueToDelete);  
setFormValues((prevValues) => ({  
    ...prevValues,  
    nextUseApplication: updatedFups,  
}));  
};  

const handlePlmChipDelete = (valueToDelete) => { 
handlePlmDropdownClose() 
const updatedPlm = formValues.endOfUseTreatment.filter((selected) => selected !== valueToDelete);  
setFormValues((prevValues) => ({  
    ...prevValues,  
    endOfUseTreatment: updatedPlm,  
}));  
};  












const handleSubmit = async (event) => {
  event.preventDefault();

  const payload = {
    saasId:saasId,
    name: formValues.name,
    description: formValues.description,
    category: formValues.category,
    // sku: formValues.sku,
    brand: formValues.brand,
    manufacturingDate: formValues.manufacturingDate,
    expirationDate: formValues.expirationDate,
    code: formValues.code,
    serialNumber: formValues.serialNumber,
    hsCode: formValues.hsCode,
    eanNumber: formValues.eanNumber,
    basicInfo: {
      weight: formValues.weight,
      weightUnit: formValues.weightUnit,
      quantity: formValues.quantity,
      quantityUnit: formValues.quantityUnit,
      length: formValues.length,
      width: formValues.width,
      height: formValues.height,
      usePeriod: formValues.usePeriod,

      msds: formValues.msds,
      useApplication: formValues.nextUseApplication,
      usedTreatment: formValues.endOfUseTreatment
    }
  };
  try {
    const response = await DataService.updateProduct(productData?.productId,payload);
    console.log('Product added successfully:', response.data);
    const productId = response?.data?.data?.productId;

    if (response.data.status) {
      // Save productId to local storage
      // localStorage.setItem('productId', productId);
      console.log('Product ID saved to local storage:', productId);
      await uploadProductImage(productId);

// Show success Swal alert
await Swal.fire({
icon: 'success',
title: 'Product Updated successfully',
text: 'The product has been Updated successfully!',
});

setModalOpen(!modalOpen)
   // Call the uploadProductImage function to upload the image
   fetchSupplierChainData()
}
else{
const errorMessage = response?.data?.message;
// Show error Swal alert with the extracted message
await Swal.fire({
  icon: 'error',
  title: 'Product Addition Failed',
  text: errorMessage,
});

}
  } catch (error) {
    console.error('Error adding product:', error);
    const errorMessage = error?.response?.data?.message;
    // Show error Swal alert with the extracted message
     await Swal.fire({
       icon: 'error',
       title: 'Product Addition Failed',
       text: errorMessage,
     });
  }
};

const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};




const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    setFilePreview(URL.createObjectURL(file)); // Preview URL for display
    setSelectedFile(file); // Original file to send to the API
  }
};

const uploadProductImage = async (productId) => {
  if (!selectedFile) return;

  try {
    const formData = new FormData();
    formData.append('file', selectedFile);

    // Log the FormData object to check if the file is appended correctly


    const imageResponse = await DataService.uploadProductImage(formData, productId);

    console.log('Image uploaded successfully:', imageResponse.data);

    Swal.fire({
      icon: 'success',
      title: 'Image Uploaded Successfully',
      text: 'The product image has been uploaded successfully!',
    });

  } catch (imageError) {
    console.error('Error uploading image:', imageError);

    Swal.fire({
      icon: 'error',
      title: 'Image Upload Failed',
      text: 'There was an error uploading the product image.',
    });
  }
};




// category

const [selectedCategory, setSelectedCategory] = useState('');





const handleCategoryChange = (event) => {
  setSelectedCategory(event.target.value);
  // You can trigger any additional logic when a category is selected if needed
};


const [showDescription, setShowDescription] = useState(false);

const toggleDescription = () => {
  setShowDescription((prev) => !prev);
};



  return (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
      <ModalHeader className='fw-bold' toggle={() => setModalOpen(!modalOpen)}>Edit Product</ModalHeader>
      <ModalBody>
  
          <form onSubmit={handleSubmit}>
            <Box p={1}>
           
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Name of End Product *
                    </Typography>
                    <TextField
                     // error={formValues.productName.length === 0}
                      required
                      fullWidth
                      label="Name of End Product"
                      variant="outlined"
                      name="name"
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Description *
                    </Typography>
                    <TextField
                      // error={formValues?.description.length === 0}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      label="Description"
                      variant="outlined"
                      name="description"
                      value={formValues.description}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
            <Typography variant="body1" mb={1}>
              Upload Product Image  *
            </Typography>
            <TextField
              required
              fullWidth
              variant="outlined"
              type="file"
              name="productImage"
              onChange={handleFileChange} 
              inputProps={{
                accept: "image/*", // Allow only image files
              }}
            />
          </Grid>
    <Grid item xs={12} md={3}>
    {filePreview ? (
    <Image
      src={filePreview}
      preview={false}
      alt="Product Preview"
      style={{ width: '100%', maxHeight: '100px', objectFit: 'contain',marginTop:"10px" ,zIndex:"99999" }}
    />
  )
:
(
  <Image
  src={productData?.image}
  preview={false}
    alt="Product Preview"
    style={{ width: '100%', maxHeight: '100px', objectFit: 'contain',marginTop:"10px",zIndex:"99999" }}
  />
)
}
      </Grid>
                </Grid>
              </Box>
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Typography variant="h6" className='fw-bold' style={{ fontFamily:"'Poppins', 'Georgia', 'serif'"}} mb={2}>Basic Information</Typography>
                {/* <RadioGroup
                  value={weightOption}
                  onChange={handleWeightOptionChange}
                >
                  <FormControlLabel
                    value="enterWeight"
                    control={<Radio />}
                    label="Enter weight here"
                  />
                  <FormControlLabel
                    value="useLowerAssembly"
                    control={<Radio />}
                    label="Use the weight entered from a lower assembly level (products and/or components)"
                  />
                </RadioGroup> */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Weight *
                    </Typography>
                    <TextField
                    required
                      fullWidth
                      label="Weight"
                      variant="outlined"
                      name="weight"
                      value={formValues.weight}
                      onChange={handleChange}
                      disabled={weightOption !== "enterWeight"}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Weight Unit
                    </Typography>
                    <TextField
                      fullWidth
                      label="Weight Unit"
                      variant="outlined"
                      name="weightUnit"
                      value={formValues.weightUnit}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleUomDropdownOpen} 
                    >
                      <option value=""></option>
                      {wumList.map((category) => (
        <option key={category.id} value={category.name}>
          {category.name}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Quantity In a Pack*
                    </Typography>
                    <TextField
                    required
                      fullWidth
                      label="Quantity"
                      variant="outlined"
                      name="quantity"
                      value={formValues.quantity}
                      onChange={handleChange}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Quantity Unit *
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      label="Quantity Unit"
                      variant="outlined"
                      name="quantityUnit"
                      value={formValues.quantityUnit}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleUnitmessureDropdownOpen} 

                    >
                      <option value=""></option>
                      {unitMessure?.map((category) => (
        <option key={category.id} value={category.measure}>
          {category.measure}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
  <Typography variant="body1" mb={1}>
    Size (Optional)
  </Typography>
  <TextField
    fullWidth
    label="Select Size"
    variant="outlined"
    name="Size"
    select
    SelectProps={{ native: true }}
  >
    <option value=""></option>

    <option value="XS">XS</option>
    <option value="S">S</option>
    <option value="M">M</option>
    <option value="L">L</option>
    <option value="XL">XL</option>
    <option value="XXL">XXL</option>
  </TextField>
</Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Length (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Length (mm)"
                      variant="outlined"
                      name="length"
                      value={formValues.length}
                      onChange={handleChange}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Width (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Width (mm)"
                      variant="outlined"
                      name="width"
                      value={formValues.width}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Height (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Height (mm)"
                      variant="outlined"
                      name="height"
                      value={formValues.height}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Use Period *
                    </Typography>
                    <TextField
                     required
                      fullWidth
                      label="Use Period In Year"
                      variant="outlined"
                      name="usePeriod"
                      value={formValues.usePeriod}
                      onChange={handleChange}
                       
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleProductUserPeriodDropdownOpen} 
                      >
                      <option value=""></option>
                      {Productuselist.map((category) => (
        <option key={category.id} value={category.periodName}>
          {category.periodName}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
      {/* <Typography variant="body1" mb={1}>
        MSDS *
        <IconButton onClick={toggleDescription} sx={{ ml: 1 }}>
  {showDescription ? <InfoIcon /> : <InfoOutlinedIcon />}
</IconButton>

{showDescription && (
  <Box>
    <Typography variant="body2" color="textSecondary">
      Material Safety Data Sheet. It is a document that provides information on the properties of chemical products, including details on their hazards, safe handling, storage, and emergency measures in case of accidents.
    </Typography>
  </Box>
)}
      </Typography> */}
      <Typography variant="body1" mb={1}>
      MSDS *
      </Typography> 
      <FormControl fullWidth variant="outlined">
      <InputLabel id="msds-label">MSDS</InputLabel> {/* Add the InputLabel */}
                        <Select
                            required
                            labelId="msds-label"
                            id="msds"
                            multiple
                            name="msds"
                            open={openMsds}
                            onOpen={handleMsdssDropdownOpen}
                            onClose={handleMsdsDropdownClose}
                            value={formValues.msds}
                            input={<OutlinedInput label="MSDS" />}
                            onChange={handleMultipleSelectChange}
                            onFocus={handleMsdsDropdownOpen}
                            renderValue={(selected) => (
                                <div className="flex flex-wrap gap-1">
                                    {selected?.map((value) => (
                                        <Chip style={{zIndex:"99999"}} key={value}
                                        onMouseDown={(event) => event.stopPropagation()}
                                        onDelete={() => handleChipDelete(value)}
                                        label={MSDS.find(msds => msds.name === value)?.name} />
                                    ))}
                                </div>
                            )}
                            fullWidth
                        >
                            {MSDS?.map((msds) => (
                                <MenuItem key={msds.id} value={msds.name}>
                                    {msds.name}
                                </MenuItem>
                            ))}
                        </Select>
                   
                        </FormControl>
    </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                    Future Utilization Opportunities *
                    </Typography>
                  <FormControl fullWidth>
              <InputLabel id="end-of-use-treatment-label">End of Use Treatment</InputLabel>
              <Select
                required
                onOpen={handleFupssDropdownOpen}
                 open={openFups}
                 onClose={handleFupsDropdownClose}
                labelId="end-of-use-treatment-label"
                id="end-of-use-treatment"
                multiple
                name="nextUseApplication"
                value={formValues.nextUseApplication}
                onChange={handleMultipleSelectChange}
                onFocus={handleFupsDropdownOpen}
                input={<OutlinedInput label="End of Use Treatment" />}

                renderValue={(selected) => (
                  <div className="flex flex-wrap gap-1">
                    {selected?.map((value) => (
                         <Chip style={{zIndex:"99999"}} key={value}
                         onMouseDown={(event) => event.stopPropagation()}
                         onDelete={() => handleFupsChipDelete (value)}
                         label={FUPS.find(treatment => treatment.utilization === value)?.utilization} />
                    ))}
                  </div>
                )}
                fullWidth
              >
                {FUPS?.map((item) => (
                  <MenuItem key={item.id} value={item.utilization}>
                    {item?.utilization}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                    Product End-of-Life Management *
                    </Typography>

                    <FormControl fullWidth>
  <InputLabel id="product-end-of-life-management-label">Product End-of-Life Management</InputLabel>
  <Select
  required
   open={openPlm}
   onClose={handlePlmDropdownClose}
   onOpen={handlePlmsDropdownOpen}
    fullWidth
    labelId="product-end-of-life-management-label"
    label="Product End-of-Life Management"
    variant="outlined"
    name="endOfUseTreatment"
    value={formValues.endOfUseTreatment}
    onChange={handleMultipleSelectChange}
    multiple
    onFocus={handlePlmDropdownOpen}
    input={<OutlinedInput label="Product End-of-Life Management" />}
    renderValue={(selected) => (
      <div className="flex flex-wrap gap-1">
        {selected.map((value) => (
          <Chip style={{zIndex:"99999"}} key={value}
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => handlePlmChipDelete  (value)} label={PLM.find(treatment => treatment.name === value)?.name} />
        ))}
      </div>
    )}
  >
    {PLM.map((item) => (
      <MenuItem key={item.id} value={item.name}>
        {/* <Checkbox checked={formValues.endOfUseTreatment.indexOf(item.name) > -1} /> */}
        {item.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>


                  </Grid>
                </Grid>
              </Box>
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Typography variant="h6" className='fw-bold' style={{ fontFamily:"'Poppins', 'Georgia', 'serif'"}} mb={2}>Additional Information</Typography>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
  <Typography variant="body1" mb={1}>
    Category *
  </Typography>
  <FormControl fullWidth variant="outlined" >
        <InputLabel>All Category</InputLabel>
        <Select
             required
              label="Category"
              name="category"
              value={formValues.category}
              onChange={handleChange}
      onOpen={handleDropCategorydownOpen} 

        >
          <MenuItem value="all">All Category</MenuItem>
          {categoryList.map((category) => (
        <MenuItem key={category.id} value={category.categoryName}>
          {category.categoryName}
        </MenuItem>
          ))}
        </Select>
      </FormControl>
</Grid>
          
                  <Grid item xs={12} sm={6}>
  <Typography variant="body1" mb={1}>
    Brand *
  </Typography>
  <FormControl fullWidth variant="outlined">
    <InputLabel>Brand</InputLabel>
    <Select
      required
      label="Brand"
      name="brand"
      value={formValues.brand}
      onChange={handleChange}
      onOpen={handleDropdownOpen} 
    >
          {brands?.map((category) => (
        <MenuItem key={category.id} value={category.name}>
          {category?.name}
        </MenuItem>
          ))}
    </Select>
  </FormControl>
</Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" mb={1}>
                      Manufacturing Date
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      label="Manufacturing Date"
                      variant="outlined"
                      name="manufacturingDate"
                      value={formValues.manufacturingDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" mb={1}>
                      Expiration Date
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      label="Expiration Date"
                      variant="outlined"
                      name="expirationDate"
                      value={formValues.expirationDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      disabled={!formValues.manufacturingDate} 
                    />
                  </Grid>
                </Grid>
              </Box>
           
 
            </Box>
          </form>
       
        {/* Add other basicInfo fields if needed */}
      </ModalBody>
      <ModalFooter>
        <Button     style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary" className='mx-4' onClick={handleSubmit}>Save</Button>
        <Button    style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditProductModal;
