import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  styled,
  IconButton
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: 'none',
  maxWidth: 400,
  width: '100%',
}));

const ModalforMaster = ({tab, name, saasid , logo, id,Type}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
        <IconButton aria-label="view" onClick={handleOpen}>
            <Visibility style={{ color: 'green' }} />
          </IconButton>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        View Brand Info
      </Button> */}
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="brand-modal-title"
        aria-describedby="brand-modal-description"
      >
        <ModalContent>
          <Typography id="brand-modal-title" variant="h4" component="h2" gutterBottom>
            {tab} Information
          </Typography>
          <Card>
            {logo &&<CardMedia
              component="img"
              sx={{
                height: 200,
                objectFit: 'contain',
                bgcolor: 'background.default',
                p: 2
              }}
              height="40"
              image={logo}
              alt={`${name} logo`}
            />}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {tab =="Product Usage Period"? `Period:${name}`:tab =="Dominant"?
                `Material Name :${name}`
                : name}
              </Typography>
              {tab =='Dominant'&&<Typography gutterBottom variant="h5" component="div">
                Material Type :{Type}
              </Typography>}
              <Typography variant="body2" color="text.secondary">
                <strong>ID:</strong> {id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>SAAS ID:</strong> {saasid}
              </Typography>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </ModalContent>
      </StyledModal>
    </div>
  );
};

export default ModalforMaster;