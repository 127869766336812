import React from 'react';

const Navbar = () => {
  return (
    <div className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
          <div className="flex items-center space-x-4">
    
    <nav className="text-sm text-gray-600">
    {/* <span className="text-xl font-bold">One scan</span> */}

      <a href="#" className="hover:underline">
        Passport
      </a>
      {' > '}
      <a href="#" className="hover:underline">
        Create
      </a>
    </nav>
  </div>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default Navbar;
