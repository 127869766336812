import React from 'react';
import { Box, Typography, Paper, Link } from '@mui/material';

function BasicData({ productData }) {
  return (
    <Box>
      <Paper
        sx={{
          py: 3.5,
          px: 2,
          backgroundColor: 'background.default',
          boxShadow: '0px 4px 4px rgba(91, 65, 255, 0.25)',
          
        }}
      >
        {/* First Row */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="body2" color="text.primary">
              Product Name:
            </Typography>
            <Typography variant="body2" color="text.primary" mt={1}>
              Category:
            </Typography>
            <Typography variant="body2" color="text.primary" mt={1}>
              Brand:
            </Typography>
          </Box>
          <Box textAlign="right" color="text.secondary">
            <Typography variant="body2">{productData?.name || 'N/A'}</Typography>
            <Typography variant="body2" mt={1}>
              {productData?.category || 'N/A'}
            </Typography>
            <Typography variant="body2" mt={1}>
              {productData?.brand || 'N/A'}
            </Typography>
          </Box>
        </Box>

        {/* Second Row */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="text.primary">
            SKU:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {productData?.sku || 'N/A'}
          </Typography>
        </Box>

        {/* Third Row */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="body2" color="text.primary">
              Description:
            </Typography>
            <Typography variant="body2" color="text.primary" mt={1}>
              Manufacturing Date:
            </Typography>
            <Typography variant="body2" color="text.primary" mt={1}>
              Expiration Date:
            </Typography>
          </Box>
          <Box textAlign="right" color="text.secondary">
            <Typography variant="body2">{productData?.description || 'N/A'}</Typography>
            <Typography variant="body2" mt={1}>
              {productData?.manufacturingDate
                ? new Date(productData?.manufacturingDate).toLocaleDateString()
                : 'N/A'}
            </Typography>
            <Typography variant="body2" mt={1}>
              {productData?.expirationDate
                ? new Date(productData?.expirationDate).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </Box>
        </Box>

        {/* Fourth Row - Links */}
        {/* <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="body2" color="text.primary">
              Product Image:
            </Typography>
          </Box>
          <Box textAlign="right">
            {productData?.image ? (
              <Link href={productData?.image} variant="body2" color="warning.main">
                View Image
              </Link>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No Image Available
              </Typography>
            )}
          </Box>
        </Box> */}
      </Paper>
    </Box>
  );
}

export default BasicData;
