import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton, Menu, Select, MenuItem, TextField } from '@mui/material';
import QRCode from 'react-qr-code'; 
import { Link } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DataService from '../services/requestApi';
import { useAuth } from '../contexts/AuthConext';
import { Box } from '@mui/system';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from '../components/Productpages/Pdf/PdfDocument';
import {FaTruck, FaFileInvoiceDollar, FaTruckLoading, FaDollarSign, FaMoneyBillWave, FaIdBadge, FaIdCard, FaCalendarAlt, FaBarcode, FaMapMarkedAlt } from "react-icons/fa";
import { BASEURL } from '../services/http-common';
import { Image } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const UnifiedSupplierManufactureView = ({metrial,  productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData,fetchSupplierChainData}) => {


  const {saasId
  } = useAuth(); 



  const scrollableContainerStyle = {
    height: "80vh", // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "5px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };

  const qrRef = useRef();
  
  const downloadQRCode = () => {
    if (qrRef.current) {
      // Temporarily set the QR code's height for the download
      qrRef.current.style.height = '100px';
      qrRef.current.style.width = '100px';
  
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleMenuClose()
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
          handleMenuClose()
        })
        .finally(() => {
          // Restore the original size after the download
          qrRef.current.style.height = '30px';
          qrRef.current.style.width = '30px';
          handleMenuClose()
        });
    }
  };
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };


  // download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};




 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  // Handle option change (retailer selection)
  const handleOptionChange = (event) => {
    const selectedRetailerId = event.target.value;
    const selectedRetailer = unifieldSm.find((retailer) => retailer.id === selectedRetailerId);

    setSelectedOption(selectedRetailerId); // Set the retailer ID
    setFormData((prevState) => ({
      ...prevState,
      name: selectedRetailer?.name || '', // Update retailer name in formData
    }));
  };


  const [unifieldSm, setUnifieldSm] = useState([]);

  const fetchRetailers = async () => {
    try {

     const response = await DataService.getUnifiedSM(saasId);
     setUnifieldSm(response.data.data);
  
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };
  useEffect(() => {
    fetchRetailers();
  }, []);

  //change date format for manufacture date in  format
  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  

  const [formData, setFormData] = useState({
  
    name:"",
    manufactureDate:  formatDate(unifiedSupplierManufacturerData?.manufactureDate),
    number: unifiedSupplierManufacturerData?.number,
    supplierDate: formatDate(unifiedSupplierManufacturerData?.supplierDate),
    supplierLocation: unifiedSupplierManufacturerData?.supplierLocation,
    paymentTerms: unifiedSupplierManufacturerData?.paymentTerms,
    deliveryTerms: unifiedSupplierManufacturerData?.deliveryTerms,
    warranty:unifiedSupplierManufacturerData?.warranty,
    manufactureCost: unifiedSupplierManufacturerData?.manufactureCost,
    supplierPrice: unifiedSupplierManufacturerData?.supplierPrice
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleupdateSMSave = async () => {
    try {
      if (selectedOption) {
        const response = await DataService.updateSMlink(productData?.id, selectedOption,formData); // Use selectedOption for retailerId
   
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Retailer updated successfully!',
          });
          fetchSupplierChainData()
          handleCloseModal()
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select a retailer before saving.',
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAndClose = () => {
    handleEditClick();
    handleMenuClose();
  };
  return (
    <>
   <div style={containerWrapperStyle}>

<div   className="scrollable-container " style={scrollableContainerStyle}>
     
        <Card style={{background:"#EBFEF6"}} className="w-full  mx-auto  rounded-lg">
        <Box style={{background:"#048a52"}} className="flex justify-between p-2 text-white">
          <div  className="flex justify-between ">
    <Image className='rounded' width={40} height={40} src={`${BASEURL.ENDPOINT_URL}supplier-manufacture/get-image-by-id/${unifiedSupplierManufacturerData?.supplierManufactureId}`}/>
  <Typography variant="h5" className="fw-bold mx-2 mt-1 ">{productData?.name}</Typography>
</div>
    <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{color:"white"}} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
     
      >
        <MenuItem style={{ background: "#EBFEF6" }}>
          <Card style={{ background: "#EBFEF6" }} className="mb-1 w-full max-w-3xl mx-auto rounded-lg">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditAndClose}  aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        metrial={metrial}
                        productData={productData}
                        impactEntity={impactEntity}
                        supplyChainEntity={supplyChainEntity}
                        retailerData={retailerData}
                        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}
                      />
                    }
                    fileName="product-data.pdf"
                    style={{ textDecoration: 'none' }}
                  >
                    {({ loading }) => (
                      <IconButton aria-label="download">
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7.5}>
              
                <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap mt-2"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 135,
               height: 25,
             }}
             onClick={downloadQRCode}
            >Download QRCode </Button>
           
                </Grid>
                <Grid item xs={4.5} textAlign="right">
                <QRCode ref={qrRef} value={`https://onescan.digital/productDetails/${productData?.productId}`} size={100}  style={{height:"30px"}}/>
     
            
          
                </Grid>
              </Grid>
            </CardContent>

       
          </Card>
    
        </MenuItem>
      </Menu>
  </Box>

  {/* Entity Information Section */}
  <Box className="p-4">
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box className="flex items-center space-x-2 text-gray-700">
          <FaIdBadge className="text-purple-500" />
          <Typography variant="subtitle1" className="fw-bold">Entity ID/Code</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.supplierManufactureId || "N/A"}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Box className="flex items-center space-x-2 text-gray-700">
          <FaIdCard className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Supplier Name</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.name || "N/A"}</Typography>
      </Grid>
    </Grid>

    {/* Product Origin Details and Supply Chain Details in the same row */}
    <Grid container spacing={2} className="mt-1">
      <Grid item xs={6}>
        <Typography variant="h6" className="fw-bold">Product Origin Details</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaCalendarAlt className="text-purple-500" />
          <Typography variant="subtitle1" className="fw-bold">Manufacturing Date</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.manufactureDate || "N/A"}</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaBarcode className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Batch/Serial Number</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.number || 'N/A'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className="fw-bold">Supply Details</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaTruck className="text-purple-500" />
          <Typography variant="subtitle1" className="fw-bold">Supply Date</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.supplierDate || "N/A"}</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaMapMarkedAlt className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Supply Location</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Typography>
      </Grid>
    </Grid>

    {/* Terms and Agreements and Pricing in the same row */}
    <Grid container spacing={2} className="mt-1">
      <Grid item xs={6}>
        <Typography variant="h6" className="fw-bold">Terms and Agreements</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaFileInvoiceDollar className="text-purple-500" />
          <Typography variant="subtitle1" className="fw-bold">Payment Terms</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.paymentTerms || "N/A"} Days</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaTruckLoading className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Delivery Terms</Typography>
        </Box>
        <Typography className="fw-bold">{unifiedSupplierManufacturerData?.deliveryTerms || "N/A"}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className="fw-bold">Pricing and Cost</Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          {/* <FaDollarSign className="text-purple-500" /> */}
          <FaMoneyBillWave className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Manufacturing Cost</Typography>
        </Box>
        <Typography className="fw-bold">$ {unifiedSupplierManufacturerData?.manufactureCost || "N/A"} </Typography>
        <Box className="flex items-center space-x-2 text-gray-700 mt-2">
          <FaMoneyBillWave className="text-pink-500" />
          <Typography variant="subtitle1" className="fw-bold">Supplier Price</Typography>
        </Box>
        <Typography className="fw-bold">$ {unifiedSupplierManufacturerData?.supplierPrice || "N/A"} </Typography>
      </Grid>
    </Grid>
  </Box>
</Card>


        
</div>

     
    </div>

         {/* Modal for editing */}
         <Modal isOpen={modalOpen} toggle={handleCloseModal}>
  <ModalHeader toggle={handleCloseModal}>Edit Unified SM Details</ModalHeader>
  <ModalBody>
    <Typography>Select Unified SM Type:</Typography>
    <Select value={selectedOption} onChange={handleOptionChange} fullWidth>
      {unifieldSm.map((retailer) => (
        <MenuItem key={retailer.id} value={retailer.id}>
          {retailer.name}
        </MenuItem>
      ))}
    </Select>

    {/* Add spacing between fields */}
    <Box mt={2}>
      <TextField
        fullWidth
        type="date"
        label="Manufacturing Date"
        variant="outlined"
        name="manufactureDate"
        value={formData.manufactureDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="number"
        value={formData.number}
        onChange={handleInputChange}
        label="Batch/Serial Number"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        type="date"
        label="Supplier Date"
        variant="outlined"
        name="supplierDate"
        value={formData.supplierDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="supplierLocation"
        value={formData.supplierLocation}
        onChange={handleInputChange}
        label="Supply Location"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="paymentTerms"
        value={formData.paymentTerms}
        onChange={handleInputChange}
        label="Payment Terms"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="deliveryTerms"
        value={formData.deliveryTerms}
        onChange={handleInputChange}
        label="Delivery Terms"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="warranty"
        value={formData.warranty}
        onChange={handleInputChange}
        label="Warranty Information"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="manufactureCost"
        value={formData.manufactureCost}
        onChange={handleInputChange}
        label="Manufacturing Cost"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="supplierPrice"
        value={formData.supplierPrice}
        onChange={handleInputChange}
        label="Supplier Pricing"
        variant="outlined"
      />
    </Box>
  </ModalBody>

  <ModalFooter className="flex justify-end gap-3">
    <Button
      style={{
        textTransform: 'none',
        color: '#fff',
        fontSize: '12px',
        background: '#2D059C',
        border: 'none',
        borderRadius: '20px',
        '&:hover': { background: '#2D059C' },
        width: 225,
        height: 45,
      }}
      onClick={handleupdateSMSave}
    >
      Save
    </Button>
    <Button
      style={{
        borderRadius: '20px',
        padding: '10px',
        width: '200px',
      }}
      variant="outlined"
      color="secondary"
      onClick={handleCloseModal}
    >
      Cancel
    </Button>
  </ModalFooter>
</Modal>


    </>
  );
};

export default UnifiedSupplierManufactureView;
