// PassportSummary.js
import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

const PassportSummary = ({ title, stepsProgress }) => {
  return (
    <Box   p={2}
    borderRadius={2}
    border="1px solid #e0e0e0"
    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
    width="100%"
    height="fit-content"
    bgcolor="white">
      <Typography className="fw-bold" variant="h6" gutterBottom>
        {title}
      </Typography>

      {stepsProgress.map((step, index) => (
        <Box key={index} mb={2}>
          <Typography variant="body2">
            {`${step.name} (Step ${step.stepNumber}/${step.totalSteps})`}
          </Typography>
          <Box display="flex" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={step.progress}
              style={{
                width: "100%",
                marginRight: "8px",
                height: "8px",
                borderRadius: "4px",
              }}
            />
            <Typography variant="body2" color="green">
              {step.progress.toFixed(0)}% complete
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PassportSummary;
