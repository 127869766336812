import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton, Pagination } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { Box, fontWeight } from '@mui/system';
import DataService from '../../services/requestApi';
import { useNavigate } from 'react-router-dom';
import AddRetailModal from '../Modal/AddRetailModal';
import UpdateRetailerModal from '../Modal/UpdateRetailerModal';
import { useAuth } from '../../contexts/AuthConext';
import Swal from 'sweetalert2';
import { Image } from 'antd';
import { BASEURL } from '../../services/http-common';
import RetailerInfoModal from './RetailerInfoModal';

const RetailerList = () => {
  const [retailers, setRetailers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const {saasId
  } = useAuth(); 
  const fetchRetailers = async ( search = '') => {
    try {
      let response;
      if (search) {
        response = await DataService.searchRetailerByName(saasId,search);
      } else {
        response = await DataService.getRetailerList(saasId);
      }
      setRetailers(response.data.data);
      setTotalPages(response.data.totalPage);
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };

  useEffect(() => {
    fetchRetailers();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEditClick = (row) => {
    setSelected(row);
    setUpdateModalOpen(true);
  };

  const handleDeleteClick = async (retailerId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const res =await DataService.deleteRetailerById(retailerId);
        if(res.data.status){
          Swal.fire('Deleted!', 'Retailer has been deleted.', 'success');
          fetchRetailers();

        }else{
          Swal.fire('Error!', 'Failed to delete retailer.', 'error');
        }
        // Refresh the list after deletion
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete Retailer', 'error');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchRetailers( event.target.value); // Search from page 1
  };

  const columns = [
    {
      name: 'Retailer ID',
      selector: (row) => row.retailerId,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Logo',
      cell: (row) => (
        <div>
         <Image width={40} height={40} src={`${BASEURL.ENDPOINT_URL}retailer/get-image-by-id/${row.retailerId}`}/>
        </div>
      ),
    },
    {
      name: 'Address',
      selector: (row) =>
        `${row.address?.state},${row.address?.country}, `,
      sortable: true,
    },

    {
      name: 'Contact Info',
      selector: (row) => `${row.contactInfo?.phone}`,
      sortable: false,
    },
    {
      name: 'Actions',
      fontWeight:700,
      center: true,
      cell: (row) => (
        <div className='flex'>
          <IconButton aria-label="edit" className="mr-2" onClick={() => handleEditClick(row)}>
            <Edit style={{ color: "green" }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDeleteClick(row.retailerId)}>
            <Delete style={{ color: "#C81D20" }} />
          </IconButton>

          <RetailerInfoModal row={row}/>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create Retailer Directory</h1>
            <Button className='fw-bold' style={{background:"#a855f7"}} variant="contained" color="primary" onClick={() => setSupplierModalOpen(true)}>Create</Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4 fw-bold">Retailer Directory</h2>
          <div className="flex mb-2">
            <TextField
              label="Search or Browse Retailers"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div style={{ height: "300px", overflow: "auto" }}>
            <DataTable
              columns={columns}
              data={retailers}
              pagination
              paginationServer
              paginationTotalRows={totalPages}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    minHeight: '72px', // override the row height
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <UpdateRetailerModal fetchRetailers={fetchRetailers} open={updateModalOpen} onHide={() => setUpdateModalOpen(false)} row={selected} />
      <AddRetailModal fetchRetailers={fetchRetailers} open={supplierModalOpen} onHide={() => setSupplierModalOpen(false)} />
    </>
  );
};

export default RetailerList;
