import React, { useEffect, useRef, useState } from 'react';
import {Menu, Container, Grid, Card, CardContent, Typography, Button, IconButton, Select, MenuItem,TextField, Chip, FormControl, InputLabel, Stack } from '@mui/material';
import QRCode from 'react-qr-code'; 
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { useAuth } from '../../contexts/AuthConext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Pdf/PdfDocument';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaTruck, FaWarehouse } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};


const SupplierChainEntity = ({metrial,fetchSupplierChainData, productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData}) => {
  const qrRef = useRef();

  
  const {saasId,
    transport,
    getAllTransport,
    Transportmethod,
    fetchgetserTransportmethodList,
    fetchsetWarehouseNameList,
    fetchWarhouseOwnnerList,
    WarehouseName,
    WarhouseOwnner
  } = useAuth(); 

  const handleTransportDropdownOpen = () => {
    getAllTransport();
  };

  const handleTransportmethodDropdownOpen = () => {
    fetchgetserTransportmethodList();
  };
  const downloadQRCode = () => {
    if (qrRef.current) {
      // Temporarily set the QR code's height for the download
      qrRef.current.style.height = '100px';
      qrRef.current.style.width = '100px';
  
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleMenuClose()
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
          handleMenuClose()
        })
        .finally(() => {
          // Restore the original size after the download
          qrRef.current.style.height = '30px';
          qrRef.current.style.width = '30px';
          handleMenuClose()
        });
    }
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    // Implement your edit logic here.
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


// download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};








// update api 

 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };



 

  const [formData, setFormData] = useState({
    supplier_name: "supplier 1",
    location: `${supplyChainEntity?.address?.flatNo} ${supplyChainEntity?.address?.country}`,
    progress_bar: "90",
    transport_method: supplyChainEntity?.transportDetails?.transportMethod || [],
    transport_rout:supplyChainEntity?.transportDetails?.transportRout || [],
    transport_rout_start: supplyChainEntity?.transportDetails?.transportRoutStart,
    transport_rout_end:  supplyChainEntity?.transportDetails?.transportRoutEnd,
    warehouse_name: supplyChainEntity?.warehouseDetails?.warehouseName,
    warehouse_license_no: supplyChainEntity?.warehouseDetails?.warehouseLicensNo,
    warehouse_manager: supplyChainEntity?.warehouseDetails?.warehouseManager,
    warehouse_gst_no: supplyChainEntity?.warehouseDetails?.warehouseGstNo,
    address: {
      flatNo: supplyChainEntity?.address?.flatNo,
      cityArea: supplyChainEntity?.address?.cityArea,
      country: supplyChainEntity?.address?.country,
      state: supplyChainEntity?.address?.state,
      // district: "",
      pincode: supplyChainEntity?.address?.pincode
    },
    phone: supplyChainEntity?.contactInfo?.phone,
    phone2: supplyChainEntity?.contactInfo?.phone2,
    email: supplyChainEntity?.contactInfo?.email,
    email2:supplyChainEntity?.contactInfo?.email2
  });
  

  useEffect(() => {
    if (supplyChainEntity) {
      handleTransportmethodDropdownOpen()
      handleTransportDropdownOpen()
  }
  }, [supplyChainEntity])
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Handle nested address fields
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [addressField]: value,
        },
      }));
    } else {
      // Handle other fields
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleArrayInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value // Assuming user inputs comma-separated values
    });

    if (e.target.name === 'transport_rout') {
      setOpenTransport(false);
    } else  if (e.target.name === 'transport_method') {
      setOpenTransportMethod(false);
    }
  };
    

  const handleupdateSMSave = async () => {
    try {
  
        const response = await DataService.updatelogisticklink(productData?.id,  formData); // Use selectedOption for retailerId
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Logistics updated successfully!',
          });
          handleCloseModal();
          fetchSupplierChainData()
        }
   
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };
        // dropdown close
        const [openTransport, setOpenTransport] = useState(false); // Transport Routes dropdown state
        const handleTransporttDropdownOpen = () => setOpenTransport(true);
        const handleTransportDropdownClose = () => setOpenTransport(false);

        const handleDeleteTransportRoute = (event, value) => {
          const newRoutes = formData.transport_rout.filter(route => route !== value);
          setFormData(prevData => ({ ...prevData, transport_rout: newRoutes }));
        };


        // Transport Methods dropdown handlers
        const [openTransportMethod, setOpenTransportMethod] = useState(false); // Transport Methods dropdown state

        const handleTransporttmethodDropdownOpen = () => setOpenTransportMethod(true);
        const handleTransportmethodDropdownClose = () => setOpenTransportMethod(false);

        const handleDeleteTransportMethod = (value) => {
          handleTransportmethodDropdownClose()
          const newMethods = formData.transport_method.filter(method => method !== value);  
          setFormData(prevData => ({ ...prevData, transport_method: newMethods }));  
        };  
        
  // Transport Routes dropdown handlers
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAndClose = () => {
    handleEditClick();
    handleMenuClose();
  };
  const handleWarehouseNameDropdownOpen = () => {
    fetchsetWarehouseNameList();
  };
  const handleWarhouseOwnnerDropdownOpen = () => {
    fetchWarhouseOwnnerList();
  };
  return (
    <>
    <div maxWidth="lg" className="scrollable-container" style={scrollableContainerStyle}>
    
        <Card style={{background:"#EBFEF6"}} className="w-full  mx-auto ">
      <Box style={{background:"#048a52"}} className="flex justify-between  p-2 text-white">
      <Typography variant="h5" className="fw-bold mt-1 mx-2 ">{productData?.name}</Typography>

      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{color:"white"}} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
     
      >
        <MenuItem style={{ background: "#EBFEF6" }}>
          <Card style={{ background: "#EBFEF6" }} className="mb-1 w-full max-w-3xl mx-auto rounded-lg">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditAndClose}  aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        metrial={metrial}
                        productData={productData}
                        impactEntity={impactEntity}
                        supplyChainEntity={supplyChainEntity}
                        retailerData={retailerData}
                        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}
                      />
                    }
                    fileName="product-data.pdf"
                    style={{ textDecoration: 'none' }}
                  >
                    {({ loading }) => (
                      <IconButton aria-label="download">
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7.5}>
              
                <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap mt-2"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 135,
               height: 25,
             }}
             onClick={downloadQRCode}
            >Download QRCode </Button>
           
                </Grid>
                <Grid item xs={4.5} textAlign="right">
                <QRCode ref={qrRef} value={`https://onescan.digital/productDetails/${productData?.productId}`} size={100}  style={{height:"30px"}}/>
     
            
          
                </Grid>
              </Grid>
            </CardContent>

       
          </Card>
    
        </MenuItem>
      </Menu>
      </Box>
      <Box className="p-6">
        <Grid container spacing={2}>
          {/* Warehouse Mobile */}
          <Grid item xs={12} sm={6}>
            <Box className="flex items-center space-x-2 text-gray-700">
              <FaPhone className="text-purple-500" />
              <Typography variant="h6" className="fw-bold">
                Warehouse Mobile No.
              </Typography>
            </Box>
            <Typography  className="text-lg fw-bold">
              (+91) {supplyChainEntity?.contactInfo?.phone} (Primary)
            </Typography>
            <Typography className="text-lg fw-bold">
              (+91) {supplyChainEntity?.contactInfo?.phone2} (Secondary)
            </Typography>
          </Grid>

          {/* Warehouse Email */}
          <Grid item xs={12} sm={6}>
            <Box className="flex items-center space-x-2 text-gray-700">
              <FaEnvelope className="text-pink-500" />
              <Typography variant="h6" className="fw-bold ">
                Warehouse E-mail
              </Typography>
            </Box>
            <Typography className="text-lg fw-bold">
  <a href={`mailto:${supplyChainEntity?.contactInfo?.email}`} className="text-blue-500 underline ">
    {supplyChainEntity?.contactInfo?.email}
  </a> (Primary)
</Typography>
<Typography className="text-lg fw-bold">
  <a href={`mailto:${supplyChainEntity?.contactInfo?.email1}`} className="text-blue-500 underline">
    {supplyChainEntity?.contactInfo?.email1}
  </a> (Secondary)
</Typography>

          </Grid>

     

        <Grid item xs={12} className='flex justify-center'>
              {/* Warehouse Details */}
              <Grid item md={6}>
            <Box className="flex items-center space-x-2 text-gray-700">
              <FaWarehouse className="text-purple-500" />
              <Typography variant="h6" className="fw-bold">
                Warehouse Details
              </Typography>
            </Box>
            <Typography  className='fw-bold' variant="body1">Warehouse Name: {supplyChainEntity?.warehouseDetails?.warehouseName}</Typography>
            <Typography  className='fw-bold' variant="body1">License No: {supplyChainEntity?.warehouseDetails?.warehouseLicensNo}</Typography>
            <Typography  className='fw-bold' variant="body1">Warehouse Owner: {supplyChainEntity?.warehouseDetails?.warehouseManager}</Typography>
            <Typography  className='fw-bold' variant="body1">GST No: {supplyChainEntity?.warehouseDetails?.warehouseGstNo}</Typography>
          </Grid>

          {/* Transport Details */}
          <Grid item md={6}>
            <Box className="flex items-center space-x-2 text-gray-700">
              <FaTruck className="text-pink-500" />
              <Typography variant="h6" className="fw-bold">
                Transport Details
              </Typography>
            </Box>
            <Typography  className='fw-bold' variant="body1">Transport Method: {supplyChainEntity?.transportDetails?.transportMethod}</Typography>
            <Typography  className='fw-bold' variant="body1">Transport Route: {supplyChainEntity?.transportDetails?.transportRout}</Typography>
            <Typography  className='fw-bold' variant="body1">Route Start: {supplyChainEntity?.transportDetails?.transportRoutStart}</Typography>
            <Typography  className='fw-bold' variant="body1">Route End: {supplyChainEntity?.transportDetails?.transportRoutEnd}</Typography>
          </Grid>
          </Grid>
     

              {/* Warehouse Address */}
              <Grid item xs={12} className="bg-white rounded-lg p-4 shadow-md flex items-start gap-3 mt-3">
            <MdLocationOn className="text-purple-500 mt-1" size={24} />
              {/* <Typography variant="body2" className="font-semibold">
                Warehouse Address :
              </Typography> */}
      
            <Typography className="text-lg fw-bold">
            {supplyChainEntity?.address?.flatNo}, {supplyChainEntity?.address?.cityArea},{' '}
              {supplyChainEntity?.address?.state}, {supplyChainEntity?.address?.country},{' '}
              {supplyChainEntity?.address?.pincode}
            </Typography>
          </Grid>

        

        </Grid>
      </Box>
    </Card>
     
    </div>

             {/* Modal for editing */}
             <Modal  isOpen={modalOpen} toggle={handleCloseModal}>
  <ModalHeader toggle={handleCloseModal}>Edit Logistics And Distribution</ModalHeader>
  <ModalBody>
  {/* Supplier Name */}
  {/* <Box mt={2}>
    <TextField
      fullWidth
      name="supplier_name"
      value={formData.supplier_name}
      onChange={handleInputChange}
      label="Supplier Name"
      variant="outlined"
    />
  </Box> */}

  {/* Location */}
  {/* <Box mt={2}>
    <TextField
      fullWidth
      name="location"
      value={formData.location}
      onChange={handleInputChange}
      label="Location"
      variant="outlined"
    />
  </Box> */}




  {/* Transport Method */}
  <Box mt={2}>
    {/* <TextField
      fullWidth
      name="transport_method"
      value={formData.transport_method.join(",")}
      onChange={handleArrayInputChange}
      label="Transport Method (comma separated)"
      variant="outlined"
    /> */}
          <FormControl fullWidth margin="normal">
          <InputLabel id="transport-method">Transport Methods *</InputLabel>
          <Select
          required
            open={openTransportMethod}
            onClose={handleTransportmethodDropdownClose}
            onOpen={handleTransporttmethodDropdownOpen}
            label='Transport Methods'
            labelId="transport-method-label"
            id="transport-method"
            multiple
            name='transport_method'
            value={formData.transport_method || []}
            onChange={handleArrayInputChange}
            onFocus={handleTransportmethodDropdownOpen}
            renderValue={(selected) => (
              <div className="flex flex-wrap gap-1">
                {selected.map((value) => (
                  <Chip
                  style={{zIndex:"99999"}} 
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={() => handleDeleteTransportMethod(value)}  
                  key={value} label={Transportmethod.find(category => category.methodName === value)?.methodName} />
                ))}
              </div>
            )}
          >
            {Transportmethod.map((category) => (
              <MenuItem key={category.id} value={category.methodName}>
                {category.methodName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  </Box>

  {/* Transport Route */}
  <Box mt={2}>
    {/* <TextField
      fullWidth
      name="transport_rout"
      value={formData.transport_rout.join(",")}
      onChange={handleArrayInputChange}
      label="Transport Route (comma separated)"
      variant="outlined"
    /> */}
          <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Transport Routes *</InputLabel>
  <Select
    required
    fullWidth
    open={openTransport}
    onClose={handleTransportDropdownClose}
    onOpen={handleTransporttDropdownOpen}
  label="Transport Routes"
     labelId="category-label"
    id="transport-rout"
    multiple
    name='transport_rout'
    value={formData.transport_rout || []}
    onChange={handleArrayInputChange}
    onFocus={handleTransportDropdownOpen}
    renderValue={(selected) => (
      <Stack direction="row" spacing={1}>
        {selected.map((value) => (
          <Chip
            key={value}
            style={{ zIndex: "99999" }}
            onMouseDown={(event) => event.stopPropagation()}
            onDelete={(event) => handleDeleteTransportRoute(event, value)}
            label={transport.find(category => category.routeName === value)?.routeName}
          />
        ))}
      </Stack>
    )}
  >
    {transport.map((category) => (
      <MenuItem key={category.id} value={category.routeName}>
        {category.routeName}
      </MenuItem>
    ))}
  </Select>
</FormControl>
  </Box>

  {/* Transport Route Start */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_rout_start"
      value={formData.transport_rout_start}
      onChange={handleInputChange}
      label="Transport Route Start"
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  </Box>

  {/* Transport Route End */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_rout_end"
      value={formData.transport_rout_end}
      onChange={handleInputChange}
      label="Transport Route End"
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  </Box>

  {/* Warehouse Name */}
  
            {/* </Grid> */}
  <Box mt={2}>
  <TextField
              required
                fullWidth
                label="Warehouse Name"
                placeholder="Warehouse Name"
                variant="outlined"
                name="warehouse_name"
                value={formData.warehouse_name}
                onChange={handleInputChange}
                select
                SelectProps={{ native: true }}
                onFocus={handleWarehouseNameDropdownOpen} 

                >
                <option value=""></option>
                {WarehouseName?.map((category) => (
  <option key={category.id} value={category.name}>
    {category.name}
  </option>
    ))}
              </TextField>
    {/* <TextField
      fullWidth
      name="warehouse_name"
      value={formData.warehouse_name}
      onChange={handleInputChange}
      label="Warehouse Name"
      variant="outlined"
    /> */}
  </Box>

  {/* Warehouse License No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_license_no"
      value={formData.warehouse_license_no}
      onChange={handleInputChange}
      label="Warehouse License No"
      variant="outlined"
    />
  </Box>

  {/* Warehouse Manager */}
  <Box mt={2}>
  <TextField
              required
                fullWidth
                label="Warehouse Owner Name "
                placeholder="Warehouse Owner Name"
                variant="outlined"
                name='warehouse_manager'
                value={formData.warehouse_manager}
                onChange={handleInputChange}
                select
                SelectProps={{ native: true }}
                onFocus={handleWarhouseOwnnerDropdownOpen} 

                >
                <option value=""></option>
                {WarhouseOwnner?.map((category) => (
  <option key={category.id} value={category.name}>
    {category.name}
  </option>
    ))}
              </TextField>
  </Box>

  {/* Warehouse GST No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_gst_no"
      value={formData.warehouse_gst_no}
      onChange={handleInputChange}
      label="Warehouse GST No"
      variant="outlined"
    />
  </Box>

  {/* Address - Flat No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.flatNo"
      value={formData.address.flatNo}
      onChange={handleInputChange}
      label="Flat No"
      variant="outlined"
    />
  </Box>

  {/* Address - City Area */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.cityArea"
      value={formData.address.cityArea}
      onChange={handleInputChange}
      label="City Area"
      variant="outlined"
    />
  </Box>

  {/* Address - Country */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.country"
      value={formData.address.country}
      onChange={handleInputChange}
      label="Country"
      variant="outlined"
    />
  </Box>

  {/* Address - State */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.state"
      value={formData.address.state}
      onChange={handleInputChange}
      label="State"
      variant="outlined"
    />
  </Box>

  {/* Address - District */}
  {/* <Box mt={2}>
    <TextField
      fullWidth
      name="address.district"
      value={formData.address.district}
      onChange={handleInputChange}
      label="District"
      variant="outlined"
    />
  </Box> */}

  {/* Address - Pincode */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.pincode"
      value={formData.address.pincode}
      onChange={handleInputChange}
      label="Pincode"
      variant="outlined"
    />
  </Box>

  {/* Phone */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="phone"
      value={formData.phone}
      onChange={handleInputChange}
      label="Phone"
      variant="outlined"
    />
  </Box>

  {/* Phone2 */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="phone2"
      value={formData.phone2}
      onChange={handleInputChange}
      label="Phone 2"
      variant="outlined"
    />
  </Box>

  {/* Email */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="email"
      value={formData.email}
      onChange={handleInputChange}
      label="Email"
      variant="outlined"
    />
  </Box>

  {/* Email2 */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="email2"
      value={formData.email2}
      onChange={handleInputChange}
      label="Email 2"
      variant="outlined"
    />
  </Box>
</ModalBody>


  <ModalFooter className="flex justify-end gap-3">
    <Button
      style={{
        textTransform: 'none',
        color: '#fff',
        fontSize: '12px',
        background: '#2D059C',
        border: 'none',
        borderRadius: '20px',
        '&:hover': { background: '#2D059C' },
        width: 225,
        height: 45,
      }}
      onClick={handleupdateSMSave}
    >
      Save
    </Button>
    <Button
      style={{
        borderRadius: '20px',
        padding: '10px',
        width: '200px',
      }}
      variant="outlined"
      color="secondary"
      onClick={handleCloseModal}
    >
      Cancel
    </Button>
  </ModalFooter>
</Modal>
    </>
  );
};

export default SupplierChainEntity;
