import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent,CardHeader, Typography, Button, IconButton,Select, MenuItem ,Menu } from '@mui/material';
import QRCode from 'react-qr-code';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import DataService from '../../services/requestApi';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useAuth } from '../../contexts/AuthConext';
import Swal from 'sweetalert2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Pdf/PdfDocument';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import { Image } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const scrollableContainerStyle = {
  height: "80vh",
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px",
  boxSizing: "border-box",
};

const RetailEntity = ({metrial,productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData,fetchSupplierChainData }) => {
  const qrRef = useRef();
  const cardRef = useRef(); // Add a reference for the card to download as PDF

  const {saasId
  } = useAuth(); 

  const downloadQRCode = () => {
    if (qrRef.current) {
      // Temporarily set the QR code's height for the download
      qrRef.current.style.height = '100px';
      qrRef.current.style.width = '100px';
  
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleMenuClose()
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
          handleMenuClose()
        })
        .finally(() => {
          // Restore the original size after the download
          qrRef.current.style.height = '30px';
          qrRef.current.style.width = '30px';
          handleMenuClose()
        });
    }
  };

  const downloadPDF = () => {
    const input = cardRef.current;
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('retailer-details.pdf');
      })
      .catch((error) => {
        console.error('Failed to generate PDF:', error);
      });
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value); // Handle select option change
  };




  const [retailers, setRetailers] = useState([]);

  const fetchRetailers = async ( search = '') => {
    try {

     const response = await DataService.getRetailerList(saasId);
      setRetailers(response.data.data);
  
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };
  useEffect(() => {
    fetchRetailers();
  }, []);



  const handleupdateRetailerSave = async () => {
    try {
      if (selectedOption) {
        const response = await DataService.updateRetailerlink(productData?.id, selectedOption); // Use selectedOption for retailerId
   
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Retailer updated successfully!',
          });
          fetchSupplierChainData()
          handleCloseModal()
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select a retailer before saving.',
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAndClose = () => {
    handleEditClick();
    handleMenuClose();
  };
  return (
    <>
    <div className="scrollable-container" style={scrollableContainerStyle}>
   
        <Card style={{background:"#EBFEF6"}} className="w-full  mx-auto  rounded-lg">
      {/* Card Header */}
      <Box style={{background:"#048a52"}} className="flex justify-between p-2 text-white">
      <div  className="flex justify-between ">
        
      <Image width={40} height={40}
          src={retailerData?.logo}
          alt="Brand"
      />
      <Typography  variant="h5" className="fw-bold mt-1 mx-2 ">{productData?.name}</Typography>
  </div>
             <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{color:"white"}} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
     
      >
        <MenuItem style={{ background: "#EBFEF6" }}>
          <Card style={{ background: "#EBFEF6" }} className="mb-1 w-full max-w-3xl mx-auto rounded-lg">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditAndClose}  aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        metrial={metrial}
                        productData={productData}
                        impactEntity={impactEntity}
                        supplyChainEntity={supplyChainEntity}
                        retailerData={retailerData}
                        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}
                      />
                    }
                    fileName="product-data.pdf"
                    style={{ textDecoration: 'none' }}
                  >
                    {({ loading }) => (
                      <IconButton aria-label="download">
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7.5}>
              
                <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap mt-2"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 135,
               height: 25,
             }}
             onClick={downloadQRCode}
            >Download QRCode </Button>
           
                </Grid>
                <Grid item xs={4.5} textAlign="right">
                <QRCode ref={qrRef} value={`https://onescan.digital/productDetails/${productData?.productId}`} size={100}  style={{height:"30px"}}/>
     
            
          
                </Grid>
              </Grid>
            </CardContent>

       
          </Card>
    
        </MenuItem>
      </Menu>
          </Box>
      {/* Card Content */}
      <CardContent className="p-6">
        <Grid container spacing={2}>
          {/* Retailer ID */}
          <Grid item xs={12} className="flex justify-between items-center mb-2">
            <Typography variant="body1" className="font-semibold text-gray-700 fw-bold">
              Retailer ID
            </Typography>
            <Box className="bg-purple-100 text-lg px-3 py-1 rounded-md text-gray-800 fw-bold">
              {retailerData?.retailerId}
            </Box>
          </Grid>

          {/* Retailer's Name */}
          <Grid item xs={12} className="bg-white rounded-lg p-3  shadow-md ">
          <Typography variant="body2" className="text-gray-500 fw-bold">
              Retailer's Name
            </Typography>
            <Typography variant="h6" className="font-bold text-purple-600 mt-1 fw-bold">
              {retailerData?.name}
            </Typography>
       
          </Grid>

          {/* Retailer Contact Numbers */}
          <Grid item xs={12}>
            <Box className="flex justify-center mt-1 mb-1 gap-3">
          <Grid item md={6}>
              <Box className="flex items-center gap-2 text-gray-700 ">
                <MdPhone className="text-purple-500" size={20} />
                <Typography variant="body1" className="fw-bold">(+91) {retailerData?.contactInfo?.phone} (Primary)</Typography>
              </Box>
              </Grid>
              <Grid item md={6}>
              <Box className="flex items-center gap-2 text-gray-700 ">
                <MdPhone className="text-pink-500" size={20} />
                <Typography variant="body1" className="fw-bold">(+91) {retailerData?.contactInfo?.phone2} (Secondary)</Typography>
              </Box>
              </Grid>
            </Box>
          </Grid>

          {/* Retailer Emails */}
          <Grid item xs={12}>
            <Box className="flex justify-center mt-1 mb-1 gap-3">
            <Grid item md={6}>
              <Box className="flex items-center gap-2 text-gray-700 ">
                <MdEmail className="text-purple-500" size={20} />
                <Typography variant="body1" className="fw-bold">
                <a href={`mailto:${retailerData?.contactInfo?.email}`} className="text-blue-500 underline ">
    {retailerData?.contactInfo?.email}
  </a> {retailerData?.contactInfo?.email && <span> (Primary)</span>}
                  {/* {retailerData?.contactInfo?.email} (Primary) */}
                  </Typography>
              </Box>
              </Grid>
              <Grid item md={6}>
              <Box className="flex items-center gap-2 text-gray-700 fw-bold">
              {retailerData?.contactInfo?.email2 &&<MdEmail className="text-pink-500" size={20} />}
                <Typography variant="body1" className="fw-bold">
                <a href={`mailto:${retailerData?.contactInfo?.email2}`} className="text-blue-500 underline ">
    {retailerData?.contactInfo?.email2}
  </a> {retailerData?.contactInfo?.email2 && <span> (Secondary)</span>}
                  {/* {retailerData?.contactInfo?.email2}  {retailerData?.contactInfo?.email2 && <span> (Secondary)</span>}   */}
                  </Typography>
              </Box>
              </Grid>
            </Box>
          </Grid>

          {/* Retailer Address */}
          <Grid item xs={12} className="bg-white rounded-lg p-4 shadow-md flex items-start gap-3 ">
            <MdLocationOn className="text-purple-500 mt-1" size={24} />
            <Typography variant="body1" className="text-gray-700 fw-bold">
              {`${retailerData?.address?.flatNo}, ${retailerData?.address?.cityArea}, ${retailerData?.address?.district}, ${retailerData?.address?.state}, ${retailerData?.address?.country}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
      
    </div>

     {/* Modal for editing */}
     <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Edit Retailer Details</ModalHeader>
                <ModalBody>
                  <Typography>Select Retailer Type:</Typography>
                  <Select value={selectedOption} onChange={handleOptionChange} fullWidth>
            {retailers.map((retailer) => (
              <MenuItem key={retailer.retailerId} value={retailer.retailerId}>
                {retailer.name}
              </MenuItem>
            ))}
          </Select>
                </ModalBody>
                <ModalFooter className='flex justify-end gap-3'>
                  <Button  Button     style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary" onClick={handleupdateRetailerSave}>
                    Save
                  </Button>
                  <Button    style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

    </>
  );
};

export default RetailEntity;
